import React from 'react';
import { FormattedMessage } from 'react-intl';

// Images
import Images from '../../img/index';

const Catalog = function () {

    return (
        <div className="why-choose catalog justify-center">
            <div className="container column">
                {/*<div className="white-space-32"></div>
                <h2 className="color-black text-center">
                    <FormattedMessage id="catalogos" defaultMessage="CATÁLOGOS" />
                </h2>
                <div className="white-space-32"></div>*/}
                <div className="row-responsive justify-center">
                    {/*<div className="why-choose-reason" style={{ backgroundImage: `url(${Images['catalog']})` }}>
                        <div className="icon auto align-center">
                            <img src={Images['pdf']} alt="Pdf" title="Pfd"/>
                        </div>
                        <div className="column">
                            <h3 className="color-white">
                                <FormattedMessage id="footer-catalogo-eventos" defaultMessage=" CATÁLOGO EVENTOS" />
                            </h3>
                            <div className="white-space-8"></div>
                            <a href="https://www.elfosgourmet.eu/descargas/CatalogoEventos.pdf" className="auto link color-white text-mini">
                                <FormattedMessage id="descargar" defaultMessage="DESCARGAR" />
                            </a>
                        </div>
                    </div>*/}
                    {/*<div className="why-choose-reason" style={{ backgroundImage: `url(${Images['catalog']})` }}>
                        <div className="icon auto align-center">
                            <img src={Images['pdf']} alt="Pdf" title="Pfd" />
                        </div>
                        <div className="column">
                            <h3 className="color-white">
                                <FormattedMessage id="footer-catalogo-elfos" defaultMessage=" CATÁLOGO ELFOS GOURMET" />
                            </h3>
                            <div className="white-space-8"></div>
                            <a href="https://www.elfosgourmet.eu/descargas/CatalogoElfos.pdf" className="auto link color-white text-mini">
                                <FormattedMessage id="descargar" defaultMessage="DESCARGAR" />
                            </a>
                        </div>
                    </div>*/}
                    {/*<div className="why-choose-reason" style={{ backgroundImage: `url(${Images['catalog']})` }}>
                        <div className="icon auto align-center">
                            <img src={Images['pdf']} alt="Pdf" title="Pfd" />
                        </div>
                        <div className="column">
                            <h3 className="color-white">
                                <FormattedMessage id="footer-catalogo-setal-horeca" defaultMessage=" CATÁLOGO SETAL HORECA" />
                            </h3>
                            <div className="white-space-8"></div>
                            <a href="https://www.elfosgourmet.eu/descargas/CatalogoSetalHoreca.pdf" className="auto link color-white text-mini">
                                <FormattedMessage id="descargar" defaultMessage="DESCARGAR" />
                            </a>
                        </div>
                    </div>*/}
                </div>

                {/*<div className="white-space-32"></div>
                <div className="row-responsive why-choose-container">
                    <div className="why-choose-reason" style={{ backgroundImage: `url(${Images['catalog']})` }}>
                        <div className="icon auto align-center">
                            <img src={Images['pdf']} alt="Pdf" title="Pfd" />
                        </div>
                        <div className="column">
                            <h3 className="color-white">
                                <FormattedMessage id="footer-catalogo-elfos" defaultMessage=" CATÁLOGO ELFOS GOURMET" />
                            </h3>
                            <div className="white-space-8"></div>
                            <a href="https://www.elfosgourmet.eu/descargas/CatalogoElfos.pdf" className="auto link color-white text-mini">
                                <FormattedMessage id="descargar" defaultMessage="DESCARGAR" />
                            </a>                            
                        </div>
                    </div>
                    <div className="why-choose-reason" style={{ backgroundImage: `url(${Images['catalog']})` }}>
                        <div className="icon auto align-center">
                            <img src={Images['pdf']} alt="Pdf" title="Pfd" />
                        </div>
                        <div className="column">
                            <h3 className="color-white">
                                <FormattedMessage id="footer-catalogo-setal-comercio" defaultMessage=" CATÁLOGO SETAL INDUSTRIA" />
                            </h3>
                            <div className="white-space-8"></div>
                            <a href="https://www.elfosgourmet.eu/descargas/CatalogoSetalComercio.pdf" className="auto link color-white text-mini">
                                <FormattedMessage id="descargar" defaultMessage="DESCARGAR" />
                            </a>
                        </div>
                    /div>
                </div>*/}
                <div className="white-space-32"></div>
            </div>
        </div>
    );

}

export default Catalog;