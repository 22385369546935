import React, { Component } from 'react';

// Web Components
import RelevantProduct from './relevant-product';

class RelevantProducts extends Component {    
    
    render() {
        const numberOfItems = this.props.numitems;
        return (
            <div className="column relevant">
                {
                    this.props.items
                        .slice((this.props.page - 1) * numberOfItems, this.props.page * numberOfItems)
                        .map(function (item, index) {
                            return (
                                <RelevantProduct key = { index } item={ item } />
                            )
                        })
                }
            </div>
        );
    }            
}

export default RelevantProducts;