import React, { Component } from "react";
import ModalLogin from "../modals/login";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../redux/actions";
import { FormattedMessage, injectIntl, FormattedNumber } from 'react-intl';
import Menu from './menu';

//Images
import Images from "../../img/index";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carrito_length: this.props.carrito_length
    };
  }

  logIn() {
    // Trigger Login Modal
    document.getElementById("modal-login").classList.remove("hidden");
  }

  logOut() {
    this.props.dispatch({ type: "LOGOUT" });
    window.location = "/inicio";
  }

  findProduct(event) {
    event.preventDefault();
    const searchValue = event.target.search.value;
    event.target.search.style.border = "2px solid #E9EBED";
    if (searchValue.length > 2) {
      this.props.dispatch(action.getProductsByQuery(searchValue, this.props.intl.locale));
      this.props.history.push("./comprar");
    } else {
      event.target.search.style.border = "2px solid red";
    }
  }

  changeLocale(locale) {
    if(this.props.line > 0) {
      this.props.dispatch(action.getCategories(this.props.line, locale));
    }
    this.props.dispatch(action.changeLocale(locale));
    if(locale === 'en') {
      alert("Switching to English");
    } else {
      alert("Cambiando a Español");
    }
  }

  render() {
    const User_Name = this.props.auth ? this.props.user.usuario : "";
    let subtotal = 0;
    if (this.props.subtotal) {
      subtotal = this.props.subtotal;
    }
    return (
      <header className="column">
        <div className="header-top">
          <div className="justify-center">
            <div className="container">
              {/*<div className="phone auto align-center">
                <a className="color-darkGray link" href="tel:+(34) 975 373443">
                  <FormattedMessage id="ordena_al" defaultMessage="Llama al:" /> 
                  <span className="color-aqua"> +(34) 975 373443 </span>
                </a>
              </div>*/}
              <div className="email auto align-center">
                <a href="mailto:info@elfosgourmet.eu">
                  <i className="fas fa-envelope color-darkGray" />
                  &nbsp;{" "}
                  <span className="link">info@elfosgourmet.eu</span>
                </a>
              </div>
              <div className="lang justify-end">
                {this.props.locale === "es" ? (
                  <div
                    className="en auto align-center"
                    onClick={this.changeLocale.bind(this, "en")}
                  >
                    <a href="#">
                      <img
                        src={Images["en"]}
                        alt="English Site Flag"
                        title="Enlish Site Flag"
                      />
                    </a>
                    <a href="#" className="lang-text">
                      &nbsp; English
                    </a>
                  </div>
                ) : (
                  <div
                    className="en auto align-center"
                    onClick={this.changeLocale.bind(this, "es")}
                  >
                    <a href="#">
                      <img
                        src={Images["es"]}
                        alt="Español Site Flag"
                        title="Español Site Flag"
                      />
                    </a>
                    <a href="#" className="lang-text color-aqua">
                      &nbsp; Español
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="header-main">
          <div className="justify-center">
            <div className="container">
              <div className="row-responsive break-ipad align-center">
                <div className="middle">
                  <div className="logo justify-start">
                    <Link to="">
                      {/*LOGO DORADO ELFOS*/}
                      <img src={Images["elfos-gourmet-logo"]} alt="Elfos Gourmet Logo" title="Elfos Gourmet Logo" />
                    </Link>
                  </div>
                </div>
                <div className="column">
                  <div className="row-responsive">
                    <div className="right justify-end">
                      <div className="user-cart">
                        <div className="user auto align-center icon-padding">
                          <div className="icon">
                            <img src={Images["user"]} alt="Ícono de usuario" title="Ícono de usuario" />
                          </div>
                          {this.props.auth ? (
                            <div className="info column auto">
                              <Link to="/perfil" className="color-aqua weight-medium link">
                                {User_Name}
                              </Link>
                            </div>
                          ) : (
                              <div className="info column auto">
                                <Link className="color-darkGray weight-medium link" to="/registro" >
                                  <FormattedMessage id="header-right-registro" defaultMessage="REGISTRO" />
                                </Link>                                
                              </div>
                            )}
                        </div>
                        <div className="user auto align-center icon-padding">
                          {this.props.auth ? (
                            <div className="info column auto">
                              
                            </div>
                          ) : (
                              <div className="info column auto">
                                
                                <a className="color-darkGray weight-medium link" onClick={this.logIn.bind(this)} href="#" id="open-modal-login" >
                                  <FormattedMessage id="header-right-ingresar" defaultMessage="INGRESAR" />
                                </a>
                              </div>
                            )}
                        </div>
                        {this.props.auth &&
                          <div className="exit auto align-center icon-padding">
                            <div className="icon">
                              <img src={Images["logout"]} alt="Ícono de usuario" title="Ícono de usuario" />
                            </div>
                            <div className="info column auto">
                              <a className="color-darkGray weight-medium link" onClick={this.logOut.bind(this)}>
                                <FormattedMessage id="header-right-salir" defaultMessage="SALIR" />
                              </a>
                            </div>
                          </div>
                        }
                        <div className="cart auto align-center icon-padding">
                          <div className="icon">
                            <img src={Images["cart"]} alt="Ícono de carrito" />
                          </div>
                          <div className="info column auto">
                            <Link className="color-darkGray weight-medium link" to="/carrito">
                              <FormattedMessage id="header-right-carrito" defaultMessage="CARRITO" />
                            </Link>
                            <a className="color-darkGray weight-medium link" href="#" >
                              <FormattedNumber
                                currency="EUR"
                                style="currency"
                                value={subtotal}
                              />
                            </a>
                          </div>
                        </div>
                        {/*{this.props.auth ?
                    <div className="auto">
                        <Link to="../pedidos" className="color-aqua link weight-medium">
                          <FormattedMessage id="header-right-carrito" defaultMessage="MIS PEDIDOS" />
                        </Link>
                    </div>
                    : null}*/}
                      </div>
                    </div>
                  </div>
                  <div className="lm row-responsive align-center justify-between">
                    <div className="left">
                      <div className="search">
                        <form onSubmit={this.findProduct.bind(this)}>
                          <FormattedMessage id="header-buscar" defaultMessage="BUSCAR">
                            {
                              inputSearch => (<input type="text" className="input input-search" name="search" placeholder={inputSearch} />)
                            }
                          </FormattedMessage>
                          <button type="submit" className="btn btn-search justify-center" >
                            <i className="fas fa-search" />
                          </button>
                        </form>
                      </div>
                    </div>
                    <Menu></Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalLogin />
      </header>
    );
  }

  componentDidMount() {
    var modal = document.getElementById("modal-login");

    var btn = document.getElementById("open-modal-login");

    if (btn) {
      btn.onclick = function() {
        modal.classList.remove("hidden");
      };
    }

    if (modal) {
      window.onclick = function(event) {
        if (event.target === modal) {
          modal.classList.add("hidden");
        }
      };

      window.onkeydown = event => {
        if (event.keyCode === 27) {
          modal.classList.add("hidden");
        } else {
          return 0;
        }
      };
    }
  }
}
function mapStateToProps(state) {
  return {
    locale: state.locale,
    line: state.lines_selected,
    subtotal: state.subtotal,
    auth: state.auth,
    user: state.user,
    carrito_count: state.carrito_count
  };
}
export default withRouter(injectIntl(connect(mapStateToProps)(Header)));
