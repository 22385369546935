import React from 'react';
import { FormattedMessage } from 'react-intl';

// Images
import Images from '../../img/index';

const WhyChooseUs = function() {

    return(
        <div className="why-choose justify-center" style={{ background: '#d8e5dc' }}>
            <div className="container column">
                <div className="white-space-64"></div>
                <h2 className="color-black text-center">
                    <FormattedMessage id="why-choose-us" defaultMessage="¿POR QUÉ ELEGIRNOS?" />
                </h2>
                <div className="white-space-16"></div>
                <div className="justify-center">
                    <img src={ Images['divider'] } alt="Divisor" title="Divisor"/>
                </div>
                <div className="white-space-32"></div>
                <div className="row-responsive why-choose-container">
                    <div className="why-choose-reason">
                        <div className="icon auto align-center">                            
                        </div>
                        <div className="column">
                            <h3 className="color-black">
                                <FormattedMessage id="why-choose-experiencia" defaultMessage="EXPERIENCIA" />
                            </h3>
                            <div className="white-space-8"></div>
                            <p className="text-justify">
                                <FormattedMessage id="why-choose-experiencia-descripcion" defaultMessage="Elfos Gourmet es una empresa innovadora con 20 años de experiencia dedicada a la transformación, elaboración y comercialización de productos micológicos naturales y artesanos, con unas instalaciones cubiertas de 2.480 m2, respondiendo a una exigencia de la industria alimentaria especializada." />
                            </p>
                        </div>
                    </div>
                    <div className="why-choose-reason">
                        <div className="icon auto align-center">                        
                        </div>
                        <div className="column">
                            <h3 className="color-black">
                                <FormattedMessage id="why-choose-calidad" defaultMessage="CALIDAD" />
                            </h3>
                            <div className="white-space-8"></div>
                            <p className="text-justify">
                            <FormattedMessage id="why-choose-calidad-descripcion" defaultMessage="Un cualificado equipo humano selecciona la materia prima, clasifica y transforma el producto utilizando la tecnología alimentaria más moderna. La permanente investigación define las novedades gastronómicas que vincularán estrechamente al consumidor con la naturaleza." />
                            </p>
                        </div>
                    </div>
                </div>

                <div className="white-space-32"></div>
                <div className="row-responsive why-choose-container">
                    <div className="why-choose-reason">
                        <div className="icon auto align-center">                            
                        </div>
                        <div className="column">
                            <h3 className="color-black">
                                <FormattedMessage id="why-choose-compromiso" defaultMessage="COMPROMISO" />
                            </h3>
                            <div className="white-space-8"></div>
                            <p className="text-justify">
                            <FormattedMessage id="why-choose-compromiso-descripcion" defaultMessage="Conscientes de la importancia de ofrecer garantías sanitarias en producción micológica. Elfos Gourmet cumple con la normativa europea sobre productos micológicos silvestres. Siendo la materia prima un producto natural escaso, garantizamos rigurosamente el control del producto." />
                            </p>
                        </div>
                    </div>
                    <div className="why-choose-reason">
                        <div className="icon auto align-center">
                        </div>
                        <div className="column">
                            <h3 className="color-black">
                                <FormattedMessage id="why-choose-idi" defaultMessage="I+D+I" />
                            </h3>
                            <div className="white-space-8"></div>
                            <p className="text-justify">
                            <FormattedMessage id="why-choose-idi-descripcion" defaultMessage="La puesta en marcha va acompañada de la aplicación de las más modernas técnicas de producción, la aplicación del sistema ISO 9001 (actualizando a la calidad IFS) - calificación de productos BIO  y aplicación de los estudios realizados como el envasado especial de atmósfera controlada." />
                            </p>
                        </div>
                    </div>
                </div>
                <div className="white-space-64"></div>
            </div>
        </div>
    );

}

export default WhyChooseUs;