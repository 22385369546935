import React, { Component } from "react";
import { connect } from "react-redux";
import request from "superagent";
import { FormattedMessage, injectIntl } from "react-intl";
import { URL_API } from "../api/urls";
import { Send } from "react-facebook";

export class RestorePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageID: "none",
      loading: false
    };
  }

  hanldeSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });
    const form = event.target;
    const email = form.verify_email.value;
    const data = {
      email,
      locale: this.props.intl.locale
    };
    this.setState({ messageID: "none" });
    request
      .post(URL_API + "clientes/verificar_mail")
      .send(data)
      .then(res => {
        console.log(res.body);
        this.setState({ messageID: res.body.message });
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err.message);
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <div className="content">
        <div className="white-space-32" />
        <div className="column">
          <form onSubmit={this.hanldeSubmit.bind(this)} className="column">
            <div className="white-space-32" />
            <FormattedMessage
              id="restore_proporcione_email"
              defaultMessage="Proporcione su correo electrónico"
            />
            <div className="white-space-32" />
            <input
              type="email"
              className="input-text"
              name="verify_email"
              required
            />
            <div className="white-space-32" />
            <button type="submit" className="align-center btn btn-green">
              <FormattedMessage id="enviar" defaultMessage="ENVIAR" />
              &nbsp;
              {this.state.loading ? (
                <i className="fas fa-spinner fa-spin" />
              ) : (
                <i className="fas fa-envelope" />
              )}
            </button>
            <div className="white-space-32" />
          </form>
          <label>
            <FormattedMessage id={this.state.messageID} defaultMessage=" " />
          </label>
          <div className="white-space-32" />
        </div>
        <div className="white-space-32" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    verify_tokenpass: state.verify_tokenpass
  };
}

export default injectIntl(connect(mapStateToProps)(RestorePassword));
