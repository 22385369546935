import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import * as action from "../redux/actions";
import { FormattedMessage } from "react-intl";

// Web Components
import PageBanner from "../components/banner/page-banner";
import Pedidos from '../components/profile/pedidos';
import Cuenta from '../components/profile/accountForm';
import { setTimeout } from "timers";

class Perfil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      login_error: "",
	  error_class: "label-error",
	  page: localStorage.getItem('elfprofpage') || "pedidos"
    };
  }

  componentDidUpdate(prevProps, prevState) {
	  if(prevState.page !== this.state.page) {
		  localStorage.setItem('elfprofpage', this.state.page)
	  }
  }

  cipher(text) {
    const string64 = new Buffer(text).toString("base64");
    return string64;
  }

  response(res) {
    console.log(res);
    let message = '';
    if (res.error) {
      console.log(res.message);
      message = <FormattedMessage id="register_mesage_error" defaultMessage="Error al registrar, intente mas tarde" />;
    } else {
      message = <FormattedMessage id="register_mesage_success" defaultMessage="Registro de usuario exitoso" />
      window.location = "/";
    }
    this.setState({ error: res.error, message: message });
    const modal = document.getElementById("registro-modal-message");
    if (modal) {
      modal.classList.remove("hidden");
    }
  }

  renderModal() {
    const modal = document.getElementById("registro-modal-message");
    return (
      <div
        className="flex modal justify-center hidden"
        id="registro-modal-message"
      >
        <div
          className="container modal-content column"
          style={{ maxWidth: "300px" }}
        >
          <div className="modal-header justify-center">
            <h2 className="color-white">
              <FormattedMessage id="modal_login_header" defaultMessage="" />
            </h2>
          </div>
          <div className="modal-body column justify-center">
            <div className="white-space-32" />
            <div className="justify-center">
              <label>{this.state.message}</label>
            </div>
            <div className="white-space-32" />
            <div className="btn-container-login">
              <button
                className="btn btn-red"
                onClick={() => modal.classList.add("hidden")}
              >
                <i className="fas fa-close" />
                &nbsp;{" "}
                <FormattedMessage id="modal_close" defaultMessage="CERRAR" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="column">
        <Helmet>
          <title>Elfos Gourmet - Registro</title>
        </Helmet>
        <PageBanner
          bannerImage="bannershop"
          bannerTitle={
            <FormattedMessage
              id="profile"
              defaultMessage="PROFILE"
            />
          }
        />
		<div class="navbar">
			<a className={this.state.page === "pedidos" && "active"} onClick={() => this.setState({page: "pedidos"})}>
				<FormattedMessage
					id="pedidos"
					defaultMessage="Seguimiento de mis pedidos"
				/>
			</a>
			<a className={this.state.page === "cuenta" && "active"} onClick={() => this.setState({page: "cuenta"})}>
				<FormattedMessage
					id="cuenta"
					defaultMessage="Mi cuenta"
				/>
			</a>
		</div>
		<div>
			{this.state.page === "pedidos" && <Pedidos/>}
			{this.state.page === "cuenta" && <Cuenta/>}
		</div>
        {this.renderModal()}
      </div>
    );
  }

  registerOk() {
    alert("Registro Exitoso");
    document.getElementById("btn-register").setAttribute("disabled", "");
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.user,
    login_error: state.login_error,
    loading: state.login_loading,
    sigin_message: state.sigin_message,
    register_loading: state.client_register_loading
  };
}

export default connect(mapStateToProps)(Perfil);
