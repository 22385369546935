import React from 'react';
import { Link } from'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Images
import Images from '../../img/index';

const Slider = function(props) {
        
    return(
        <div className="carousel" data-flickity='{"draggable": false, "autoPlay": 5000, "prevNextButtons": false, "pageDots": true, "selectedAttraction": 0.5, "friction": 0.9}'>

            <div className="slide justify-center" style={{ backgroundImage: `url(${Images['TrufaSlider']})` }}>
                <div className="container align-center ">
                    <div className="column">
                        <h2 className="slide-h2">
                            <FormattedMessage id="slider-titulo" defaultMessage="DELICIAS DEL BOSQUE" />
                        </h2>
                        <div className="white-space-8"></div>
                        <h1 className="slide-h1 auto">
                            <FormattedMessage id="slider-trufas" defaultMessage="TRUFAS" />
                        </h1>
                        <div className="white-space-8"></div>
                        <p className="slide-p color-white">
                            <FormattedMessage id="slider-description1" defaultMessage="Nos dedicamos a transformar, elaborar y comercializar productos" /> <br />
                            <FormattedMessage id="slider-description2" defaultMessage="micológicos naturales y artesanos, respondiendo a una exigencia" /> <br />
                            <FormattedMessage id="slider-description3" defaultMessage=" " />
                        </p>
                        <div className="white-space-16"></div>
                        <div>
                            <Link className="btn btn-green" to="comprar" onClick={props.comprar.bind(this, 1)}>
                                <FormattedMessage id="slider-comprar" defaultMessage="COMPRAR AHORA" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="slide justify-center" style={{ backgroundImage: `url(${Images['ElfosSlider']})` }}>
                <div className="container align-center ">
                    <div className="column">
                        <h2 className="slide-h2">
                            <FormattedMessage id="slider-titulo" defaultMessage="DELICIAS DEL BOSQUE" />
                        </h2>
                        <div className="white-space-8"></div>
                        <h1 className="slide-h1 auto">
                            ELFOS GOURMET
                        </h1>
                        <div className="white-space-8"></div>
                        <p className="slide-p color-white">
                            <FormattedMessage id="slider-description1" defaultMessage="Nos dedicamos a transformar, elaborar y comercializar productos" /> <br />
                            <FormattedMessage id="slider-description2" defaultMessage="micológicos naturales y artesanos, respondiendo a una exigencia" /> <br />
                            <FormattedMessage id="slider-description3" defaultMessage=" " />
                        </p>
                        <div className="white-space-16"></div>
                        <div>
                            <Link className="btn btn-green" to="comprar" onClick={props.comprar.bind(this, 1)}>
                                <FormattedMessage id="slider-comprar" defaultMessage="COMPRAR AHORA" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="slide justify-center" style={{ backgroundImage: `url(${Images['SetaSlider']})` }}>
                <div className="container align-center ">
                    <div className="column">
                        <h2 className="slide-h2">
                            <FormattedMessage id="slider-titulo" defaultMessage="DELICIAS DEL BOSQUE" />
                        </h2>
                        <div className="white-space-8"></div>
                        <h1 className="slide-h1 auto">
                            <FormattedMessage id="slider-boletus" defaultMessage="BOLETUS Y SETAS" />
                        </h1>
                        <div className="white-space-8"></div>
                        <p className="slide-p color-white">
                            <FormattedMessage id="slider-description1" defaultMessage="Nos dedicamos a transformar, elaborar y comercializar productos" /> <br />
                            <FormattedMessage id="slider-description2" defaultMessage="micológicos naturales y artesanos, respondiendo a una exigencia" /> <br />
                            <FormattedMessage id="slider-description3" defaultMessage=" " />
                        </p>
                        <div className="white-space-16"></div>
                        <div>
                            <Link className="btn btn-green" to="comprar" onClick={props.comprar.bind(this, 1)}>
                                <FormattedMessage id="slider-comprar" defaultMessage="COMPRAR AHORA" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>            

        </div>        
    );    

}

export default Slider;