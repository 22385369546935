import React, { Component } from "react";
import {connect} from 'react-redux';
import dayjs from "dayjs";
import Dates from "../orders/dates";
import OrdersTable from "../orders/orders-table";
import Modal from "../orders/modal";
import Request from '../../core/httpClient';
import Paginator from "../paginator/paginator";

const req = new Request();

class Pedidos extends Component {
	constructor(props) {
		super(props);
		this.state = {
			order: 0,
			pedidos: []
		};
	}

	componentDidMount() {
		let modal = document.getElementById("modal-order");
		let btn = document.getElementById("open-modal-order");
		if (btn) {
			btn.onclick = function() {
				modal.classList.remove("hidden");
			};
		}
		window.onclick = function(event) {
			if (event.target === modal) {
				modal.classList.add("hidden");
			}
		};
		window.onkeydown = event => {
			if (event.keyCode === 27) {
				modal.classList.add("hidden");
			} else {
				return 0;
			}
		};
	}

	async findOrders(start, end) {
		this.setState({loading: true});
		const data = {
			start: dayjs(start).format("YYYY-MM-DD"),
			end: dayjs(end).format("YYYY-MM-DD"),
			id_cliente: this.props.user.id_cliente
		};

		this.setState({ filters: {}, page: 1 });
		
		const res = await req.get('/pedidos/obtener', data);
		if(Array.isArray(res.data)) {
			this.setState({pedidos: res.data, loading: false});
		} else {
			this.setState({pedidos: [], message: res.message, loading: false});
		}
	}

	selectorder(order) {
		console.log(order)
		this.setState({ order: 0 });
		this.render();
		this.setState({ order: order });
	}

	setPage(page) {
		this.setState({ page: page });
	}

	render() {
		let pages = 1;
		let items = [];
		if (this.state.pedidos) {
			items = this.state.pedidos;
			pages = Math.ceil(items.length / 10);
		}

		return (
		<div className="workspace">
			<div className="control-content justify-center">
				<div className="container column">
					<div className="white-space-32" />
					<h3 className="color-black">
						PEDIDOS
					</h3>
					<div className="white-space-8" />
					<Dates setDates={this.findOrders.bind(this)} loading={this.state.loading} />
					<div className="white-space-16" />
					<OrdersTable page={this.state.page} selectorder={this.selectorder.bind(this)} pedidos={this.state.pedidos} loading={this.state.loading}/>
					<div className="white-space-16" />
					<Paginator
						setpage={this.setPage.bind(this)}
						items={pages}
					/>
					<div className="white-space-32" />
				</div>
			</div>
			<Modal
				order={this.state.order}
				dispatch={this.props.dispatch}
				loading={this.props.update_order_loading}
			/>
		</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user
	}
}

export default connect(mapStateToProps)(Pedidos);
