import React, { Component } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import * as action from "../../redux/actions";
import { Request } from '../../api/apiservice';

const req = new Request();

class CartTotalSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      envio: 0
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.pesos !== prevProps.pesos) {
      this.props.dispatch(action.getShipping(this.props.pesos, this.props.subtotal));
      //this.load(this.props.pesos);
    }
  }

  /*async load(pesos) {
    const data = { pesos: pesos };
    const res = await req.get('pedidos/shipping', data);
    if (res) {
      this.setState({ envio: res.shipping });
      window.localStorage.setItem('shipping', JSON.stringify(res.shipping));
    }
  }*/

  render() {
    let envio = 0;
    let total = this.props.subtotal;
  
    if (this.props.pesos > 0) {
      /*if (this.state.envio) {
        envio = this.state.envio;
      } else {
        envio = this.props.shipping;
      }*/
      envio = this.props.shipping;
      total = total + envio;
    }
    return (
      <div className="table-responsive table-total-summary">
        <table>
          <thead>
            <tr>
              <th className="th-total"> SUBTOTAL </th>
              <th className="th-tax">
                <FormattedMessage id="envio" defaultMessage="ENVIO" />
              </th>
              <th className="th-total"> TOTAL </th>
            </tr>
          </thead>
          <tbody>
            <tr className="table-row">
              <td className="table-total total-check">
                {this.props.subtotal === 0 || this.props.subtotal > 0 ? 
                <FormattedNumber currency="EUR" style="currency" value={this.props.subtotal} /> :
                <i className="fa-spin fas fa-spinner"></i>
              }            
              </td>
              <td className="table-total total-check">
              {
                total >= 100 ?
                (
                  '0'
                ) :
                envio === 0 || envio > 0 ?  ( <FormattedNumber currency="EUR" style="currency" value={envio} /> ) :
                ( <i className="fa-spin fas fa-spinner"></i> )
              }
              </td>
              <td className="table-total total-check">
              {
                total === 0 || total > 0 ?  ( <FormattedNumber currency="EUR" style="currency" value={total} />) :
                ( <i className="fa-spin fas fa-spinner"></i> )
              }                
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default CartTotalSummary;
