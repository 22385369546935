import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { IMG_URL } from '../../api/urls';


class CartTableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cantidad: this.props.item.cantidad
    };
  }

  handleChange(item, event) {
    const cart = this.props.carrito;
    const cantidad = parseFloat(event.target.value);
    Object.assign(cart.find(prod => prod.idProducto === item.idProducto), {
      cantidad: cantidad
    });
    this.setState({ cantidad: cantidad });
    this.props.dispatch(actions.updateCart(cart, item));
  }

  removeProduct(id) {
    this.props.dispatch(actions.removeProduct(id));
  }

  render() {
    const total = this.props.item.precio_promo ? this.props.item.precio_promo * this.props.item.cantidad : this.props.item.cantidad * this.props.item.precio;

    return (
      <tr className="table-row">
        <td className="table-image">
          <img
            src = { IMG_URL + "imagenes/obtener?id_imagen=" + this.props.item.idProducto }
            alt="Producto en carrito" title="Producto en carrito" />
        </td>
        <td className="table-product-name">{this.props.item.nombre}</td>
        <td className="table-quatity">
          <input type="number" name="quantity" className="input input-number" min="1" placeholder="Cantidad" 
          value={this.state.cantidad} onChange={this.handleChange.bind(this, this.props.item)} />
        </td>
        <td className="table-price">
          <FormattedNumber
            currency="EUR"
            style="currency"
            value={this.props.item.precio} />
        </td>
        <td className="table-price">
          {
            this.props.item.precio_promo ? 
            (
              <b>              
                <FormattedNumber currency="EUR" style="currency" value={this.props.item.precio_promo} />
              </b>
            ) :
            (
              "S/P"
            )
          }
          
        </td>
        <td className="table-total">
          <FormattedNumber currency="EUR" style="currency" value={total} />
        </td>
        <td className="table-delete">
          <button className="btn btn-red"
            onClick={this.removeProduct.bind(this, this.props.item.idProducto)} >
            <i className="fas fa-trash-alt" /> <FormattedMessage id="cart-row-remover" defaultMessage="ELIMINAR" />
          </button>
        </td>
      </tr>
    );
  }
}

function mapStateToProps(state) {
  return {
    carrito: state.carrito,
    count: state.carrito_count
  };
}

export default connect(mapStateToProps)(CartTableRow);
