import * as types from "./constants";

const localeInit = navigator.language.substring(0, 2);

const initialState = {
  auth: false,
  locale:
    localeInit === "es" || localeInit === "en"
      ? navigator.language.substring(0, 2)
      : "es",
  subtotal: 0,
  user: {},
  carrito: [],
  products: [],
  products_filter: [],
  categories: [],
  lines: [],
  category_selected: {},
  lines_selected: {},
  carrito_count: 0,
  last_prod: {},
  product_detail: {},
  relevantes: [],
  products_banner: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    /* ==========================================================================
    LOCALE
========================================================================== */
    case types.CHANGE_LOCALE:
      window.location.reload();
      return {
        ...state,
        locale: action.locale,
        products: [],
        products_filter: [],
        category_selected: {}
      };
    /* ==========================================================================
    CLIENTS
========================================================================== */
    case types.LOGIN:
      return {
        ...state,
        login_loading: true,
        login_error: undefined
      };
    case types.LOGIN_ERROR:
      return {
        ...state,
        login_loading: false,
        login_error: action.error
      };
    case types.LOGIN_SUCCESS:
      var modal = document.getElementById("modal-login");
      modal.classList.add("hidden");
      return {
        ...state,
        login_loading: false,
        login_error: action.error,
        auth: true,
        user: action.user,
        products: [],
        products_filtrado: []
      };
    case types.LOGOUT:
      return {
        ...state,
        auth: false,
        user: {},
        products: [],
        products_filtrado: [],
        pedidos: [],
        direccion: {},
        subtotal: 0
      };

    case types.CLIENT_REGISTER:
      return {
        ...state,
        login_error: undefined,
        sigin_message: undefined,
        client_register_loading: true
      };
    case types.CLIENT_REGISTER_ERROR:
      return {
        ...state,
        login_loading: false,
        login_error: action.error,
        sigin_message: undefined,
        client_register_loading: false
      };
    case types.CLIENT_REGISTER_SUCCESS:
      return {
        ...state,
        login_loading: false,
        login_error: action.error,
        sigin_message: action.message,
        client_register_loading: false
      };

    /* ==========================================================================
    PRODUCTS
========================================================================== */
    case types.GET_PRODUCTS:
      return {
        ...state,
        products_loading: true
      };
    case types.GET_PRODUCTS_ERROR:
      return {
        ...state,
        products_loading: false
      };
    case types.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products_loading: false,
        products: action.products,
        products_filter: action.products_filter || action.products,
        category_selected: action.idCategoria || {}
      };

    case types.GET_RELEVANTS:
      return {
        ...state,
        relevantes_loading: true
      };
    case types.GET_RELEVANTS_ERROR:
      return {
        ...state,
        relevantes_loading: false
      };
    case types.GET_RELEVANTS_SUCCESS:
      return {
        ...state,
        relevantes_loading: false,
        products: action.relevants
      };

    case types.GET_PRODUCTS_SEARCH:
      return {
        ...state,
        busqueda_loading: true
      };
    case types.GET_PRODUCTS_SEARCH_ERROR:
      return {
        ...state,
        busqueda_loading: false,
        error: action.error
      };
    case types.GET_PRODUCTS_SEARCH_SUCCESS:
      return {
        ...state,
        query: action.query,
        busqueda_loading: false,
        products: action.products,
        products_filter: action.products
      };

    case types.GET_CATEGORIES:
      return {
        ...state,
        products: [],
        categories_isLoading: true,
        category_selected: {},
        lines_selected: action.data.idLinea
      };
    case types.GET_CATEGORIES_ERROR:
      return {
        ...state,
        categories_isLoading: false,
        error: action.error
      };

    case types.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories_isLoading: false,
        categories: action.categories
      };

    case types.GET_LINES:
      return {
        ...state,
        lines_isLoading: true
      };
    case types.GET_LINES_ERROR:
      return {
        ...state,
        lines_isLoading: false,
        error: action.error
      };

    case types.GET_LINES_SUCCESS:
      return {
        ...state,
        lines_isLoading: false,
        lines: action.lines
      };

    case types.SET_LINE:
      //console.log(action);
      return {
        ...state,
        lines_selected: action.line
      };

    case types.SET_CATEGORY:
      //console.log(action);
      return {
        ...state,
        category_selected: action.category
      }

    /* ==========================================================================
        CART
    ========================================================================== */
    case types.ADD_CART:
      return {
        ...state,
        carrito: action.carrito,
        carrito_count: action.carrito.length,
        last_prod: action.producto,
        subtotal: action.subtotal,
        pesos: action.pesos
      };

    case types.ADD_CART_ITEM:
      return {
        ...state,
        carrito: action.carrito
      };

    case types.ADD_PEDIDO:
      return {
        ...state,
        add_pedido_loading: true
      };

    case types.ADD_PEDIDO_ERROR:
      return {
        ...state,
        add_pedido_loading: false
      };

    case types.ADD_PEDIDO_SUCCESS:
      return {
        ...state,
        add_pedido_loading: false
      };

    case types.COMPLETA_VENTA:
      return {
        ...state,
        carrito: [],
        carrito_count: 0,
        last_prod: {},
        subtotal: 0
      };

    case types.REMOVE_ALL_CART:
      return {
        ...state,
        carrito: [],
        subtotal: 0
      };

    case types.REMOVE_CART:
      return {
        ...state,
        carrito: action.carrito,
        carrito_count: action.carrito.length,
        last_prod: action.producto,
        subtotal: action.subtotal
      };

    case types.GET_SHIPPING_PRICE:
      return {
        ...state,
        shipping: action.shipping,
        pesos: action.pesos
      };

    /* ==========================================================================
    ORDERS
========================================================================== */
    case types.OBTENER_PEDIDOS:
      return {
        ...state,
        pedidos_loading: true
      };

    case types.OBTENER_PEDIDOS_ERROR:
      return {
        ...state,
        error: action.message,
        pedidos_loading: false
      };

    case types.OBTENER_PEDIDOS_SUCCESS:
      return {
        ...state,
        error: action.message,
        pedidos: action.pedidos,
        pedidos_loading: false
      };

    /* ==========================================================================
    BANNER
  ========================================================================== */ 

    case types.GET_PRODUCT_BANNER:
      return {
        ...state,
        products_loading: true
      };
    case types.GET_PRODUCT_BANNER_ERROR:
      return {
        ...state,
        products_loading: false
      };
    case types.GET_PRODUCT_BANNER_SUCCESS:
      return {
        ...state,
        products_loading: false,
        products_banner: action.products
      };
	case 'REDIRECT_BANNER':
		console.log(action);
		return {
			...state,
			lines_selected: action.data.linea,
			category_selected: action.data.categoria
		};
    default:
      return {
        ...state
      };
  }
}
