import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import * as action from "../redux/actions";
import { injectIntl, FormattedMessage } from 'react-intl'

// Web Components
import PageBanner from "../components/banner/page-banner";
import ProductsContainer from "../components/products/products-container";
import Paginator from "../components/paginator/paginator";
import ProducstLine from "../components/products/products-line";
import ProductsCategories from "../components/products/products-categories";
import PopOver from "../components/popover/popover";

class Comprar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      numitems: 6,
      selectMessage: ''
    };

    if (this.props.products.length <= 0) {
      if (this.props.lines_selected && this.props.categories.length > 0) {
        const idLinea = parseInt(this.props.lines_selected);
        //console.log(idLinea);
        this.props.dispatch(action.getCategories(idLinea, this.props.intl.locale));
        this.props.dispatch(action.getProducts(idLinea, this.props.intl.locale));
        //console.log(this.props.category_selected);
        this.props.dispatch({type: 'SET_CATEGORY', category: this.props.category_selected});
      }
    }
  }

  setPage(page) {
    this.setState({ page: page });
  }

  render() {
    const page = this.state.page;
    let pages = 1;
    let items = [];
    if (this.props.products) {
      items = this.props.products;
      pages = Math.ceil(items.length / this.state.numitems);
    }
    return (
      <div className="column">
        <Helmet>
          <title>Elfos Gourmet - Comprar</title>
        </Helmet>
        <PageBanner bannerImage="bannershop" bannerTitle={<FormattedMessage id="comprar-productos" defaultMessage=" PRODUCTOS"/>} />
        <div className="products-main column">
          <div className="justify-center">
            <div className="container">
              <div className="row-responsive">
                <div className="left column">
                  {this.props.products_loading ? (
                    <div className="justify-center">
                      <i className="color-darkGreen fas fa-spinner fa-spin fa-2x" />
                    </div>
                  ) : (
                    <ProductsContainer items={items} page={page} numitems={this.state.numitems} />
                  )}
                  <div className="white-space-32" />
                  {items.length > this.state.numitems ? 
                  <Paginator
                    setpage={this.setPage.bind(this)}
                    items={pages}
                  /> : null}
                  <div className="white-space-32" />
                </div>
                <div className="sidebar column">
                  <ProducstLine />
                  <ProductsCategories />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PopOver icon="fas fa-cart-plus" text="AÑADIDO AL CARRITO" />
      </div>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    //console.log("Comprar");

    //Filtrar productos por línea.
    if (this.props.lines_selected) {
      const idLinea = parseInt(this.props.lines_selected);
      this.props.dispatch(action.getCategories(idLinea, this.props.intl.locale));

      if (this.props.category_selected) {
        const idCategoria = parseInt(this.props.category_selected);
        if (isNaN(idCategoria)) {
          this.props.dispatch(action.getProducts(idLinea, this.props.intl.locale));
        } else {
          this.props.dispatch(action.filterProducts({ idCategoria: idCategoria }));
          this.props.dispatch({ type: 'SET_CATEGORY', category: idCategoria });
        }
      }
    }

    /*if (this.props.lineas_selected) {
      const idLinea = parseInt(this.props.lines_selected);
      this.props.dispatch(action.getCategories(idLinea, this.props.intl.locale));
    }*/
    /*console.log(this.props.category_selected);
    if(this.props.category_selected) {
      this.props.dispatch(action.filterProducts({ idCategoria: this.props.category_selected }));
    }*/

    /*if (this.props.products.length <= 0) {
      if (this.props.lines_selected && this.props.categories.length > 0) {
        const idLinea = parseInt(this.props.lines_selected);
        console.log(idLinea);
        this.props.dispatch(action.getCategories(idLinea, this.props.intl.locale));
        this.props.dispatch(action.getProducts(idLinea, this.props.intl.locale));
      }
    }*/
  }
}

function mapStateToProps(state) {
  return {
    products: state.products,
    products_loading: state.products_loading,
    categories: state.categories,
    category_selected: state.category_selected,
    products_filter: state.products_filter,
    lines_selected: state.lines_selected
  };
}

export default injectIntl(connect(mapStateToProps)(Comprar));
