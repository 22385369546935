import React, { Component } from "react";
import store from "../redux/store";
import { FormattedMessage } from "react-intl";

class CheckOutStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getUrlVars() {
    var vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
      m,
      key,
      value
    ) {
      vars[key] = value;
    });
    return vars;
  }

  renderSuccess() {
    return (
      <div className="checkout-status-approved justify-center">
        <div className="container column">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 130.2 130.2"
          >
            <circle
              className="path circle"
              fill="none"
              stroke="#73AF55"
              strokeWidth="6"
              strokeMiterlimit="10"
              cx="65.1"
              cy="65.1"
              r="62.1"
            />
            <polyline
              className="path check"
              fill="none"
              stroke="#73AF55"
              strokeWidth="6"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="100.2,40.2 51.5,88.8 29.8,67.5 "
            />
          </svg>
          <div className="white-space-32" />
          <h2 className="color-black text-center">
            <FormattedMessage
              id="checkout-success-felicidades"
              defaultMessage="¡FELICIDADES, TU COMPRA FUE APROBADA!"
            />
          </h2>
          <div className="white-space-16" />
          {/*<p className="text-center">
                        FOLIO: {folio}
            </p>
        */}
          <div className="white-space-16" />
          <div className="btn-container-check-status justify-center">
            <a href="/inicio" className="btn btn-green">
              <FormattedMessage
                id="checkout-regresar"
                defaultMessage="REGRESAR A INICIO"
              />
            </a>
          </div>
          <div className="white-space-32" />
        </div>
      </div>
    );
  }

  renderFail() {
    return (
      <div className="checkout-status-approved justify-center">
        <div className="container column">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 130.2 130.2"
          >
            <circle
              className="path circle"
              fill="none"
              stroke="#D06079"
              stroke-width="6"
              stroke-miterlimit="10"
              cx="65.1"
              cy="65.1"
              r="62.1"
            />
            <line
              className="path line"
              fill="none"
              stroke="#D06079"
              stroke-width="6"
              stroke-linecap="round"
              stroke-miterlimit="10"
              x1="34.4"
              y1="37.9"
              x2="95.8"
              y2="92.3"
            />
            <line
              className="path line"
              fill="none"
              stroke="#D06079"
              stroke-width="6"
              stroke-linecap="round"
              stroke-miterlimit="10"
              x1="95.8"
              y1="38"
              x2="34.4"
              y2="92.2"
            />
          </svg>
          <div className="white-space-32" />
          <h2 className="color-black text-center">
            ¡LO SENTIMOS, TU COMPRA FUE RECHAZADA!
          </h2>
          <div className="white-space-16" />
          <div className="white-space-16" />
          <div className="btn-container-check-status justify-center">
            <a href="/inicio" className="btn btn-green">
              REGRESAR A INICIO
            </a>
          </div>
          <div className="white-space-32" />
        </div>
      </div>
    );
  }

  render() {
    let renderPage;
    let locationPath = this.props.location.pathname;
    if (locationPath.includes("success")) {
      renderPage = this.renderSuccess();
      store.dispatch({ type: "COMPLETA_VENTA" });
    } else if (locationPath.includes("fail")) {
      renderPage = this.renderFail();
    }

    return <div className="column">{renderPage}</div>;
  }
}

export default CheckOutStatus;
