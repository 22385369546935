import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { IntlProvider, addLocaleData } from "react-intl";

//SASS
import './sass/style.scss';

// Web components
import store from "./redux/store";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";

//Web Pages
import Inicio from "./pages/inicio";
import NotFoundPage from "./pages/not-found";
import Comprar from "./pages/comprar";
import Registro from "./pages/registro";
import Perfil from "./pages/perfil";
import Nosotros from "./pages/nosotros";
import Producto from "./pages/producto";
import Carrito from "./pages/carrito";
import CheckOut from "./pages/checkout";
import CheckOutStatus from "./pages/checkout-status";
import Pedidos from "./pages/pedidos";
import Legalidad from "./pages/legalidad";
import Restore from "./pages/restore";
import RestorePass from './pages/restore-password';
import Widget from './components/popover/widget';

//locales
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import messages_en from './locales/en.json';
import messages_es from './locales/es.json';

addLocaleData([...en, ...es]);

const messages = {
  'es': messages_es,
  'en': messages_en
};

const locale = store.getState().locale || 'es'


class Routes extends Component {
  render() {
    return (
      <IntlProvider locale={locale} messages={messages[locale]}>
        <Provider store={store}>
          <BrowserRouter>
            <div className="flex main">
              <div className="column">
                <Header />
                <Switch>
                  <Route path="/" exact component={Inicio} />
                  <Route path="/inicio" exact component={Inicio} />
                  <Route path="/comprar" exact component={Comprar} />
                  <Route path="/registro" exact component={Registro} />
				          <Route path="/perfil" exact component={Perfil} />
                  <Route path="/nosotros" exact component={Nosotros} />
                  <Route path="/producto" exact component={Producto} />
                  <Route path="/producto/:id" exact component={Producto} />
                  <Route path="/carrito" exact component={Carrito} />
                  <Route path="/checkout" exact component={CheckOut} />
                  <Route path="/legalidad" exact component={Legalidad} />
                  <Route path="/restore" exact component={Restore} />
                  <Route path="/restore/:id" exact component={RestorePass} />
                  <Route
                    path="/checkout/success"
                    exact
                    component={CheckOutStatus}
                  />
                  <Route
                    path="/checkout/fail"
                    exact
                    component={CheckOutStatus}
                  />
                  <Route path="/pedidos" exact component={Pedidos} />
                  <Route component={NotFoundPage} />
                </Switch>
                <Footer />
                <Widget></Widget>
              </div>
            </div>
          </BrowserRouter>
        </Provider>
      </IntlProvider>
    );
  }
}

export default Routes;
