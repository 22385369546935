import React from 'react';

// Images
import Images from '../../img/index';

const PageBanner = function( props ) {

    return(
        <div className="page-banner column" style={{ backgroundImage: `url(${Images[props.bannerImage]})` }}>
            <div className="justify-center">
                <div className="container column">
                    <div className="white-space-64"></div>
                    <h2 className="color-white text-center uppercase">
                        {props.bannerTitle}                        
                    </h2>
                    <div className="white-space-64"></div>
                </div>
            </div>
        </div>
    );

}

export default PageBanner;