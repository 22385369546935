import React, { Component } from "react";

// Images 
import Images from '../img/index';
const tableStyle = {tableLayout: 'fixed'};

class Legalidad extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div className="justify-center">
                <div className="container column">
                    <div className="white-space-32"></div>
                    <h3 className="color-black">
                        INFORMACIÓN LEGAL
                    </h3>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        Facuven Productos Silvestres, S.L. (titular del dominio elfosgourmet.eu) cumple con los requisitos establecidos en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico, así como en la Ley Orgánica 15/1999, de 13 de diciembre, sobre Protección de Datos de Carácter Personal y a tal fin ha creado el siguiente aviso legal.
                        La navegación por la página Web  elfosgourmet.eu. implica la perfecta comprensión y la expresa aceptación de las mencionadas condiciones por parte del usuario.
                        El titular del sitio Web se reserva el derecho de efectuar sin previo aviso las modificaciones que considere oportunas en el mismo pudiendo cambiar suprimir o añadir los contenidos y servicios que se prestan, como la forma en la que éstos aparecen presentados o localizados.
                    </p>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        Información General
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        Facuven Productos Silvestres, S.L. <br />
                        C.I.F. B09538653 <br />
                        Poligono Industrial La Nava,2 42146 Cabrejas del Pinar, Soria <br />
                        Tel. (+34)670423491 <br />
                        Inscrita en el Registro Mercantil de Burgos en el Folio 207 Tomo 655 Libro 446 H <br/>
                        Hoja Número BU-14717
                    </p>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        Propiedad Intelectual
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        Todos los contenidos de la presente página Web, incluyendo sin carácter limitativo, textos, gráficos, imágenes, su diseño y los derechos de propiedad intelectual que pudieran corresponder a dichos contenidos, así como todas las marcas, nombres comerciales o cualquier otro signo distintivo son propiedad del titular del sitio Web, o de sus legítimos propietarios, quedando reservados todos los derechos sobre los mismos.
                        Queda prohibido cualquier acto de reproducción de los contenidos, en todo o en parte, en cualquier forma o medio, sea mecánico, electrónico, reprográfico u otro, así como cualquier acto de difusión, comunicación pública o distribución, sin la previa autorización del titular del sitio Web o de sus legítimos propietarios.
                        El titular del sitio Web no será responsable de los daños o perjuicios que pudieran derivarse de la utilización de los contenidos por parte de los usuarios o de la infracción por parte de éstos de cualquier disposición legal vigente.
                    </p>
                    <div className="white-space-32"></div>
                    <h3 className="color-black" id="privacidad">
                        POLÍTICA DE PRIVACIDAD
                    </h3>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        El titular del sitio Web protege los datos personales según lo establecido en la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal, y en su normativa de desarrollo.
                        En cumplimiento de la LOPD, le informamos que los datos personales que nos suministre a través de la página Web serán tratados confidencialmente. Sus datos personales serán utilizados únicamente para la gestión y envío de su pedido, así como para el envío de información que el usuario haya podido solicitar y futuras comunicaciones comerciales que pudieran ser de su interés.
                        Le informamos que puede ejercitar sus derechos de acceso, rectificación, cancelación y oposición con arreglo a lo previsto en la LOPD, enviando un correo electrónico a la siguiente dirección:  
                        Los datos personales que usted proporcione no serán vendidos, alquilados ni puestos a disposición de terceras personas.
                        El usuario garantiza que los datos personales facilitados son veraces y se hace responsable de comunicar cualquier modificación en los mismos. En el caso de que el usuario proporcione datos de carácter personal referentes a terceros a través de los distintos formularios del presente sitio Web, el usuario deberá con carácter previo a su inclusión informarles de los extremos contenidos en a presente política de privacidad.
                        Para que el sitio Web funcione óptimamente, a veces es necesario obtener diversa información del ordenador que accede a ella o identificarlo. Para la identificación mencionada, habitualmente se utilizan unos ficheros llamados “cookies” que se almacenan temporalmente en su ordenador. El sitio Web consulta las “cookies” automáticamente cuando usted accede a él, y gracias a ello puede ofrecerle contenidos específicos en función de elecciones anteriores, permitirle el acceso a zonas reservadas o almacenar los productos que usted ha seleccionado en caso de que exista un sistema de compra con “carrito” o “cesta”, entre otras muchas funciones posibles.
                        Usted puede configurar su navegador de Internet para que no acepte cookies, pero en ese caso es posible que no pueda utilizar todas las funcionalidades que le ofrece el sitio Web.
                    </p>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        Enlaces
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        El titular del sitio Web no asume ninguna responsabilidad sobre los enlaces externos que, en su caso, pudieran incluirse en la página Web, por lo que el usuario accede bajo su exclusiva responsabilidad al contenido y en las condiciones de uso que rijan en los mismos.
                    </p>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        Legislación aplicable
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        El presente Aviso Legal se rige en todos y cada uno de sus extremos por la ley española. En caso de cualquier conflicto o discrepancia el fuero aplicable será el de los juzgados y tribunales de Soria.
                    </p>
                    <div className="white-space-32"></div>
                    <h3 className="color-black" id="terminos">
                        TÉRMINOS Y CONDICIONES DE USO
                    </h3>
                    <div className="white-space-16"></div>
                    <p className="text-jusfity">
                        Las presentes condiciones regulan el acceso y utilización del sitio Web elfosgourmet.eu El sitio Web ha sido creado por 
                        Facuven Productos Silvestres, S.L. con domicilio social en Poligono Industrial La Nava,2 C.P. 42146 Cabrejas del Pinar (Soria) e Inscrita en el Registro Mercantil de Burgos en el Folio 207 Tomo 655 Libro 446 H <br />
                        Hoja Número BU-14717 con CIF: B09538653 <br/>
                        Todas las ventas y demás operaciones comerciales realizadas entre Facuven Productos Silvestres, S.L. y el usuario a través del sitio Web quedarán sujetas a las presentes condiciones que se considerarán conocidas y aceptadas sin reservas por el usuario al acceder al mismo. Las condiciones pueden sufrir modificaciones, Facuven Productos Silvestres, S.L. se reserva el derecho de modificar unilateralmente las presentes condiciones, sin que ello pueda afectar a los bienes o promociones que fueron adquiridos previamente a la modificación. Le recomendamos que las consulte previamente cada vez que acceda a la Web. <br/>
                        El hecho de pulsar el botón de aceptar, conlleva que el usuario:
                    </p>
                    <div className="white-space-8"></div>            
                    <p>a) Ha leído, entiende y comprende lo aquí expuesto en su totalidad.</p>
                    <p>b) Que es una persona con capacidad suficiente para contratar.</p>
                    <p>c) Que asume todas las obligaciones dispuestas en los presentes Términos y Condiciones.</p>                
                    <div className="white-space-8"></div>
                    <p className="text-justify">
                        Si el Usuario no está de acuerdo con las mismas, no tendrá derecho a utilizar el sitio Web elfosgourmet.eu
                    </p>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        Registro de cuenta de cliente
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        El cliente con carácter previo a la formalización de la compra de cualquiera de los productos y servicios por Facuven Productos Silvestres, S.L deberá proporcionar de manera libre y voluntaria su nombre, apellidos y correo electrónico.
                        El cliente solo podrá contratar y realizar pedidos si es mayor de edad. La plena responsabilidad en la determinación de los concretos contenidos y servicios a los que acceden los menores de edad corresponde única y exclusivamente a los mayores a cuyo cargo se encuentran.
                        En el caso de que los datos proporcionados por el cliente sean fraudulentos y/o falsos, Facuven Productos Silvestres, S.L corresponderle contra el infractor.
                        El cliente asimismo declara que toda la información suministrada por él para el acceso a este sitio Web y en el curso de la utilización de este, es verdadera, completa y precisa y se compromete a mantenerla actualizada. <br/>
                        Facuven Productos Silvestres, S.L. ofrecerá al usuario un sistema de registro a través de una contraseña para facilitar al cliente futuras operaciones de compra. <br/>
    
                        El cliente se compromete a asegurar la confidencialidad de su contraseña. El usuario se compromete a hacer uso diligente de la misma, y a no ponerla a disposición de terceros, así como a comunicar Facuven Productos Silvestres, S.L. la pérdida o robo de la misma o del posible acceso por un tercero no autorizado, de tal forma que ésta pueda proceder al bloqueo inmediato del usuario. Facuven Productos Silvestres, S.L. queda eximida expresamente de cualquier responsabilidad derivada del incorrecto uso y/o negligencia por parte del usuario en el cumplimiento de su obligación de confidencialidad de su contraseña.
                    </p>                                
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        Direcciones de entrega
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        A la hora de rellenar los datos de compra se contempla la posibilidad de enviar la compra a otra dirección diferente a la de facturación, bien porque es un regalo, porque se quiere enviar el pedido a otra delegación, o cualquier otro motivo.
                    </p>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        Cómo comprar
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        Para poder comprar en la presente página Web, es necesario que se haya registrado en la misma; una vez que el usuario esté registrado, podrá realizar la compra.
                        De conformidad con lo exigido por el art. 27 de la Ley 34/2002 de Servicios de la Sociedad de la Información y del Comercio Electrónico, se informa que el procedimiento de contratación seguirá los siguientes pasos:
                        Desde la página principal, el cliente podrá consultar distintos productos y alimentos selectos vascos, y podrá elegir entre los disponibles en cada momento. <br />
                        Una vez seleccione los que le interesen, deberá seleccionar la opción comprar, y deberá seguir los siguientes pasos.
                    </p>
                    <div className="white-space-8"></div>
                    <p>1) Registro</p>
                    <p>2) Información de facturación</p>
                    <p>3) Información del envío</p>
                    <p>4) Método de envío</p>
                    <p>5) Información de pago</p>
                    <p>6) Revisión del pedido</p>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        Limitación de responsabilidad
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        Facuven Productos Silvestres, S.L ofrece este sitio Web en el estado en el que se presenta. Puede contener imprecisiones o erratas. Facuven Productos Silvestres, S.L no efectúa manifestaciones ni ofrece garantías de ninguna clase, ya sean explícitas o implícitas, en cuanto al funcionamiento el sitio Web o a la información, contenido, software, materiales o productos incluidos en el mismo. En la medida que lo permita la legislación aplicable, Facuven Productos Silvestres, S.L queda exonerada de cualesquiera garantías implícitas de comerciabilidad e idoneidad para un fin determinado. Facuven Productos Silvestres, S.L. no será responsable de los daños o perjuicios de cualquier índole que puedan derivarse del uso de este sitio Web, incluidos, entre otros, los daños directos e indirectos. <br />
                        Facuven Productos Silvestres, S.L no asume ninguna responsabilidad ni garantiza que el acceso al Sitio Web sea ininterrumpido. En ningún caso Facuven Productos Silvestres, S.L. será responsable de las pérdidas, daños o perjuicios de cualquier tipo que surjan por el uso de, y la navegación por, el Sitio Web, incluyendo, sin limitación, los daños ocasionados a los sistemas informáticos o los provocados por la introducción de virus. En particular, Facuven Productos Silvestres, S.L no se hace responsable en modo alguno de las caídas, interrupciones, falta o defecto de las telecomunicaciones que pudieran ocurrir en el transcurso de operaciones de naturaleza financiera ni de aquellos daños y perjuicios que pudiesen generarse en tales casos.
                    </p>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        Vínculos o enlaces con otros sitios Web
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        El presente sitio Web puede contener vínculos o enlaces con otros sitios Web no gestionados por Facuven Productos Silvestres, S.L Dichos vínculos o enlaces, se incluyen sólo a efectos de referencia. Facuven Productos Silvestres, S.L. no ejerce control alguno sobre dichos sitios Web, ni es responsable del contenido de los mismos.
                        La inclusión de vínculos o enlaces a otros sitios Web no implica Facuven Productos Silvestres, S.L promocione, avale, garantice o recomiende ese sitio Web. Los vínculos o enlaces que incluye el presente sitio Web únicamente son ofrecidos como referencias informativas sin ningún tipo de valoración sobre los contenidos, propietarios o servicios o productos ofrecidos desde los mismos.
                    </p>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        Información de carácter general
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        Las presentes Condiciones Generales, así como cualquier relación entre el cliente y Facuven Productos Silvestres, S.L se regirán por la legislación española, sometiéndose expresamente las partes a la jurisdicción de los Juzgados y Tribunales de la ciudad de Soria. Para cualquier cuestión litigiosa derivada de la existencia o contenido de estas Condiciones Generales o de las relaciones entre el cliente y Facuven Productos Silvestres, S.L ambas partes se acogen a lo que indique la ley al respecto.
                    </p>
                    <div className="white-space-32"></div>
                    <h3 className="color-black">
                        TÉRMINOS Y CONDICIONES GENERALES DE CONTRATACIÓN
                    </h3>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        PREVIO
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        Estos son los Términos y Condiciones de Venta que regulan la relación contractual de adquisición de productos que Facuven Productos Silvestres S.L. ofrece en su Tienda Online www.elfosgourmet.eu  .
                        Los Términos y Condiciones de Venta descritos a continuación, rigen exclusivamente las relaciones contractuales entre todo usuario de las páginas Web de Facuven Productos Silvestres S.L (denominado en adelante “Cliente”) y el vendedor que es la plataforma/tienda online elfosgourmet.eu (denominada en adelante “Facuven Productos Silvestres S.L y “Nosotros”). <br/>
                        Se entiende por “contrato” la operación de compraventa que se instrumenta mediante este documento. Se entiende por “producto” la mercancía objeto de compra. <br/>
                        Por tanto, el Usuario se compromete a leer atentamente las condiciones de contratación, cada vez que proceda a la contratación de algún producto, dado que las mismas han podido ser objeto de modificación desde la última vez que accedió. <br/>
                        Mediante la aceptación de las presentes Condiciones Generales de contratación el Usuario declara: <br/>
                        a) Que es una persona con capacidad para contratar. <br/>
                        b) Que ha leído y acepta las presentes condiciones generales de contratación. <br/>
                        El Usuario tendrá acceso siempre y en todo caso, con carácter previo al inicio del procedimiento de compra de productos, a las condiciones de contratación, pudiendo ser almacenadas y/o reproducidas en un soporte duradero.
                    </p>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        1.	PRECIO
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        Los precios de nuestros productos se expresan en euros e incluyen el Impuesto del Valor Añadido (IVA) correspondiente según el producto. <br />
                        Facuven Productos Silvestres S.L se reserva el derecho de modificar sus precios en todo momento, pero los productos se facturarán sobre la base de las tarifas vigentes en el momento en que se registre el pedido.
                    </p>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        2.	DISPONIBILIDAD DE PRODUCTO
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        Nuestras ofertas y precios son válidas mientras se expongan en el sitio web, y siempre en función de las existencias disponibles. <br/>
                        Nos reservamos la posibilidad de asignar un máximo de botellas de cada vino por cliente. Las asignaciones se harán por riguroso orden de recepción de pedido. <br/>
                        Normalmente sólo ofertamos productos que tenemos en stock. Para los productos que no se encuentren en nuestros almacenes, nuestras ofertas son válidas con la condición de que exista disponibilidad por parte de nuestros proveedores. Dentro de este marco, se facilitarán indicaciones sobre la disponibilidad de los productos en el momento en que se procese cada pedido. <br/>
                        En el caso de que el producto no esté disponible tras el procesamiento de un pedido, se informará al comprador por correo electrónico o por teléfono de la entrega de sólo una parte de su pedido pudiendo el mismo aceptar o cancelar el pedido. En cualquier caso, el cliente recibirá la devolución del importe parcial o completo en un plazo máximo de 72h.
                    </p>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        3.	PAGO DEL PEDIDO
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        Los pagos de los pedidos se realizarán online mediante tarjeta. El cargo a la tarjeta de crédito se hará en el momento de la compra. <br/>
                        Todas las transacciones de compra se realizan dentro de un estricto marco de confidencialidad y cifrado gracias al protocolo SSL. Se garantiza la confidencialidad de los datos facilitados por el cliente mediante encriptación, evitando su comunicación a terceros. De modo aleatorio, para reforzar la seguridad y para verificar la fiabilidad de los datos facilitados durante la realización de un pedido, Facuven Productos Silvestres S.L podrá solicitar al cliente datos complementarios (como copia del DNI o similar) que permitan verificar que la compra ha sido realizada por el usuario titular de la tarjeta. El documento deberá ser enviado en un plazo de 3 días hábiles, reservándose Facuven Productos Silvestres S.L el derecho a efectuar el reembolso del pedido si no recibe los documentos solicitados o si estos no son válidos. En cualquier caso, Facuven Productos Silvestres S.L no tiene acceso a los datos bancarios ni los guarda en sus servidores.
                    </p>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        4.	CONDICIONES DE ENVÍO
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        Los productos se envían a la dirección indicada por el cliente durante la realización del pedido. Con el fin de optimizar la entrega, invitamos al comprador a que indique una dirección en la cual el pedido pueda ser entregado laborables de lunes a viernes de 8.30 h a 20.00 horas. El envío se entregará en la dirección indicada en un plazo de 48-72h desde el pego de pedido. <br/>
                        El coste de los envíos se calcula en función de variables de lo que se pide como son el peso, su volumen, el tramo de recorrido, etc.  En las compras superiores a 100€ el transporte será gratuito, de lo contrario, en pedido inferiores a 100€ el transporte lo costeará el cliente. Los precios de transporte serán los siguientes:
                    </p>
                    <div className="white-space-8"></div>
                    <div className="responsive-img">
                        <img src={Images['tarifas']} alt="Tarifas" title="Tarifas" />
                    </div>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        En el caso de las Islas Canarias, Islas Baleares, Ceuta y Melilla y debido a su problemática logística, Facuven Productos Silvestres, S.L sirve el producto siempre y cuando el cliente contacte por email o telefónicamente con nuestro Departamento de Atención al Cliente +34670423491, que le comentará cual es la operativa a seguir con anterioridad a la realización del pedido. <br/>
                        Todos los envíos van adecuadamente protegidos en cajas diseñadas especialmente para garantizar que el producto llegue en perfectas condiciones. <br/>
                        El cliente se responsabiliza de verificar el contenido y estado de la mercancía en el momento de la entrega, siendo que aceptada la misma y consignando su firma en la copia del albarán a devolver al transportista manifiesta su conformidad sobre lo recibido y renunciando a reclamaciones posteriores sobre la idoneidad e identidad de la mercancía solicitada y recibida. En el caso de verificar el cliente en el momento de la entrega errores en la mercancía recibida o que ésta se encuentre dañada, deberá consignar estas circunstancias en el albarán del transportista y poner en conocimiento estos hechos a Facuven Productos Silvestres, S.L en el plazo de 24 horas, mediante el servicio de atención al cliente publicado en la página web. <br/>
                        La entrega en domicilio será de 48 a 72 horas en España desde la confirmación del pedido, islas canarias y baleares y países extranjeros a consultar con Facuven Productos Silvestres, S.L. <br/>
                        Facuven Productos Silvestres, S.L no se responsabiliza de retrasos en la entrega por causas ajenas al normal funcionamiento del servicio de logística, como huelgas, accidentes, etc. o la ausencia del remitente en el momento de la entrega en el domicilio. Los sábados, domingos y festivos no habrá servicio de entrega y se cuentan como días no hábiles. <br/>
                        En caso que el transportista intentara entregarle el paquete y no hubiera nadie para recibirlo, le dejará una nota haciendo constar el intento de entrega. Por favor contacte con Facuven Productos Silvestres S.L o con la agencia de transporte lo antes posible a través del número de teléfono que encontrará en la nota, con el fin de acordar una nueva entrega. <br/>
                        Si en esta segunda entrega no hubiera nadie para recibirlo, le dejará una nota de nuevo haciendo constancia del segundo intento de entrega. A partir de entonces, su pedido permanecerá en la oficina de la agencia de transporte durante 15 días. <br/>
                        En el momento en que reciba su paquete, debe: <br/>
                        1.	Comprobar la conformidad de la mercancía recibida en el momento de la entrega. <br/>
                        2.	Señalar cualquier anomalía en el albarán de entrega, haciendo constar sus reservas e incluyendo su firma. <br/>
                        3.	Los proveedores de transporte no permiten la devolución de la mercancía si el cliente la abre en su presencia, pero puede revisar la caja desde el exterior y si detecta que la caja está húmeda o mojada, no acepte el pedido. Nuestro partner nos notificará la incidencia y automáticamente mandaremos un nuevo envío sin coste alguno para usted. <br/>
                        Facuven Productos Silvestres S.L no asume responsabilidad alguna por las consecuencias de posibles retrasos en el envío. <br/>
                        A través del presente sitio web, el cliente tiene acceso a información sobre alimentos selectos y, puede realizar la compra de tales alimentos.
                    </p>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        5.	CANCELACIONES Y DEVOLUCIONES
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        Los pedidos se pueden cancelar sin coste alguno hasta el momento en que el envío se hace efectivo. Si recibimos aviso de cancelación una vez el paquete ya esté enviado, se considerará una devolución. Las devoluciones pueden hacerse hasta 7 días naturales a partir de la recepción del producto según las siguientes especificaciones: <br />
                        1.	No se podrá ejercer el derecho de devolución cuando existan indicios de que los productos han sido abiertos, manipulados o expuestos a condiciones inadecuadas. <br/>
                        2.	Es obligatorio devolver los productos protegidos correctamente, en su embalaje original, en perfecto estado para su venta posterior. <br/>
                        3.	No se podrá aceptar ningún paquete que carezca de alguno de los elementos de identificación del remitente (nombre, apellidos, dirección, número de pedido). <br/>
                        4.	La devolución de los productos solicitados dará lugar al reembolso de la totalidad de las cantidades abonadas por el Usuario. <br/>
                        5.	Los gastos de envío corren a cargo del cliente, así como los de devolución. Quedan excluidas de este caso aquellas devoluciones que se deriven de un error nuestro o de algún defecto del producto. En tal caso, Facuven Productos Silvestres S.L se compromete a cambiar el producto defectuoso por otro igual o se reintegrará el importe. También se hará cargo de los gastos de devolución y posterior envío. <br/>
                    </p>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        6.	PROTECCIÓN DE DATOS PERSONALES
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        Los datos personales que el Cliente debe facilitarnos son indispensables para el envío de los pedidos y redacción de facturas. La ausencia de estos datos conllevará la anulación automática de los pedidos. Al inscribirse en la página, el Cliente se compromete a proporcionarnos datos personales válidos, datos que permitan la prestación del servicio por parte de Facuven Productos Silvestres S.L  y la correcta identificación del Cliente registrado, así como el envío de información y publicidad de los diferentes productos y ofertas que Facuven Productos Silvestres S.L pueda tener en sus páginas web. <br />
                        El Usuario manifiesta que todos los datos facilitados por él son ciertos y correctos, y se compromete a comunicar a Facuven Productos Silvestres S.L los cambios que se produzcan en los mismos. El Usuario tiene derecho a oponerse al tratamiento de cualquiera de sus datos que no sean imprescindibles para la celebración del contrato y a su utilización para cualquier finalidad distinta del mantenimiento de su relación contractual. <br />
                        Facuven Productos Silvestres S.L cumple con lo establecido en la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal, y la normativa sobre Servicios de la Sociedad de la Información y de Comercio Electrónico, por lo que ha adoptado los procedimientos administrativos y técnicos necesarios para garantizar la seguridad de los datos personales que recopilamos. <br />
                        En el momento en que un cliente realiza un pedido, sus datos personales, domiciliarios y los relativos a su forma de pago, son incorporados a nuestra base de datos, utilizándose únicamente para tramitar el pedido y enviar información sobre ofertas y servicios de las empresas del grupo que puedan resultar de interés a nuestros clientes. Facuven Productos Silvestres S.L asegura la confidencialidad de los datos aportados por nuestros clientes, ya que los datos personales que se solicitan son siempre los estrictamente necesarios. <br />
                        Facuven Productos Silvestres S.L se compromete a no darles otro uso que el acordado y a no cederlos ni venderlos a terceros ajenos a las empresas del grupo bajo ningún concepto. En cualquier caso los clientes de Facuven Productos Silvestres S.L podrán en todo momento ejercitar los derechos de acceso, rectificación, cancelación y oposición, comunicándolo por escrito a hola@elfosgourmet.eu <br />
                    </p>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        7.	PROPIEDAD INTELECTUAL
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        Todos los contenidos de las Tiendas Online de Facuven Productos Silvestres S.L (ilustraciones, textos denominaciones, marcas propias, imágenes, videos…) son propiedad de Facuven Productos Silvestres S.L y sus colaboradores. Las marcas de terceros son propiedad de sus titulares. Toda reproducción parcial o total del contenido mediante cualquier procedimiento y en cualquier soporte está sujeta a una autorización previa y expresa de Facuven Productos Silvestres S.L o de los titulares de los derechos, en su caso. <br />
                        Facuven Productos Silvestres S.L no se responsabiliza del mal uso que se realice de los contenidos de las páginas Web, siendo exclusiva responsabilidad del usuario que acceda a los mismos o los utilice. <br />
                        Facuven Productos Silvestres S.L no asume responsabilidad alguna por la información contenida en páginas Web de terceros a las que se pueda acceder por enlaces (links) o buscadores de las páginas Web. La presencia de enlaces (links) en las páginas Web tiene finalidad meramente informativa y en ningún caso supone sugerencia, invitación o recomendación sobre los mismos. <br />
                        Todas las marcas, logotipos y anagramas mostrados en este sitio son propiedad de Facuven Productos Silvestres S.L o de terceras empresas. No podrán utilizarse marcas, nombres comerciales, rótulos de establecimientos, denominaciones, logotipos, eslóganes o cualquier tipo de signo distintivo perteneciente a Facuven Productos Silvestres S.L sin el permiso escrito de Facuven Productos Silvestres S.L o de la tercera empresa.
                    </p>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        8.	ENLACES CON TERCEROS
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        Existe la posibilidad de compartir contenidos o cualquier tipo de información a través de redes sociales u otras aplicaciones, quedando sometida dicha actividad a las condiciones de uso y registro expresamente establecidas por estas plataformas online, sin que Facuven Productos Silvestres S.L asuma ninguna responsabilidad directa o indirecta por los contenidos publicados en ellas por los usuarios. <br />
                        En estos casos, el Usuario deberá tener en cuenta que al acceder a dichas redes, está saliendo de las páginas web de la tienda, para acceder a un entorno no controlado por Facuven Productos Silvestres S.L. En consecuencia, Facuven Productos Silvestres S.L no asumirá ninguna responsabilidad por las posibles vulneraciones a la seguridad de dichos entornos. <br />
                        Facuven Productos Silvestres S.L declina toda responsabilidad que se derive del intercambio de información entre Usuarios a través de las páginas Web. La responsabilidad de la difusión de datos y de las manifestaciones difundidas en sus páginas Web, corresponde en todo caso a quienes las realizan.
                    </p>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        9.	MODIFICACIONES DE LAS MENCIONES LEGALES
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        Facuven Productos Silvestres S.L se reserva el derecho a modificar las presentes Condiciones sin que sea requisito previo la aceptación expresa por parte del Usuario, entendiéndose tácitamente aceptadas por el mismo las modificaciones introducidas en el caso de que continúe accediendo a las Páginas Web a partir de la fecha de publicación de las referidas modificaciones. En caso contrario, el Usuario deberá cesar de forma inmediata en el acceso y/o utilización de la Web. <br />
                        El presente contrato ha sido redactado originalmente en español y el usuario acepta que la versión española del presente documento prevalecerá sobre cualquier versión del mismo en otro idioma diferente al español. <br />
                    </p>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        10.	NULIDAD DE LAS CLÁUSULAS
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        Si cualquiera cláusula incluida en estas condiciones fuese declarada total o parcialmente nula o ineficaz, tal nulidad afectará tan sólo a dicha disposición o la parte de la misma que resulte nula o ineficaz, subsistiendo en todo lo demás las condiciones.
                    </p>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        11.	ACCESO Y NAVEGACIÓN
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        El acceso y navegación en este web site supone aceptar y conocer las advertencias legales, condiciones y términos de uso contenidas en ella. Facuven Productos Silvestres S.L realiza los máximos esfuerzos para que la navegación se realice en las mejores condiciones y evitar los perjuicios de cualquier tipo que pudiesen ocasionarse durante la misma. <br/>
                        Este web site ha sido diseñado para soportar los navegadores más utilizados (explorer, chrome, firefox, safari, opera). <br/>
                        Facuven Productos Silvestres S.L  no se hace responsable de los perjuicios, de cualquier índole, que pudieran ocasionarse a los usuarios por la utilización de otros navegadores o versiones distintas de los navegadores para los que ha sido diseñada la página web. <br/>
                    </p>
                    <div className="white-space-16"></div>
                    <h4 className="color-black">
                        12.	LEY APLICABLE Y JURISDICCIÓN
                    </h4>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        Las Condiciones Generales de Contratación aquí expuestas están sujetas a la legislación española. En caso de litigio sobre la interpretación, ejecución o validez de estas condiciones generales de venta, las partes contratantes se someten a los Juzgados y Tribunales de Barcelona con expresa renuncia de cualquier otro fuero que pudiera corresponderles, asumiendo la parte que incumpla el contrato los gastos judiciales y extrajudiciales que se deriven de la reclamación incluido los gastos de abogados, procuradores, etc.
                    </p>
                    <div className="white-space-32"></div>
                    <h3 className="color-black">
                        POLÍTICA DE COOKIES
                    </h3>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        En cuanto a la política de cookies de la web, en caso de que la página web disponga de ellas y estas no sean inherentes al funcionamiento de la WEB o como así refiere el mencionado apartado 2 del artículo 22 LSSI “al solo fin de efectuar la transmisión de una comunicación por una red de comunicaciones electrónica”: Una cookie es un archivo de información que el servidor de este sitio web envía al dispositivo(ordenador, smartphone, tablet, etc.) de quien accede a la página para almacenar y recuperar información sobre la navegación que se efectúa desde dicho equipo.Facuven productos Silvestres S.L. utiliza diversos tipos de cookies (técnicas, analíticas y sociales)únicamente con la finalidad de mejorar la navegación del usuario en el sitio web, sin ningún tipo de objeto publicitario o similar.Facuven productos silvestres S.L., a través de las cookies no recoge dato personal alguno. Todas las cookies, salvo las de terceros desaparecen al concluir la sesión. Facuven Productos silvestres S.L. utiliza en este sitio web las cookies que se detallan a continuación:
                    </p>
                    <div className="white-space-32"></div>
                    <table className="table-responsive" style={tableStyle}>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Tipo</th>
                                <th>Caducidad</th>
                                <th>Finalidad</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>SL_wptGlobTipTmp / SL_GWPT_Show_Hide_tmp</td>
                                <td>técnicas</td>
                                <td>Al cerrar el navegador</td>
                                <td className="text-mini text-justify">(Utilizamos estas cookies para hacer que el uso del sitio sea más cómodo. Algunos usos comunes de las cookies incluyen la capacidad de guardar preferencias de navegación relacionadas con el idioma de uso del sitio o la capacidad de compartir en las redes sociales enlaces y contenido en el sitio.) </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        El usuario podrá, en cualquier momento, elegir qué cookies quiere que funcionen en este sitio web mediante: <br/>
                        a) La configuración del navegador <br/>
                        b) Herramientas de terceros, disponibles online, que permiten a los usuarios detectar las cookies en cada sitio web que visita y gestionar su desactivación. Ni esta web, ni sus representantes legales se hacen responsables ni del contenido ni de la veracidad de las políticas de privacidad que puedan tener los terceros mencionados en esta política de cookies. La aceptación de la presente política implica que el usuario ha sido informado de una forma clara y completa sobre el uso de dispositivos de almacenamiento y recuperación de datos (cookies) así como que Facuven Productos Silvestres S.L. dispone del consentimiento del usuario para el uso de las mismas tal y como establece el artículo 22 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico (LSSI‐CE).
                    </p>
                    <p className="text-justify">
                        Además de las cookies antes mencionadas, otras cookies de origen o de terceros podrían ser utilizadas en contenido (externo) añadido en este sitio web, utilizando tecnologías como iFrames, JavaScript, etc. Al aceptar cookies, también aceptas que dicho contenido puede utilizar cookies. <br/>
                        Facuven Productos Silvestres S.L no tiene control ni acepta la responsabilidad por las cookies de terceros. Por favor revisa el sitio web del tercero para obtener más información.
                    </p>
    
                    <div className="white-space-32"></div>
                </div>
            </div>
        );
    }
}

export default Legalidad;