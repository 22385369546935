import React from 'react';

const NotFoundPage = function({ location }){

    return(
        <div className="justify-center">
            <div className="container">
                <div className="not-found-page column justify-center align-center">
                    <h2>
                        404
                    </h2>
                    <div className="white-space-32"></div>
                    <h3 className="color-black uppercase text-center">
                        La página "{ location.pathname }" que solicitas no existe
                    </h3>
                    <div className="white-space-8"></div>
                    <p className="text-center text-mini">
                        No es culpa de sistemas
                    </p>
                    <div className="white-space-32"></div>
                </div>
            </div>
        </div>
    );

}

export default NotFoundPage;