import store from './store';
import * as types from './constants';
import { URL_API } from '../api/urls';

/* ==========================================================================
    LOCALE
========================================================================== */

export function changeLocale(locale) {
	return {
		type: types.CHANGE_LOCALE,
		locale
	}
}
/* ==========================================================================
    CLIENTS
========================================================================== */
export function client_login( data ) {
	return {
		type: types.LOGIN,
		data,
		url: URL_API + 'clientes/login'
	}
};

export function logOut() {
	return {
		type: types.LOGOUT
	}
};

export function client_register( data, response ) {
	return {
		type: types.CLIENT_REGISTER,
		data,
		url: URL_API + 'clientes/registro',
		response
	}
};

/* ==========================================================================
    PRODUCTS
========================================================================== */

export function getProducts( line, locale ) {	
	return {
		type: types.GET_PRODUCTS,
		data: { idLinea: line, idioma: locale },
		url: URL_API + "productos/todos",
	}	
};

export function filterProducts(category) {
	
	let products = store.getState().products_filter;
	//console.log(products);

	products = products.filter(product => (
		product.idCategoria === category.idCategoria
	));
	return {
		type: types.GET_PRODUCTS_SUCCESS,
		products: products,
		products_filter: store.getState().products_filter,
		idCategoria: category.idCategoria
	}
}

export function getRelevants( locale ) {
	return {
		type: types.GET_RELEVANTS,
		url: URL_API + 'productos/relevantes',
		locale
	}
}

export function getProductsByQuery( query, idioma ) {

	return {
		type: types.GET_PRODUCTS_SEARCH,
		data: { query: query },
		url: URL_API + 'productos/buscar',
		idioma: idioma
	}

};

export function getCategories(idLinea, idioma) {
	return {
		type: types.GET_CATEGORIES,		
		url: URL_API + 'categorias',
		data: {idLinea: idLinea, idioma, idioma}
	}
};

export function getLines() {
	return {
		type: types.GET_LINES,
		url: URL_API + 'lineas'
	}
};


/* ==========================================================================
    CART
========================================================================== */
export function addCart(product) {
	const carrito = store.getState().carrito;
	const exist = carrito.find(item => {
		return (
			item.idProducto == product.idProducto
		)
	})

	if (exist) {
		exist.cantidad = exist.cantidad + 1;
	} else {
		Object.assign(product, { cantidad: 1 })
		carrito.push(product);
	}
	let subtotal = 0;
	let pesos = 0;
	//console.log(product);
	carrito.map(product => {
		// Calcula el subtotal del carrito
		subtotal = subtotal + (product.precio_promo ? product.precio_promo * product.cantidad : product.precio * product.cantidad);
		pesos = pesos + (product.peso * product.cantidad);
		// Asigna el importe
		Object.assign(product, { importe: product.precio_promo ? product.precio_promo * product.cantidad : product.precio * product.cantidad })
	})
	return {
		type: types.ADD_CART,
		carrito: carrito,
		product: product,
		subtotal: subtotal,
		pesos: pesos
	}
};

export function updateCart(cart, product) {
	let subtotal = 0;
	let pesos = 0;
	cart.map(product => {
		// Calcula el subtotal del carrito
		subtotal = subtotal + (product.precio_promo ? product.precio_promo * product.cantidad : product.precio * product.cantidad);
		pesos = pesos + (product.peso * product.cantidad);
		// Asigna el importe
		Object.assign(product, { importe: product.precio_promo ? product.precio_promo * product.cantidad : product.precio * product.cantidad})
	})
	return {
		type: types.ADD_CART,
		carrito: cart,
		product: product,
		subtotal: subtotal,
		pesos: pesos
	}
};

export function removeProduct(id_producto) {
	let carrito = store.getState().carrito;
	carrito = carrito.filter(item => {
		return item.idProducto !== id_producto
	})
	let product = carrito[carrito.length - 1]
	if (!product) {
		product = { id_producto: 0 }
	}
	let subtotal = 0;
	let pesos = 0;
	carrito.map(product => {
		// Calcula el subtotal del carrito
		subtotal = subtotal + (product.precio_promo ? product.precio_promo * product.cantidad : product.precio * product.cantidad);
		pesos = pesos + (product.peso * product.cantidad);
	})
	return {
		type: types.REMOVE_CART,
		carrito: carrito,
		product: product,
		subtotal: subtotal,
		pesos: pesos
	}
};

export function addPedido(data, response) {
	return {
		url: URL_API + 'pedidos/registrar',
		type: types.ADD_PEDIDO,
		data,
		response
	}
}

export function getShipping(pesos, subtotal) {
	return {
		url: URL_API + 'pedidos/shipping',
		type: types.GET_SHIPPING_PRICE,
		pesos,
		subtotal
	}
}

export function requestPay(data, response) {
	return {
		url: process.env.NODE_ENV !== 'production'?'http://localhost:8000/apipay.php': "https://elfosgourmet.eu/apipay/apipay.php",
		type: types.OBTENER_DATOSPAY,
		data,
		response
	}
}

/* ==========================================================================
    ORDERS
========================================================================== */

export function getOrders(data) {
	return {
		type: types.OBTENER_PEDIDOS,
		url: URL_API + 'pedidos/obtener',
		data
	}
}

export function getOrderDetail(data, response) {
	return {
	  type: types.OBTENER_PEDIDO_DETAIL,
	  url: URL_API + "admin/pedidos/detail",
	  data,
	  response
	};
  }

/* ==========================================================================
  BANNER
========================================================================== */

export function getProductBanner() {
	return {
		type: types.GET_PRODUCT_BANNER,
		url: URL_API + "productos/banner",
	}
};