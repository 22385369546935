import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Images from '../../img/index';

const CTACatalog = function(props) {
    return(
        <div className="promotion-banners justify-center">
            <div className="container">
                <div className="column align-center">
                    <div className="row-responsive gutters">
                        {/*<div className="promotion-banner column justify-center" style={{ backgroundImage: `url(${Images['ctac1']})` }}>
                            <img src={Images['elfos-gourmet-logo']} alt="Logo Elfos Gourmet" />
                            <div className="white-space-8"></div>
                            <div className="justify-center">
                                <Link className="btn btn-brown" to="comprar" onClick={props.comprar.bind(this, 1)}>
                                    <FormattedMessage id="cta-explorar" defaultMessage="COMPRAR" />
                                </Link>
                            </div>
                            <div className="white-space-8"></div>
                            <p className="text-center color-white weight-bold">
                                Delicatessen
                            </p>
                        </div> */}

                        {/*<div className="promotion-banner column justify-center" style={{ backgroundImage: `url(${Images['lotes']})` }}>
                            <img src={Images['elfos-gourmet-logo']} alt="Logo Elfos Gourmet" />
                            <div className="white-space-8"></div>
                            <div className="justify-center">
                                <Link className="btn btn-brown" to="comprar" onClick={props.comprar.bind(this, 4)}>
                                    <FormattedMessage id="cta-explorar" defaultMessage="COMPRAR" />
                                </Link>
                            </div>
                            <div className="white-space-8"></div>
                            <p className="text-center color-white weight-bold">
                                Estuches y lotes
                            </p>
                    </div>*/}
                    </div>
                    
                    {/*<div className="row-responsive gutters">
                        <div className="promotion-banner column justify-center" style={{ backgroundImage: `url(${Images['ctac2']})` }}>
                            <img src={Images['Logo-Setal-White']} alt="Setal Logo" />
                            <div className="white-space-8"></div>
                            <div className="justify-center">
                                <Link className="btn btn-brown" to="comprar" onClick={props.comprar.bind(this, 3)}>
                                    <FormattedMessage id="cta-explorar" defaultMessage="COMPRAR" />
                                </Link>
                            </div>
                            <div className="white-space-8"></div>
                            <p className="text-center color-white weight-bold">
                                Horeca-comercio
                            </p>
                        </div>

                        <div className="promotion-banner column justify-center" style={{ backgroundImage: `url(${Images['ctac3']})` }}>
                            <img src={Images['Logo-Setal-White']} alt="Setal Logo" />
                            <div className="white-space-8"></div>
                            <div className="justify-center">
                                <Link className="btn btn-brown" to="comprar" onClick={props.comprar.bind(this, 2)}>
                                    <FormattedMessage id="cta-explorar" defaultMessage="COMPRAR" />
                                </Link>
                            </div>
                            <div className="white-space-8"></div>
                            <p className="text-center color-white weight-bold">
                                Industria
                            </p>
                        </div>
                    </div>*/}
                </div>
            </div>
        </div>
    );

}

export default CTACatalog;