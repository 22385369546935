import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import jsonxml from "jsontoxml";
import { injectIntl, FormattedMessage, FormattedNumber } from "react-intl";
import { URL_API } from "../api/urls";

// Web Components
import * as action from "../redux/actions";
import PageBanner from "../components/banner/page-banner";
import { debug } from "util";

import moment from 'moment-timezone';
import { Request } from '../api/apiservice';

const req = new Request();

class CheckOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
		user: this.props.user,
		envio: 0,
		promocion: '',
		descuento: false,
		descuento_porcentaje: 0,
		descuento_cantidad: 0,
		total: this.props.subtotal + this.props.shipping,
		messageCode: '',
		messageCodeType: ''
    };
  }

  /*componentDidMount() {
    let envio = window.localStorage.getItem('shipping');
    if (envio) {
      envio = JSON.parse(envio);
      this.setState({ envio: envio });
    }
  }*/

	toCheckOut(event) {
		event.preventDefault();
		if (this.state.termsCond) {
			const carrito = this.props.carrito.map(item => ({
				item: {
				idProducto: item.idProducto,
				cantidad: item.cantidad,
				precio: item.precio,
				precio_promo: item.precio_promo,
				importe: item.importe,
				peso: item.peso
				}
			}));      
			let importe = 0;
			importe = carrito.reduce((a, b) => a + b.item.importe, 0);
			importe = importe + this.props.shipping /*this.state.envio*/;

			let data = {
				carrito: jsonxml(carrito),
				importe: this.state.total,
				id_cliente: this.props.user.id_cliente,
				direccion: document.getElementById("fullAddress").value,
				numInterior: document.getElementById("internalNumber").value,
				numExterior: document.getElementById("externalNumber").value,
				ciudad: document.getElementById("city").value,
				estado: document.getElementById("state").value,
				zip: document.getElementById("zip").value,
				pais: document.getElementById("country-list").value,
				dni: this.state.facturar ? document.getElementById("dni").value : '',
				facturar: this.state.facturar? 1:0,
				peso: this.props.pesos,
				envio: this.props.shipping /*this.state.envio*/,
				promocion: this.state.promocion,
				descuento: this.state.descuento_porcentaje
			};
			//console.log(data);
			this.props.dispatch(action.addPedido(data, this.savePedido.bind(this)));
		} else {
			const modal = document.getElementById("checkout_message_modal");
			if(modal) {
				modal.classList.remove("hidden");
			}
		}
	}

	savePedido(res) {
		//console.log("SAVEPEDIDO", res);
		if (res.error) {
		this.setState({ error: res.message });
		} else {
		const paydata = {
			amount: (res.importe).toFixed(2) * 100,
			order: res.folio,
			url: URL_API + "pago",
			urlok:
			process.env.NODE_ENV !== "production"
				? "http://localhost:3000/checkout/success"
				: "https://www.elfosgourmet.eu/checkout/success",
			urlko:
			process.env.NODE_ENV !== "production"
				? "http://localhost:3000/checkout/fail"
				: "https://www.elfosgourmet.eu/checkout/fail",
			aditionalInfo: {
			customerId: this.props.user.id_cliente,
			customerEmail: this.props.user.email,
			locale: this.props.intl.locale
			},
			language: this.props.intl.locale === "en" ? 2 : 1
		};

		this.props.dispatch(
			action.requestPay(paydata, this.responsePay.bind(this))
		);
		}
		//debugger;
	}

	responsePay(res) {
		/*console.log("RESPONSE PAY", res.body);
		debugger;*/
		const form = document.getElementById("payRequestForm");
		form.Ds_MerchantParameters.value = res.body.parametros;
		form.Ds_Signature.value = res.body.llave;
		form.submit();
	}

	async verifyPromotionCode() {
		this.setState({ messageCode: '' });
		const code = document.getElementById('code').value;
		const codigo = this.state.promocion;
		if (code !== '' && code !== codigo) {
			this.setState({ loading_code: true });

			const totalOriginal = this.props.subtotal + this.props.shipping;

			const data = {
				codigo: code,
				fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
			};
			const res = await req.post('promociones/get', data);

			if (res.promocion) {
				const discount = this.props.subtotal * (res.promocion.descuento / 100);
				this.setState({ 
					messageCode: 'Promoción aplicada correctamente',
					messageCodeType: 'discount_done',
					promocion: data.codigo,
					descuento: true,
					descuento_cantidad: discount,
					descuento_porcentaje: res.promocion.descuento,
					total: totalOriginal - discount });
			} 

			if (res.error) {
				this.setState({ 
					messageCode: 'Código inválido',
					messageCodeType: 'invalid_code',
					total: totalOriginal,
					promocion: '',
					descuento: false,
					descuento_cantidad: 0,
					descuento_porcentaje: 0
				});
			}
			this.setState({ loading_code: false });
		}
	}

	quitarDescuento() {
		const totalOriginal = this.props.subtotal + this.props.shipping;

		document.getElementById('code').value = '';

		this.setState({
			messageCode: '',
			total: totalOriginal,
			promocion: '',
			descuento: false,
			descuento_cantidad: 0,
			descuento_porcentaje: 0
		});
	}

  renderModal() {
    return (
      <div className="flex modal hidden" id="checkout_message_modal">
        <div
          className="flex modal-content justify-center column"
          style={{ maxWidth: "300px" }}
        >
          <div className="white-space-32" />
          <label className="text-center">
            <FormattedMessage
              id="checkout_politics_error"
              defaultMessage="ERROR"
            />
          </label>
          <div className="white-space-32" />
          <button
            className="btn btn-red text-center"
            onClick={() =>
              document
                .getElementById("checkout_message_modal")
                .classList.add("hidden")
            }
          >
            <FormattedMessage
              id="carrito-render-alert-aceptar"
              defaultMessage="ACEPTAR"
            />
          </button>
        </div>
      </div>
    );
  }

  render() {
    /*let envio = 0;
    let total = this.props.subtotal;
    if (total < 100) {
      envio = this.props.shipping;
      total = total + envio;
    }*/
    //const total = this.props.subtotal + this.props.shipping /*this.state.envio*/;
    return (
      <div className="column">
        <Helmet>
          <title>Elfos Gourmet - Checkout</title>
        </Helmet>
        <PageBanner bannerImage="bannerpagecart" bannerTitle="checkout" />
        <form
          className="checkout row-responsive"
          onSubmit={this.toCheckOut.bind(this)}
        >
          <div className="checkout-address justify-center">
            <div className="container column">
              	<div className="white-space-64" />
				<h2 className="color-black">
					<FormattedMessage
						id="registro-envio"
						defaultMessage="¿DÓNDE ENVIAREMOS TUS COMPRAS?"
					/>
				</h2>
              	<div className="white-space-16" />
				<p>
					<FormattedMessage
						id="checkout-puedes-cambiar"
						defaultMessage="Puedes cambiar tu dirección de envío"
					/>
				</p>
				<div className="white-space-16" />
				<div className="row align-center justify-start">
					<label htmlFor="facturar" className="pointer color-black">
						<FormattedMessage
							id="checkout-facturar"
							defaultMessage="REQUIERE FACTURA"
						/>
					</label>
					&nbsp;
					<input id="facturar" type="checkbox" className="pointer" checked={this.state.facturar} onChange={event=> this.setState({facturar: event.target.checked})}/>
				</div>
				{this.state.facturar &&
					<React.Fragment>
						<div className="white-space-16" />
						<div className="column">
							<label htmlFor="dni" className="color-black">
								<FormattedMessage
									id="registro-dni"
									defaultMessage="DNI / NIF"
								/>
							</label>
							<div className="white-space-8" />
							<input type="text" name="dni" id="dni" className="input input-text" value={this.state.user.dni} onChange={event => {
								const user = this.state.user;
								Object.assign(user, { dni: event.target.value });
								this.setState({ user: user });
							}} />
							<p>
								<FormattedMessage
									id="obligatorio-factura"
									defaultMessage="*Obligatorio para emitir factura"
								/>
							</p>
						</div>
					</React.Fragment>
				}
              	<div className="white-space-8" />
				<div className="checkout-address column">
					<div className="white-space-16" />
					<label htmlFor="fullAddress" className="color-black">
					<FormattedMessage
						id="registro-direccion"
						defaultMessage="Dirección *"
					/>
					</label>
					<div className="white-space-8" />
					<input
					type="text"
					name="fullAddress"
					id="fullAddress"
					className="input input-text"
					required
					value={this.state.user.direccion}
					onChange={event => {
						const user = this.state.user;
						Object.assign(user, { direccion: event.target.value });
						this.setState({ user: user });
					}}
					/>
					<div className="white-space-16" />
					<label htmlFor="internalNumber" className="color-black">
					<FormattedMessage
						id="registro-numInterior"
						defaultMessage="Número Interior"
					/>
					</label>
					<div className="white-space-8" />
					<input
					type="text"
					name="internalNumber"
					id="internalNumber"
					className="input input-text"
					value={this.state.user.numInterior}
					onChange={event => {
						const user = this.state.user;
						Object.assign(user, { numInterior: event.target.value });
						this.setState({ user: user });
					}}
					/>
					<div className="white-space-16" />
					<label htmlFor="externalNumber" className="color-black">
					<FormattedMessage
						id="registro-numExterior"
						defaultMessage="Número Exterior *"
					/>
					</label>
					<div className="white-space-8" />
					<input
					type="text"
					name="externalNumber"
					id="externalNumber"
					className="input input-text"
					required
					value={this.state.user.numExterior}
					onChange={event => {
						const user = this.state.user;
						Object.assign(user, { numExterior: event.target.value });
						this.setState({ user: user });
					}}
					/>
					<div className="white-space-16" />
					<label htmlFor="city" className="color-black">
					<FormattedMessage
						id="registro-ciudad"
						defaultMessage="Ciudad *"
					/>
					</label>
					<div className="white-space-8" />
					<input
					type="text"
					name="city"
					id="city"
					className="input input-text"
					required
					value={this.state.user.ciudad}
					onChange={event => {
						const user = this.state.user;
						Object.assign(user, { ciudad: event.target.value });
						this.setState({ user: user });
					}}
					/>
					<div className="white-space-16" />
					<label htmlFor="state" className="color-black">
					<FormattedMessage
						id="registro-estado"
						defaultMessage="Estado / Provincia *"
					/>
					</label>
					<div className="white-space-8" />
					<input
					type="text"
					name="state"
					id="state"
					className="input input-text"
					required
					value={this.state.user.estado}
					onChange={event => {
						const user = this.state.user;
						Object.assign(user, { estado: event.target.value });
						this.setState({ user: user });
					}}
					/>
					<div className="white-space-16" />
					<label htmlFor="zip" className="color-black">
					<FormattedMessage
						id="registro-zip"
						defaultMessage="Código Postal *"
					/>
					</label>
					<div className="white-space-8" />
					<input
					type="text"
					name="zip"
					id="zip"
					className="input input-text"
					required
					value={this.state.user.zip}
					onChange={event => {
						const user = this.state.user;
						Object.assign(user, { zip: event.target.value });
						this.setState({ user: user });
					}}
					/>
					<div className="white-space-16" />
					<label htmlFor="country" className="color-black">
					<FormattedMessage
						id="registro-country"
						defaultMessage="País *"
					/>
					</label>
					<div className="white-space-8" />
					<select name="county" id="country-list" className="input input-select" required value={this.state.user.pais}
						onChange={event => {
							const user = this.state.user;
							Object.assign(user, { pais: event.target.value });
							this.setState({ user: user });
						}}>
						<option value="ESP">España</option>
					</select>
					<div className="white-space-16" />
				</div>
              <div className="white-space-64" />
            </div>
          </div>

          <div className="checkout-order">
            <div className="column">
              	<div className="table-responsive">
					<table>
					<thead>
						<tr>
						<th className="th-subtotal"> SUB-TOTAL </th>
						{this.state.descuento ? <th>
							<FormattedMessage
								id="discount" defaultMessage="DESCUENTO"
							/>
						</th> : null}
						<th className="th-tax">
							{" "}
							<FormattedMessage
							id="envio"
							defaultMessage="ENVIO"
							/>{" "}
						</th>
						<th className="th-total"> TOTAL </th>
						</tr>
					</thead>
					<tbody>
						<tr className="table-row">
						<td>
							<FormattedNumber
							currency="EUR"
							style="currency"
							value={this.props.subtotal}
							/>
						</td>
						{this.state.descuento ?
							<td>
								<FormattedNumber currency="EUR" style="currency" value={this.state.descuento_cantidad} />
								&nbsp; <i class="fas fa-times-circle color-red" onClick={this.quitarDescuento.bind(this)}></i>
							</td> : null}
						<td>
							{" "}
							<FormattedNumber currency="EUR" style="currency" value={this.props.shipping /*this.state.envio*/} />
							{" "}
						</td>
						<td className="table-total total-check">
							<FormattedNumber
							currency="EUR"
							style="currency"
							value={this.state.total}
							/>
						</td>
						</tr>
					</tbody>
					</table>
              	</div>
              	<div className="white-space-24" />
				<div className="promotion-code justify-center align-center">
					<label className="color-black">
						<FormattedMessage
							id="code-promotion" defaultMessage="¿Tienes un código de promoción?"
						/>
					</label>
				</div>
				<div className="white-space-16"></div>
				<div className="row justify-center">
					<input className="input input-text" type="text" name="code" id="code" />&nbsp;
					<button className="btn-code btn-brown" type="button" onClick={this.verifyPromotionCode.bind(this)}>
						{this.state.loading_code ? <i className="fas fa-spinner fa-spin" /> : <FormattedMessage
							id="apply" defaultMessage="APLICAR"
						/>}
					</button>
				</div>
				{this.state.messageCode !== '' ?
				<React.Fragment>
					<div className="white-space-16"></div>
					<div className="justify-center">
						<p>
							<FormattedMessage
								id={this.state.messageCodeType} defaultMessage={this.state.messageCode}
							/>
						</p>
					</div>
				</React.Fragment> : null}
				<div className="white-space-24" />
              	<div className="justify-center align-center">
                <input type="checkbox" id="checkout_privacy_acceptd" className="input-checkbox" checked={this.state.termsCond}
                  onChange={event => {
                    this.setState({ termsCond: event.target.checked });
                  }} /> &nbsp; 
                <label htmlFor="checkout_privacy_acceptd">
                  <FormattedMessage id="checkout_politics_accept" defaultMessage="Acepto los" />
                </label>
                &nbsp;
                <a href="/legalidad" target="_newtab">
                  <FormattedMessage id="checkout_politics" defaultMessage="políticas de privacidad" />
                </a>
              </div>
              <div className="white-space-24" />
              <div className="btn-container-pay">
                <button className="btn btn-aqua" type="submit">
                  <i className="fas fa-credit-card" />
                  &nbsp;
                  <FormattedMessage
                    id="checkout-proceder"
                    defaultMessage="PROCEDER AL PAGO"
                  />
                  {this.props.add_loading ? (
                    <i className="fas fa-spinner fa-spin" />
                  ) : null}
                </button>
              </div>
              <div>{this.state.error}</div>
              <div className="white-space-16" />
              <a href="carrito" className="link text-mini">
                <FormattedMessage
                  id="checkout-cancelar-pedido"
                  defaultMessage="CANCELAR PEDIDO"
                />
              </a>
            </div>
          </div>
        </form>
        <form id="payRequestForm" method="post" action={process.env.NODE_ENV === "production"? "https://sis.redsys.es/sis/realizarPago" : "https://sis-t.redsys.es:25443/sis/realizarPago"}>
			<input type="hidden" name="Ds_SignatureVersion" value="HMAC_SHA256_V1"/>
			<input type="hidden" name="Ds_MerchantParameters" id="Ds_MerchantParameters"/>
			<input type="hidden" name="Ds_Signature" id="Ds_Signature" />
        </form>
        {this.renderModal()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.user,
    subtotal: state.subtotal,
    shipping: state.shipping,
    carrito: state.carrito,
    pesos: state.pesos,
    add_loading: state.add_pedido_loading
  };
}

export default injectIntl(connect(mapStateToProps)(CheckOut));
