import React, { Component } from "react";
import Request from '../../core/httpClient';

// Web Components
import ProductsTable from "./products-table";
import ClientTable from "./client-table";
import StatusTable from "./status-table";
import * as action from "../../redux/actions";

const req = new Request();

class OrderDetails extends Component {
  	constructor(props) {
		super(props);
		this.state = {
		loading: false,
		products: [],
		accion: "",
		estado: this.props.order.estado
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.order !== this.props.order) {
			this.response();
		}
	}

	async response() {
		this.setState({ loading: true });
		const data = {
			id_pedido: this.props.order.idPedidos
		};
		const res = await req.get('/admin/pedidos/detail', data);
		if(Array.isArray(res.data)) {
			this.setState({products: res.data});
		} else {
			this.setState({message: res.message, products: []});
		}
		this.setState({ loading: false });
	}

  actualizar(event) {
    event.preventDefault();
    const form = document.getElementById("product-detail-form");
    const modal = document.getElementById("detail-question-modal");
    const text = document.getElementById("question-modal-text");
    text.innerHTML = "¿ Actualizar Pedido ?";
    // console.log(form.status.value);

    modal.classList.remove("hidden");
    this.setState({ accion: "ACTUALIZAR" });
  }

  cancelar(event) {
    event.preventDefault();
    document.getElementById("modal-order").classList.add("hidden")
  }

  estadoSelect(event) {
    this.setState({ estado: event });
  }

  actionResponse(message) {
    this.setState({ message: message });
    const modal = document.getElementById("detail-message-modal");
    modal.classList.remove("hidden");
  }

  render() {
    return (
      <div className="flex modal justify-center hidden" id="modal-order">
        <div className="container modal-content column">
          <div className="modal-header justify-center">
            <h2 className="color-white">DETALLE DE PEDIDO</h2>            
          </div>
          {this.state.loading ? (
            <div className="modal-body column">
              <div className="white-space-32" />
              <div className="justify-center">
                <i className="fas fa-spinner fa-spin" />
              </div>
              <div className="white-space-32" />
            </div>
          ) : this.state.error ? (
            <div className="modal-body column">
              <div className="white-space-32" />
              <div className="justify-center">{this.state.error}</div>
              <div className="white-space-32" />
            </div>
          ) : (
            <form className="modal-body column" id="product-detail-form">
              <div className="white-space-8" />
              <ProductsTable products={this.state.products} />
              <div className="white-space-32" />
              <ClientTable order={this.props.order} />
              <div className="white-space-32" />
              <StatusTable
                order={this.props.order}
                products={this.state.products}
                estadoSelect={this.estadoSelect.bind(this)}
              />
              <div className="white-space-16"></div>
              <div className="btn-container-order-acc-can">
                <div className="btn-container-cancel">
                  <button
                    className="btn btn-red"
                    onClick={this.cancelar.bind(this)}
                  >
                    <i className="fas fa-ban" />
                    &nbsp; CERRAR
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default OrderDetails;