import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../redux/actions";
import { withRouter } from "react-router-dom";
import { injectIntl, FormattedMessage } from "react-intl";

class ProductsCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: this.props.categories
    };
  }

  componentDidMount() {
    if (this.props.selected) {
      this.props.dispatch(
        action.filterProducts({ idCategoria: this.props.selected })
      );
    }
  }

  handleClick(category, event) {
    event.preventDefault();
    this.props.dispatch(
      action.filterProducts(category)
    );
    if (this.props.history !== "./comprar") {
      this.props.history.push("./comprar");
    }
  }

  render() {
    let selected = 0;
    if (this.props.selected !== 0) {
      //console.log(this.props.selected);
      selected = this.props.selected;
    }
    let categories = [];
    if (this.props.categories) {
      //console.log(this.props.categories);
      categories = this.props.categories;
    }
    return (
      <div className="column">
        <div className="white-space-32" />
        <h3 className="color-black">
          <FormattedMessage id="comprar-categorias" defaultMessage="CATEGORIAS" />
        </h3>
        <div className="white-space-16" />
        <div className="categories column">
          {categories.map((category, index) => {
            return (
              <a
                href="#"
                className={
                  "category link uppercase " +
                  (selected === category.idCategoria ? "category-selected" : "")
                }
                key={index}
                onClick={this.handleClick.bind(this, category)}
              >
                {category.descripcion}
              </a>
            );
          })}
        </div>
        <div className="white-space-32" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: state.categories,
	  selected: state.category_selected
  };
}

export default withRouter(
  injectIntl(connect(mapStateToProps)(ProductsCategories))
);

