import React, { Component } from 'react';

class PopOver extends Component {
    
    render() {        

        return (
            <div className="flex popover justify-center hidden" id="popover">
                <div className="container justify-center weight-bold">
                    <i className={this.props.icon}></i>&nbsp; {this.props.text}
                </div>
            </div>
        );
    } 
}

export default PopOver;