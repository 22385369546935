import React from 'react';
import { FormattedMessage } from 'react-intl';

// Images
import Images from '../../img/index';

const UsVideo = function () {
    return (
        <div className="us-videos justify-center align-center">
            <div className="container column">
                <div className="white-space-64"></div>
                <h2 className="color-black text-center">
                    <FormattedMessage id="us-videos" defaultMessage="NUESTRO PROCESO DE PRODUCCIÓN" />
                </h2>
                <div className="white-space-16"></div>
                <div className="justify-center">
                    <img src={Images['divider']} alt="Divisor" title="Divisor" />
                </div>
                <div className="white-space-32"></div>
                <div className="row-responsive justify-center align-center">
                    <div className="usvideo">
                        <iframe width="auto" height="auto" src="https://www.youtube.com/embed/1UkEZyJSdpU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div className="usvideo">
                        <iframe width="auto" height="auto" src="https://www.youtube.com/embed/nPQ_Sp3WXeU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div className="usvideo">
                        <iframe width="auto" height="auto" src="https://www.youtube.com/embed/m5A5Q-WCL94" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div className="usvideo">
                        <iframe width="auto" height="auto" src="https://www.youtube.com/embed/Mj9j_PCv1nM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div className="white-space-64"></div>
            </div>
        </div>
    )
}

export default UsVideo;