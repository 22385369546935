/* ==========================================================================
    LOCALE
========================================================================== */

export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* ==========================================================================
    CLIENTS
========================================================================== */

export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';

export const CLIENT_REGISTER = 'CLIENT_REGISTER';
export const CLIENT_REGISTER_ERROR = 'CLIENT_REGISTER_ERROR';
export const CLIENT_REGISTER_SUCCESS = 'CLIENT_REGISTER_SUCCESS';

/* ==========================================================================
    PRODUCTS
========================================================================== */

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';

export const GET_RELEVANTS = 'GET_RELEVANTS';
export const GET_RELEVANTS_ERROR = 'GET_RELEVANTS_ERROR';
export const GET_RELEVANTS_SUCCESS = 'GET_RELEVANTS_SUCCESS';

export const GET_PRODUCTS_SEARCH = 'GET_PRODUCTS_SEARCH';
export const GET_PRODUCTS_SEARCH_ERROR = 'GET_PRODUCTS_SEARCH_ERROR';
export const GET_PRODUCTS_SEARCH_SUCCESS = 'GET_PRODUCTS_SEARCH_SUCCESS';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';

export const GET_LINES = 'GET_LINES';
export const GET_LINES_ERROR = 'GET_LINES_ERROR';
export const GET_LINES_SUCCESS = 'GET_LINES_SUCCESS';

export const SET_LINE = 'SET_LINE';
export const SET_CATEGORY = 'SET_CATEGORY';

/* ==========================================================================
    CART
========================================================================== */

export const ADD_CART = 'ADD_CART';
export const ADD_CART_ITEM = 'ADD_CART_ITEM';
export const REMOVE_CART = 'REMOVE_CART';
export const REMOVE_ALL_CART = 'REMOVE_ALL_CART';

export const ADD_PEDIDO = 'ADD_PEDIDO';
export const ADD_PEDIDO_SUCCESS = 'ADD_PEDIDO_SUCCESS';
export const ADD_PEDIDO_ERROR = 'ADD_PEDIDO_ERROR';

export const OBTENER_DATOSPAY = 'OBTENER_DATOSPAY';

export const COMPLETA_VENTA = 'COMPLETA_VENTA';

export const GET_SHIPPING_PRICE = 'GET_SHIPPING_PRICE';

/* ==========================================================================
    ORDERS
========================================================================== */

export const OBTENER_PEDIDOS = "OBTENER_PEDIDOS";
export const OBTENER_PEDIDOS_ERROR = "OBTENER_PEDIDOS_ERROR";
export const OBTENER_PEDIDOS_SUCCESS = "OBTENER_PEDIDOS_SUCCESS";

export const OBTENER_PEDIDO = "OBTENER_PEDIDO";
export const OBTENER_PEDIDO_ERROR = 'OBTENER_PEDIDO_ERROR';
export const OBTENER_PEDIDO_SUCCESS = 'OBTENER_PEDIDO_SUCCESS';

export const OBTENER_PEDIDO_DETAIL = "OBTENER_PEDIDO_DETAIL";
export const OBTENER_PEDIDO_DETAIL_ERROR = "OBTENER_PEDIDO_DETAIL_ERROR";
export const OBTENER_PEDIDO_DETAIL_SUCCESS = "OBTENER_PEDIDO_DETAIL_SUCCESS";

/* ==========================================================================
    BANNER
========================================================================== */

export const GET_PRODUCT_BANNER = "GET_PRODUCT_BANNER";
export const GET_PRODUCT_BANNER_ERROR = "GET_PRODUCT_BANNER_ERROR";
export const GET_PRODUCT_BANNER_SUCCESS = "GET_PRODUCT_BANNER_SUCCESS";