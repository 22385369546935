import React, { Component } from 'react';
import { connect } from "react-redux";
import * as action from "../../redux/actions";
import Image from '../../img/setalline.jpg';
import { FormattedMessage } from "react-intl";
import { Link } from 'react-router-dom';

class ModalBanner extends Component {
    componentDidMount() {
        this.props.dispatch(action.getProductBanner());
    }

    closeModal() {
        window.localStorage.setItem('banner', this.props.products_banner[0].idBProducto);

        document.getElementById("modal-banner").classList.add("hidden");
    }

	redirectBanner(linea, categoria) {
        window.localStorage.setItem('banner', this.props.products_banner[0].idBProducto);
		this.props.dispatch({type: 'REDIRECT_BANNER', data: { linea, categoria }});
		window.location = '/comprar';
	}

    render() {
        let product = [], descriptionArray = [], description;
        if (this.props.products_banner[0]) {
            product = this.props.products_banner[0];

            if (product.descripcion) {
                descriptionArray = JSON.parse(product.descripcion);
                description = descriptionArray[this.props.locale];
            }
        }
        return(
            <div className="flex modal justify-center hidden" id="modal-banner">
                <div className="container banner-content modal-content column">
                    <div className="modal-body-banner">
                        <div className="info-product column justify-center align-center" style={{ backgroundImage: `url(${product ? product.imagen : ''})`}}>
                            <div className="banner-b column container align-center justify-center">
                                <div className="text-banner justify-center">
                                    <h1 className="text-center" style={{color: `${product ? product.colorText : 'white'}`}}>
                                        {description ? description : ''}
                                    </h1>
                                </div>
                                <div className="justify-center">
                                    <Link className="btn btn-green btn-banner text-center" to="comprar" /*onClick={this.props.comprar.bind(this, product.linea)} onClick={this.props.comprar.bind(this, product.linea, product.categoria)}*/ onClick={this.redirectBanner.bind(this, product.linea, product.categoria)}>
                                        <FormattedMessage
                                            id="banner-ver"
                                            defaultMessage="VER"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="close-banner justify-center">
                            <a onClick={this.closeModal.bind(this)}><i className="fas fa-times"></i></a>
                            {/*<i class="fas fa-times-circle"></i>*/}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        products_banner: state.products_banner,
        locale: state.locale
    };
}

export default connect(mapStateToProps)(ModalBanner);