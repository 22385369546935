import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../redux/actions";
import { FormattedMessage } from "react-intl";
import { Link } from 'react-router-dom';

class ModalLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      login_error: ""
    };
    this.login = this.login.bind(this);
  }

  cipher(text) {
    const string64 = new Buffer(text).toString("base64");
    return string64;
  }

  login(event) {
    event.preventDefault();
    const username = event.target.username.value;
    const password = this.cipher(event.target.password.value);
    const usuario = { usuario: username, password: password };
    this.props.dispatch(action.client_login(usuario));
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.login_error !== nextProps.login_error) {
      this.setState({ login_error: nextProps.login_error });
    }
  }

  render() {
    return (
      <div className="flex modal justify-center hidden" id="modal-login">
        <div className="container modal-content column">
          <div className="modal-header justify-center">
            <h2 className="color-white">
              <FormattedMessage
                id="modal_login_header"
                defaultMessage="INICIAR SESIÓN"
              />
            </h2>
          </div>
          <div className="modal-body column">
            <div className="white-space-16" />
            <form
              className="modal-body-container column"
              onSubmit={this.login.bind(this)}
            >
              <div className="user-data column">
                <label htmlFor="user" className="color-black">
                  <FormattedMessage
                    id="modal_login_user"
                    defaultMessage="USUARIO"
                  />
                </label>
                <div className="white-space-8" />
                <input
                  type="text"
                  name="username"
                  className="input input-text"
                />
                <div className="white-space-16" />
                <label htmlFor="password" className="color-black">
                  <FormattedMessage
                    id="modal_login_password"
                    defaultMessage="CONTRASEÑA"
                  />
                </label>
                <div className="white-space-8" />
                <input
                  type="password"
                  name="password"
                  className="input input-text"
                  onChange={event =>
                    this.setState({ password: event.target.value })
                  }
                  value={this.state.password}
                />
                <div className="white-space-24" />
                <div className="color-red weight-medium text-center uppercase">
                  {this.state.login_error}
                </div>
                <div>
                  <Link to={'/restore'} onClick={() => document.getElementById('modal-login').classList.add('hidden')}> 
                    <FormattedMessage
                      id="recuperar"
                      defaultMessage="RECUPERAR CONTRASEÑA" />                    
                  </Link>
                </div>
                <div className="white-space-8" />
              </div>
              <div className="btn-container-login">
                <button type="submit" className="btn btn-aqua">
                  <i className="fas fa-sign-in-alt" />&nbsp;{" "}
                  <FormattedMessage
                    id="modal_login_acceder"
                    defaultMessage="ACCEDER"
                  />
                  &nbsp;
                  {this.props.login_loading ? (
                    <i className="fas fa-spinner fa-spin" />
                  ) : null}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.user,
    login_error: state.login_error,
    login_loading: state.login_loading
  };
}

export default connect(mapStateToProps)(ModalLogin);
