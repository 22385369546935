import React, { Component } from 'react';

class Paginator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            start: 1,
            end: 5
        }
    }
    setPage(page) {
        this.props.setpage(page);
        let startPage = 1;
        let endPage = this.props.items;
        if (page <= 4) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = 5;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (page + 1 >= this.props.items) {
                startPage = this.props.items - 4;
                endPage = this.props.items;
            } else {
                startPage = page - 2;
                endPage = page + 2;
            }
        }
        this.setState({start: startPage, end: endPage, page: page})
        window.scrollTo(0, 0);
    }

    render() {
        const numbers = [];
        let start = this.state.start;
        let end = this.state.end;
        if(end >= this.props.items) {
            end = this.props.items;
        }
        for(start; start <= end; start++) {
            numbers.push({index: start});
        }
        return(
                        
            <div className="pagination justify-center align-center">
                <div className="page auto" onClick={this.setPage.bind(this, 1)}>
                    <a className="page-link">
                        {'<<'}
                    </a>
                </div>
                {
                    numbers.map((number, index) => (
                        <div key={index} className={'page auto ' + (this.state.page === number.index? 'current-page': '')} onClick={this.setPage.bind(this, number.index)}>
                            <a className="page-link">
                                {number.index}
                            </a>
                        </div>
                    ))
                }
                <div className="page auto" onClick={this.setPage.bind(this, this.props.items)}>
                    <a className="page-link">
                        {'>>'}
                    </a>
                </div>
            </div>
        );

    }

}

export default Paginator;