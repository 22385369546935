import React, { Component } from "react";
import { connect } from "react-redux";
import request from "superagent";
import { FormattedMessage, injectIntl } from "react-intl";
import { URL_API } from "../api/urls";
import { Send } from "react-facebook";

export class RestorePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageID: "none",
      loading: false
    };
  }

  hanldeSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true, messageID: "none" });
    const form = event.target;
    const newpassword = form.password.value;
    const repassword = form.repassword.value;
    const data = {
      newpassword: this.cipher(newpassword),
      repassword: this.cipher(repassword),
      locale: this.props.intl.locale,
      token: this.props.match.params.id
    };
    request
      .post(URL_API + "clientes/restorepassword")
      .send(data)
      .then(res => {
        console.log(res.body);
        this.setState({ messageID: res.body.message, loading: false });
        if (res.body.message === "password_actualizada") {
          window.location = "/";
        }
      })
      .catch(err => {
        console.log(err.message);
        this.setState({ messageID: "internal_server_error", loading: false });
      });
  }

  cipher(text) {
    const string64 = new Buffer(text).toString("base64");
    return string64;
  }

  render() {
    return (
      <div className="content">
        <div className="white-space-32" />
        <div className="column">
          <form onSubmit={this.hanldeSubmit.bind(this)} className="column">
            <div className="white-space-32" />
            <FormattedMessage
              id="restore_proporcione_nueva_password"
              defaultMessage="Proporcione su nueva contraseña"
            />
            <div className="white-space-32" />
            <input
              type="password"
              className="input-text"
              name="password"
              required
            />
            <div className="white-space-32" />
            <input
              type="password"
              className="input-text"
              name="repassword"
              required
            />
            <div className="white-space-32" />
            <button type="submit" className="align-center btn btn-green">
              <FormattedMessage id="enviar" defaultMessage="ENVIAR" />
              &nbsp;
              {this.state.loading ? (
                <i className="fas fa-spinner fa-spin" />
              ) : (
                <i className="fas fa-upload" />
              )}
            </button>
            <div className="white-space-32" />
          </form>
          <label>
            <FormattedMessage id={this.state.messageID} defaultMessage=" " />
          </label>
          <div className="white-space-32" />
        </div>
        <div className="white-space-32" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    verify_tokenpass: state.verify_tokenpass
  };
}

export default injectIntl(connect(mapStateToProps)(RestorePassword));
