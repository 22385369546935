import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

// Images
import Images from '../../img/index';

class CTAEmail extends Component {

    render(){

        return(
            
            <div className="cta-mail-single justify-center" style={{ backgroundImage: `url(${Images['cta-email-single']})` }}>
                <div className="container row-responsive">
                    <div className="column">
                        {/*<div class="white-space-24"></div>*/}
                        
                        {/*<div className="white-space-64"></div>
                            <h2 className="color-white uppercase">
                                <FormattedMessage id="cta-blog" defaultMessage="VISITAR EL BLOG" />
                            </h2>
                            <p className="color-white">
                                <FormattedMessage id="cta-blog-descripcion" defaultMessage="Mira nuestros artículos, tips." />
                            </p>
                        <div className="white-space-64"></div>*/}
                    </div>
                    <div className="row-responsive align-center">
                        {/*<form>
                            <a target="_blank" rel="noopener noreferrer" href="http://blog.elfosgourmet.eu/" className="btn btn-aqua">
                            <FormattedMessage id="cta-blog-ir" defaultMessage="IR AL BLOG" /></a>
                        </form>*/}
                    </div>
                </div>
            </div> 
        );
    }
}

export default CTAEmail;

