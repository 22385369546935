import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl, FormattedNumber } from 'react-intl';

class Menu extends Component {
    componentDidMount() {
        this.active();
    }

    active() {
        var menu = document.getElementById("Menu");
        var btns = menu.getElementsByClassName("menu-link");
        for (var i = 0; i < btns.length; i++) {
            btns[i].addEventListener("click", function () {
                var current = document.getElementsByClassName("active");
                current[0].className = current[0].className.replace(" active", "");
                this.className += " active";
            });
        }
    }

    /*responsive() {
        var x = document.getElementById("Menu");
        if (x.className === "menu justify-center align-center") {
            x.className = "menu-responsive justify-center align-center";
        } else {
            x.className = "menu justify-center align-center";
        }
    }

    displayMenu() {
        let menu = document.getElementById("Menu");
        let nav = document.getElementById("nav");
        if (nav.classList.contains('bg-pink')) {
            nav.classList.remove('bg-pink');
            nav.classList.add('bg-blue');
        } else {
            nav.classList.remove('bg-blue');
            nav.classList.add('bg-pink');
        }

        if (menu.classList.contains('none')) {
            menu.classList.remove('none');
            menu.classList.add('responsive');
        } else {
            menu.className = "menu none justify-center align-center";
        }
    }

    handleClick() {
        document.getElementById('close-menu').click();
    }*/

    render() {
        return(
            <div className="menu none justify-center align-center" id="Menu">
                {/*<a className="bg-pink icon" onClick={this.displayMenu.bind(this)} id="nav">
                    <i className="fa fa-bars"></i>
                </a>*/}
                <Link className="menu-link active" to="/" /*onClick={this.handleClick.bind(this)}*/>
                    <FormattedMessage id="menu-inicio" defaultMessage="Inicio" />
                </Link>
                <Link className="menu-link" to="/nosotros" /*onClick={this.handleClick.bind(this)}*/>
                    <FormattedMessage id="menu-nosotros" defaultMessage="Nosotros" />
                </Link>
                <Link className="menu-link" to="/comprar" /*onClick={this.handleClick.bind(this)}*/>
                    <FormattedMessage id="menu-productos" defaultMessage="Tienda" />
                </Link>
               { /*<a target="_blank" rel="noopener noreferrer" href="http://blog.elfosgourmet.eu/" /*className="menu-link" onClick={this.handleClick.bind(this)}>Blog</a>*/}
                <a href="#contacto" /*onClick={this.handleClick.bind(this)}*/>
                    <FormattedMessage id="menu-contacto" defaultMessage="Contacto" />
                </a>
                {/*<a className="close-menu" id="close-menu" onClick={this.displayMenu.bind(this)}>
                    X
                </a>*/}
                {/*<a class="icon" onClick={this.responsive.bind(this)}>
                    <i class="fa fa-bars"></i>
                </a>*/}
            </div>
        )
    }
}

export default Menu;