import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Images from '../../img/index';
import { FormattedMessage } from 'react-intl';

class Footer extends Component {

    render() {

        return (
            <footer className="column" id="contacto">
                <div className="footer-main justify-center">
                    <div className="container column">
                        <div className="white-space-64"></div>
                        <div className="row-responsive">
                            <div className="column logo auto">
                                <div className="logo-img justify-center">
                                    <img src={Images['elfos-gourmet-logo']} alt="Logo Elfos Gourmet" title="Logo Elfos Gourmet"/>
                                </div>
                                <div className="white-space-8"></div>
                                <h4 className="text-center weight-bold">
                                    <FormattedMessage id="footer-siguenos" defaultMessage="SÍGUENOS" />
                                </h4>
                                <div className="white-space-16"></div>
                                <div className="social">
                                    <div className="social-icon auto color-white">
                                        <a href="https://www.facebook.com/Elfos-Gourmet-167714416619029/" target="_blank" rel="noopener noreferrer" className="link">
                                            <i className="fab fa-facebook"></i>
                                        </a>
                                    </div>
                                    <div className="social-icon auto color-white">
                                        <a href="https://www.messenger.com/t/167714416619029" target="_blank" rel="noopener noreferrer" className="link">
                                            <i className="fab fa-facebook-messenger"></i>
                                        </a>
                                    </div>
                                    <div className="social-icon auto color-white">
                                        <a href="https://www.instagram.com/elfosgourmet_trufasysetas/" target="_blank" rel="noopener noreferrer" className="link">
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                    </div>
                                    <div className="social-icon auto color-white">
                                        <a href="https://twitter.com/elfosgourmet1?lang=es" target="_blank" rel="noopener noreferrer" className="link">
                                            <i className="fab fa-twitter"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="quick-links column">
                                <div className="inner column">
                                    <h4 className="weight-bold">
                                        <FormattedMessage id="footer-enlaces" defaultMessage="ENLACES" />
                                    </h4>
                                    <div className="white-space-16"></div>
                                    <div className="quick-link">
                                        <Link to="../carrito" className="color-white link weight-medium">
                                            <i className="fas fa-angle-right"></i>
                                            <FormattedMessage id="footer-carrito" defaultMessage=" CARRITO" />
                                        </Link>
                                    </div>
                                    {/*<div className="white-space-16"></div>
                                    <div className="quick-link">
                                        <Link to="../pedidos" className="color-white link weight-medium">
                                            <i className="fas fa-angle-right"></i>
                                            <FormattedMessage id="header-right-pedidos" defaultMessage=" PEDIDOS" />
                                        </Link>
                                    </div>*/}
                                    <div className="white-space-16"></div>
                                    <div className="quick-link">
                                        <Link to="../nosotros" className="color-white link weight-medium">
                                            <i className="fas fa-angle-right"></i>
                                            <FormattedMessage id="footer-nosotros" defaultMessage=" NOSOTROS" />
                                        </Link>
                                    </div>                                    
                                    <div className="white-space-16"></div>
                                    <div className="quick-link">
                                        <Link to="../legalidad" className="color-white link weight-medium">
                                            <i className="fas fa-angle-right"></i>
                                            <FormattedMessage id="footer-legalidad" defaultMessage=" LEGALIDAD" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                            </div>
                            <div className="quick-info column">
                                <div className="inner column">
                                    <h4 className="weight-bold">
                                        <FormattedMessage id="footer-contacto" defaultMessage="CONTACTO" />
                                    </h4>
                                    <div className="white-space-16"></div>
                                    <div className="quick-link">
                                        <a href="tel:975 373443" className="color-white link weight-medium">
                                            <i className="fab fa-whatsapp"></i>&nbsp; +(34) 975 373443
                                        </a>
                                    </div>
                                    <div className="white-space-16"></div>
                                    <div className="quick-link">
                                        <a href="mailto:info@elfosgourmet.eu" className="color-white link weight-medium">
                                            <i className="fas fa-envelope"></i>&nbsp; info@elfosgourmet.eu
                                        </a>
                                    </div>
                                    <div className="white-space-16"></div>
                                    <div className="quick-link">
                                        <a href="https://her.is/2yFPPZE" rel="follow" className="color-white link weight-medium">
                                            <i className="fas fa-home"></i>&nbsp; Carretera Nacional 234, km 384. Poligono Industrial La Nava 42146, Cabrejas del Pinar SORIA(España)
                                        </a>
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                            </div>
                        </div>
                        <div className="white-space-64"></div>
                    </div>
                </div>
                <div className="footer-top"></div>
            </footer>            
        );

    }

}

export default Footer;