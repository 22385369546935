import request from "superagent";
import * as types from "../redux/constants";
import { URL_API } from "./urls";

const apiService = store => next => action => {
  next(action);
  switch (action.type) {
    case types.LOGIN:
      request
        .post(action.url)
        .send(action.data)
        .end((err, response) => {
          if (err) {
            console.log(err);
            return next({
              type: types.LOGIN_ERROR,
              error: "Request error"
            });
          }
          if (response.body.error) {
            console.log(response.body.error);
            return next({
              type: types.LOGIN_ERROR,
              error: response.body.error
            });
          } else {
            return next({
              type: types.LOGIN_SUCCESS,
              user: response.body.data
            });
          }
        });
      break;

    case types.GET_PRODUCTS:
      request
        .get(action.url)
        .query(action.data)
        .end((err, res) => {
          if (err) {
            console.log(err);
            return next({
              type: types.GET_PRODUCTS_ERROR
            });
          } else {
            if (res.body.data) {
              return next({
                type: types.GET_PRODUCTS_SUCCESS,
                products: res.body.data
              });
            } else {
              return next({
                type: types.GET_PRODUCTS_ERROR
              });
            }
          }
        });
      break;

    case types.GET_RELEVANTS:
      request
        .get(action.url)
        .query({ idioma: action.locale })
        .end((err, res) => {
          if (err) {
            console.log(err);
            return next({
              type: types.GET_RELEVANTS_ERROR
            });
          } else {
            if (res.body.data) {
              return next({
                type: types.GET_RELEVANTS_SUCCESS,
                relevants: res.body.data
              });
            } else {
              return next({
                type: types.GET_RELEVANTS_ERROR
              });
            }
          }
        });
      break;

    case types.GET_PRODUCTS_SEARCH:
      request
        .get(action.url)
        .query({ idioma: action.idioma, busqueda: action.data.query })
        .end((err, res) => {
          if (err) {
            console.log(err);
            return next({
              type: types.GET_PRODUCTS_SEARCH_ERROR,
              error: err
            });
          }
          if (res.body.error) {
            console.log(res.body.error);
            return next({
              type: types.GET_PRODUCTS_SEARCH_ERROR,
              error: res.body.error
            });
          } else {
            //console.log(res.body);
            return next({
              type: types.GET_PRODUCTS_SEARCH_SUCCESS,
              products: res.body.data,
              busqueda: action.data.query
            });
          }
        });
      break;

    case types.GET_CATEGORIES:
      request
        .get(action.url)
        .query(action.data)
        .end((err, response) => {
          if (err) {
            console.log(err);
            return next({
              type: types.GET_CATEGORIES_ERROR,
              error: err
            });
          }
          if (response.body.error) {
            return next({
              type: types.GET_CATEGORIES_ERROR,
              error: response.body.error
            });
          } else {
            return next({
              type: types.GET_CATEGORIES_SUCCESS,
              categories: response.body.data
            });
          }
        });
      break;

    case types.GET_LINES:
      request.get(action.url).end((err, response) => {
        if (err) {
          console.log(err);
          return next({
            type: types.GET_LINES_ERROR,
            error: err
          });
        }
        if (response.body.error) {
          return next({
            type: types.GET_LINES_ERROR,
            error: response.body.error
          });
        } else {
          return next({
            type: types.GET_LINES_SUCCESS,
            lines: response.body.data
          });
        }
      });
      break;

    case types.CLIENT_REGISTER:
      request
        .post(action.url)
        .send(action.data)
        .end((err, res) => {
          if (err) {
            console.log("Apiservice: ", err);
            action.response({ error: true, message: "Request Error" });
            return next({
              type: types.CLIENT_REGISTER_ERROR,
              error: "Request Error"
            });
          }
          if (res.body.error) {
            console.log("Apiservice response: ", res.body.error);
            action.response({ error: true, message: res.body.message });
            return next({
              type: types.CLIENT_REGISTER_ERROR,
              error: res.body.message
            });
          } else {
            action.response({ message: res.body.data });
            return next({
              type: types.CLIENT_REGISTER_SUCCESS,
              message: res.body.data.mensaje
            });
          }
        });
      break;

    case types.ADD_PEDIDO:
      request
        .post(action.url)
        .send(action.data)
        .end((err, res) => {
          if (err) {
            console.log("Apiservice: ", err);
            action.response(err);
            return next({
              type: types.ADD_PEDIDO_ERROR
            });
          }
          if (res.body.error) {
            console.log("Apiservice response: ", res.body);
            action.response(res.body);
            return next({
              type: types.ADD_PEDIDO_ERROR
            });
          } else {
            action.response(res.body.data);
            return next({
              type: types.ADD_PEDIDO_SUCCESS
            });
          }
        });
      break;

    case types.OBTENER_DATOSPAY:
      request
        .post(action.url)
        .send(action.data)
        .end((err, res) => {
          if (err) {
            console.log(err);
            return next({
              type: types.OBTENER_DATOSPAY
            });
          }
          if (res) {
            action.response(res);
            return next({
              type: types.OBTENER_DATOSPAY
            });
          }
        });
      break;

    case types.GET_SHIPPING_PRICE:
      request
        .get(action.url)
        .query({ pesos: action.pesos, subtotal: action.subtotal })
        .end((err, res) => {
          if (err) {
            console.log(err);
            return next({
              type: types.GET_SHIPPING_PRICE,
              shipping: -1,
              pesos: action.pesos
            });
          }
          if (res.body.shipping >= 0) {
            return next({
              type: types.GET_SHIPPING_PRICE,
              shipping: res.body.shipping,
              pesos: action.pesos
            });
          } else {
            return next({
              type: types.GET_SHIPPING_PRICE,
              shipping: -1,
              pesos: action.pesos
            });
          }
        });

    case types.OBTENER_PEDIDOS:
      request
        .get(action.url)
        .query(action.data)
        .then(res => {
          if (res.body.error) {
            return next({
              type: types.OBTENER_PEDIDOS_ERROR,
              error: res.body.message
            });
          } else {
            return next({
              type: types.OBTENER_PEDIDOS_SUCCESS,
              pedidos: res.body.data
            });
          }
        })
        .catch(err => {
          return next({
            type: types.OBTENER_PEDIDOS_ERROR,
            error: err.message
          });
        });
      break;

    case types.OBTENER_PEDIDO_DETAIL:
      request
        .get(action.url)
        .query(action.data)
        .then(response => {
          if (response.body.error) {
            console.log(response.body.message);
            action.response(response.body);
            return next({
              type: types.OBTENER_PEDIDO_DETAIL_ERROR
            });
          } else {
            //action.response(response.body);
            return next({
              type: types.OBTENER_PEDIDO_DETAIL_SUCCESS
            });
          }
        })
        .catch(err => {
          console.log(err.message);
          action.response({ error: true, message: err.message });
          return next({
            type: types.OBTENER_PEDIDO_DETAIL_ERROR
          });
        });
      break;

    case types.GET_PRODUCT_BANNER:
      request
        .get(action.url)
        .end((err, res) => {
          if (err) {
            console.log(err);
            return next({
              type: types.GET_PRODUCT_BANNER_ERROR
            });
          } else {
            if (res.body.data) {
              return next({
                type: types.GET_PRODUCT_BANNER_SUCCESS,
                products: res.body.data
              });
            } else {
              return next({
                type: types.GET_PRODUCT_BANNER_ERROR
              });
            }
          }
        });
      break;

    default:
      break;
  }
};

class Request {
  get(url, data) {
    const result = request
      .get(URL_API + url)
      .query(data)
      .then(res => {
        return res.body;
      })
      .catch(err => {
        //console.log(err.message);
        return { error: true, message: err.message };
      });
    return result;
  }

  post(url, data) {
    const result = request
      .post(URL_API + url)
      .send(data)
      .then(res => {
        return res.body;
      })
      .catch(err => {
        //console.log(err.message);
        return { error: true, message: err.message };
      });
    return result;
  }
}

export { Request };

export default apiService;
