import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import * as action from "../redux/actions";

import { FormattedMessage, injectIntl } from 'react-intl';

// Web Components
import Slider from '../components/slider/slider';
import CTACatalog from '../components/cta/cta-catalog';
import ProductsContainer from '../components/products/products-container';
import WhyChooseUs from '../components/about/why-choose';
import Catalog from '../components/about/catalog-cards';
import CTAEmail from '../components/cta/cta-mail-single';
import PopOver from '../components/popover/popover';
import ModalBanner from '../components/modals/banner';
import Widget from '../components/popover/widget';

// Images
import Images from '../img/index';

class Inicio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            numitems: 8
        };
        this.props.dispatch(action.getRelevants(this.props.intl.locale));   
    }

    componentDidMount() {
        this.props.dispatch(action.getProductBanner());
        console.log(this.props.products_banner);
        if (this.props.products_banner.length !== 0) {
            const banner = window.localStorage.getItem('banner');
            if (banner) {
                if (parseInt(banner) === parseInt(this.props.products_banner[0].idBProducto)) {
                    //console.log(banner);
                } else {
                    setTimeout(() => {
                        // Trigger Login Modal
                        document.getElementById("modal-banner").classList.remove("hidden");
                    }, 3000);
                }
            } else {
                setTimeout(() => {
                    // Trigger Login Modal
                    document.getElementById("modal-banner").classList.remove("hidden");
                }, 3000);
            }
            /*if (document.getElementById("modal-banner")) {
                setTimeout(() => {
                    // Trigger Login Modal
                    document.getElementById("modal-banner").classList.remove("hidden");
                }, 3000);   
            }*/
        }
    }

    componentWillMount() {
        let slideScript = document.createElement('script');
        slideScript.src = "https://unpkg.com/flickity@2/dist/flickity.pkgd.min.js";
        slideScript.defer = true;
        document.body.appendChild(slideScript); 
    }

    setPage(page) {
        this.setState({ page: page });
    }

    verBanner(line, category) {
        console.log(category);
        this.props.dispatch({type: 'SET_LINE', line: line});
        this.props.dispatch(action.getCategories(line, this.props.intl.locale));
        this.props.dispatch(action.getProducts(line, this.props.intl.locale));
        if(category !== '') {
            this.props.dispatch({type: 'SET_CATEGORY', category: category});
        }
        //this.props.dispatch(action.filterProducts({ idCategoria: category }));
    }

    comprar(line) {
        this.props.dispatch({type: 'SET_LINE', line: line});
        this.props.dispatch(action.getCategories(line, this.props.intl.locale));
        this.props.dispatch(action.getProducts(line, this.props.intl.locale));
    }

    render() {
        const page = this.state.page;
        let pages = 1;
        let items = [];
        if (this.props.products) {
            items = this.props.products;
            pages = Math.ceil(items.length / this.state.numitems);
        }
        return(
            <div className="column">
                <Slider comprar={this.comprar.bind(this)}></Slider>
                <div className="white-space-32"></div>
                <CTACatalog comprar={this.comprar.bind(this)}></CTACatalog>
                <div className="white-space-64"></div>
                <div className="justify-center">
                    <div className="container column">
                        <h2 className="color-black text-center">
                            <FormattedMessage id="inicio-productos-relevantes" defaultMessage="PRODUCTOS RELEVANTES" />
                        </h2>
                        <div className="white-space-16"></div>
                        <div className="justify-center">
                            <img src={ Images['divider'] } alt="Divisor" title="Divisor"/>
                        </div>
                        <div className="white-space-16"></div>
                        {this.props.relevantes_loading ? (
                            <div className="justify-center">
                                <i className="color-darkGreen fas fa-spinner fa-spin fa-2x" />
                            </div>
                            ) : (
                                <ProductsContainer items={items} page={page} numitems={this.state.numitems} />
                            )
                        } 
                        <div className="white-space-32"></div>
                        <div className="justify-center">
                            <Link to="/comprar" className="btn btn-aqua-invert" onClick={this.comprar.bind(this, 1)}>
                                <i className="fas fa-cart-plus"></i>&nbsp; <FormattedMessage id="inicio-ver-todo" defaultMessage="VER TODOS LOS PRODUCTOS" />
                            </Link>
                        </div>
                        <div className="white-space-32"></div>
                        <Catalog />
                        <div className="white-space-16"></div>
                    </div>
                </div>
                <div className="white-space-32"></div>
                <WhyChooseUs></WhyChooseUs>
                <CTAEmail></CTAEmail>
                <PopOver icon="fas fa-cart-plus" text={<FormattedMessage id="popover" defaultMessage="AÑADIDO AL CARRITO" />}></PopOver>
                <Widget></Widget>
                <ModalBanner productsB={this.props.products_banner} comprar={this.verBanner.bind(this)}/>
            </div>            
        );
    }

}

function mapStateToProps(state) {
    return {
        products: state.products,
        relevantes_loading: state.relevantes_loading,
        products_banner: state.products_banner
    };
}

export default injectIntl(connect(mapStateToProps)(Inicio));