import React, { Component } from "react";
import dayjs from 'dayjs';

class Dates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: localStorage.getItem('start_ped') || dayjs().add(-7, 'day').format('YYYY-MM-DD'),
      endDate: localStorage.getItem('end_ped') || dayjs().format('YYYY-MM-DD')
    };
  }

  componentDidMount() {
	this.props.setDates(this.state.startDate, this.state.endDate);
  }

  submit(event) {
    event.preventDefault();
    const startdate = event.target.startdate.value;
    const enddate = event.target.enddate.value;
    if (startdate && enddate) {
		localStorage.setItem('start_ped', startdate);
		localStorage.setItem('end_ped', enddate);
      	this.props.setDates(startdate, enddate);
    }
  }

  startChange(event) {
	this.setState({startDate: event.target.value});
  }

  endChange(event) {
	this.setState({endDate: event.target.value});
  }

  render() {
    return (
      <div className="container-search-order align-center">
        <form onSubmit={this.submit.bind(this)}>
          <div className="row-responsive">
            <div className="column">
              <h5 className="color-black">
                DESDE:
              </h5>
              <div className="white-space-8" />
              <input type="date" id="startdate" name="startdate" className="input input-text" placeholder="Desde" value={this.state.startDate} onChange={this.startChange.bind(this)} />
            </div>
            &nbsp;
            <div className="column">
              <h5 className="color-black">
                HASTA:
              </h5>
              <div className="white-space-8" />
              <input type="date" id="enddate" name="enddate" className="input input-text" placeholder="Hasta" value={this.state.endDate} onChange={this.endChange.bind(this)} />
            </div>
          </div>
          <div className="white-space-8" />
          <div className="justify-end">
            <button className="btn btn-green">
			        {
                !this.props.loading ?
                    <i className="fas fa-table"></i>
			            : <i className="fas fa-spinner fa-spin" />
			        }
              &nbsp; OBTENER
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default Dates;