import React, {Component} from "react";
import {connect} from "react-redux";
import * as action from "../redux/actions";
import PopOver from "../components/popover/popover";
import {FacebookProvider, Comments} from "react-facebook";
import {Helmet} from "react-helmet";
import {FormattedMessage, injectIntl, FormattedNumber} from "react-intl";

// Web Components
import RelevantProducts from "../components/products/relevant-products";

class Producto extends Component {
	constructor(props) {
		super(props);
		this.state = {
			product: {},
			page: 1,
			numitems: 3,
		};
	}

	async loadProductos() {

      await this.props.dispatch(action.getProducts( isNaN(this.props.idLinea) ? 0 : this.props.idLinea , this.props.intl.locale));
	}

	async componentDidUpdate(prevProps, prevState) {
		if (prevProps.products_loading != this.props.products_loading) {
			this.getDataProducts();
		}
	}

	setPage(page) {
		this.setState({page: page});
	}

	addCart(product) {
		this.props.dispatch(action.addCart(product));
		showPopOver();
		function showPopOver() {
			let popOver = document.getElementById("popover");
			popOver.classList.remove("hidden");
			setTimeout(function () {
				popOver.classList.add("hidden");
			}, 2500);
		}
	}

	async getDataProducts() {
		let product = {};

		const productDetailed = await this.props.products.find((item) => {
			return item.idProducto == this.props.match.params.id;
		});
		product = productDetailed || {nombre: "Error"};

		await this.setState({product: product});
	}

	render() {
		const page = this.state.page;
		let items = [];
		let product = this.state.product ? this.state.product : {};

		if (this.props.products) {
			items = this.props.products;
    }

		return (
			<div className="product-detail column">
				<Helmet>
					<title>{`${this.props.products_loading ? "" : product.nombre} - Elfos Gourmet`}</title>
					<meta
						property="og:title"
						content={`${this.props.products_loading ? "" : product.nombre} - Elfos Gourmet`}
					/>
					<meta property="og:url" content={this.props.location.pathname} />
					<meta property="og:description" content={product.descripcion} />
					<meta property="og:image" content="" />
				</Helmet>
				<div className="white-space-32" />
				<div className="justify-center">
					<div className="container">
						<div className="row-responsive break-ipad">
							{/*<div className="left">
								{this.props.products_loading ? (
									<div className="justify-center align-center">
										<p>CARGANDO</p>
									</div>
								) : (
									<RelevantProducts items={items} page={page} numitems={this.state.numitems} />
								)}
							</div>*/}
							
							<div className="right">								
								<div className="column">
									<div className="row-responsive">
										<div className="image auto justify-center">
											<div class="white-space-24"></div>
											<img
												src={
													"https://apielfos.herokuapp.com/imagenes/obtener?id_imagen=" +
													product.idProducto
												}
												alt="Imagen del producto"
												title="Imagen del producto"
											/>
										</div>
										{this.props.products_loading ? (
											<div className="justify-center align-center">
												<img src="../../img/loading.gif" alt="" title=""/>
												
											</div>
										) : (
											<div className="product-info column">
												<div className="product-name">
													<h3 className="color-darkGray">{product.nombre}</h3>
												</div>
												<div className="white-space-16" />
												<div className="product-price">
													<h2 className="color-black">
														{product.a_consultar ? (
															"A CONSULTAR"
														) : product.precio_promo ? (
															<div className="auto align-center justify-center">
																<FormattedNumber
																	currency="EUR"
																	style="currency"
																	value={product.precio_promo}
																/>
																<div className="promotion-price">
																	&nbsp;{" "}
																	<FormattedNumber
																		currency="EUR"
																		style="currency"
																		value={product.precio}
																	/>
																</div>
															</div>
														) : (
															<FormattedNumber
																currency="EUR"
																style="currency"
																value={product.precio}
															/>
														)}
													</h2>
												</div>
												<div className="white-space-16" />
												<div className="product-summary">
													<p className="text-justify" maxLength="350">
														{product.descripcion}
													</p>
												</div>
												<div className="white-space-24" />
												<div className="product-add row-responsive">
													<div className="add-to-cart">
														{product.a_consultar ? null : (
															<button
																className="btn btn-green"
																onClick={this.addCart.bind(this, product)}
															>
																<i className="fas fa-cart-plus" />
																&nbsp;{" "}
																<FormattedMessage
																	id="producto-anadir"
																	defaultMessage="AGREGAR AL CARRITO"
																/>
															</button>
														)}
														&nbsp;
														<a href="/comprar" className="btn btn-aqua">
															<FormattedMessage id="producto-regresar" defaultMessage="VOLVER" />
														</a>
													</div>
												</div>
											</div>
										)}
									</div>
									<div class="white-space-24"></div>
									<div class="white-space-24"></div>
									<div class="white-space-24"></div>
									<div class="white-space-24"></div>
									<div class="white-space-24"></div>
									<div class="white-space-24"></div>
									{/*<div className="commentaries column">
										<div className="white-space-64" />
										<h3 className="color-black">
											<FormattedMessage id="producto-comentarios" defaultMessage="COMENTARIOS" />
										</h3>
										<div className="white-space-16" />
										<FacebookProvider appId="2047616748863660">
											<Comments
												width="100%"
												href={`http://elfosgourmet.eu/producto/${this.props.match.params.id}`}
											/>
										</FacebookProvider>
										<div className="white-space-32" />
									</div>*/}
								</div>
							</div>
						</div>
					</div>
				</div>
				<PopOver icon="fas fa-cart-plus" text="AÑADIDO AL CARRITO" />
			</div>
		);
	}

	async componentDidMount() {
    await this.loadProductos();
		(function (d, s, id) {
			var js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) return;
			js = d.createElement(s);
			js.id = id;
			js.src =
				"https://connect.facebook.net/es_LA/sdk.js#xfbml=1&version=v3.1&appId=179299542990952&autoLogAppEvents=1";
			fjs.parentNode.insertBefore(js, fjs);
		})(document, "script", "facebook-jssdk");
		window.scrollTo(0, 0);
	}
}

function mapStateToProps(state) {
	return {
		product: state.products,
		products: state.products,
		products_loading: state.products_loading,
		idLinea: state.lines_selected,
	};
}

export default injectIntl(connect(mapStateToProps)(Producto));
