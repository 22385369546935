import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

// Web Components
import WhyChoose from '../components/about/why-choose';
import DeliveryProcess from '../components/about/delivery';
import CTAEmail from '../components/cta/cta-mail-single';
import UsVideos from '../components/about/us-videos';

// Images
import Images from '../img/index';

class Nosotros extends Component {

    render() {
        return (
            <div className="column">

                <div className="about-us justify-center">
                    <div className="container column">
                        <div className="white-space-64"></div>
                        <h2 className="color-black text-center">
                            <FormattedMessage id="nosotros-quienes-somos" defaultMessage="QUIÉNES SOMOS" />
                        </h2>
                        <div className="white-space-16"></div>
                        <div className="justify-center">
                            <img src={Images['divider']} alt="Divisor" title="Divisor" />
                        </div>
                        <div className="white-space-32"></div>
                        <div className="row-responsive gutters">
                            <div className="left column">
                                <div className="white-space-8"></div>
                                <h4 className="color-black">
                                    <FormattedMessage id="nosotros-historia" defaultMessage="NUESTRA HISTORIA" />
                                </h4>
                                <div className="white-space-8"></div>
                                <p className="text-justify">
                                    <FormattedMessage id="nosotros-historia-descripcion" defaultMessage="ELFOS GOURMET es una empresa INNOVADORA con 20 años de experiencia dedicada a la transformación, elaboración y comercialización de productos micológicos naturales y artesanos, con unas instalaciones cubiertas de 2.480 m2, respondiendo a una exigencia de la industria alimentaria especializada." />
                                </p>
                                <div className="white-space-8"></div>
                                <img src={Images['nave']} alt="Nave" title="Nave" />
                                <div className="white-space-16"></div>
                                <h4 className="color-black">
                                    <FormattedMessage id="nosotros-reconocimiento" defaultMessage="RECONOCIMIENTO" />
                                </h4>
                                <div className="white-space-8"></div>
                                <p>
                                    <b>Great Taste Gold '08</b>&nbsp;&nbsp;&nbsp;&nbsp;<b>Silver Olive Japan 2021</b>
                                </p>
                                <div className="white-space-8"></div>
                                <div className="awards">
                                    <img src={Images['GREAT2008']} alt="Premio 1" title="Premio 1" />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <img src={Images['reconocimiento']} alt="Premio 1" title="Premio 1" />
                                </div>
                            </div>
                            <div className="right column">
                                <div className="white-space-8"></div>
                                <h4 className="color-black">
                                    <FormattedMessage id="nosotros-nuestros-productos" defaultMessage="NUESTROS PRODUCTOS Y ENTORNO" />
                                </h4>
                                <div className="white-space-8"></div>
                                <p className="text-justify">
                                    <FormattedMessage id="nosotros-nuestros-productos-descripcion" defaultMessage="Los productos se recolectan en un entorno privilegiado a 1200 metros en la provincia de Soria, paradigma mundial del desarrollo sostenible por sus excepcionales condiciones naturales, protegidos y lugares emblemáticos tales como las Lagunas de Neila, el Cañón de Río Lobos, la Laguna Negra y los Picos de Urbión que albergan el Río Duero." />
                                </p>
                                <div className="white-space-8"></div>
                                <img src={Images['pinar']} alt="Pinar" title="Pinar" />
                                <div className="white-space-16"></div>
                            </div>
                        </div>
                        <div className="white-space-64"></div>
                    </div>
                </div>
                <WhyChoose></WhyChoose>
                <UsVideos></UsVideos>
                <DeliveryProcess></DeliveryProcess>
                <CTAEmail></CTAEmail>

            </div>
        );  
    }

    componentDidMount() {
        window.scrollTo(0,0);
    }

}

export default Nosotros;