import React, { Component } from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import * as action from "../redux/actions";
import Dates from "../components/orders/dates";
import OrdersTable from "../components/orders/orders-table";
import Modal from "../components/orders/modal";

class Pedidos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 0
    };
  }

  componentDidMount() {
    let modal = document.getElementById("modal-order");

    let btn = document.getElementById("open-modal-order");

    if (btn) {
      btn.onclick = function() {
        modal.classList.remove("hidden");
      };
    }

    window.onclick = function(event) {
      if (event.target === modal) {
        modal.classList.add("hidden");
      }
    };

    window.onkeydown = event => {
      if (event.keyCode === 27) {
        modal.classList.add("hidden");
      } else {
        return 0;
      }
    };
  }

  findOrders(start, end) {
    const data = {
      start: dayjs(start).format("YYYY-MM-DD"),
      end: dayjs(end).format("YYYY-MM-DD"),
      id_cliente: this.props.user.id_cliente
    };
    this.props.dispatch(action.getOrders(data));
    this.setState({ filters: {}, page: 1 });
  }

  selectorder(order) {
    this.setState({ order: 0 });
    this.render();
    this.setState({ order: order });
  }

  render() {
    return (
      <div className="workspace">
        <div className="control-content justify-center">
          <div className="container column">
            <div className="white-space-32" />
            <h3 className="color-black">
              PEDIDOS
            </h3>
            <div className="white-space-8" />
            <Dates setDates={this.findOrders.bind(this)} loading={this.props.loading} />
            <div className="white-space-16" />
            <OrdersTable page={this.state.page} selectorder={this.selectorder.bind(this)} />
            <div className="white-space-32" />
          </div>
        </div>
        <Modal
          order={this.state.order}
          dispatch={this.props.dispatch}
          loading={this.props.update_order_loading}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user
  };
}
export default connect(mapStateToProps)(Pedidos);
