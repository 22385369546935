import React, { Component } from "react";
import dayjs from "dayjs";
import { FormattedNumber } from "react-intl";

class OrdersRow extends Component {
  openModalOrder() {
    this.props.selectorder(this.props.order);
    document.getElementById("modal-order").classList.remove("hidden");
  }

  render() {
    return (
      <tr
        className="table-row"
        id="open-modal-order"
        onClick={this.openModalOrder.bind(this)}
      >
        <td className="align-left">{this.props.order.folio}</td>
        <td>{dayjs(this.props.order.fecha.replace('Z','')).format("DD/MM/YYYY")}</td>
        <td>
          <FormattedNumber
            currency="EUR"
            style="currency"
            value={this.props.order.importe}
          />
        </td>
        <td className="table-total">{this.props.order.estado}</td>
        <td className="table-total total-check pointer">VER DETALLES</td>
      </tr>
    );
  }
}

export default OrdersRow;