import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Request } from '../api/apiservice';
// Web Components
import * as action from "../redux/actions";
import PageBanner from "../components/banner/page-banner";
import CartTableRow from "../components/tables/cart-row";
import CartTotalSummary from "../components/tables/cart-total-summary";

const req = new Request();

class Carrito extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idMessage: "carrito_login_error",
      envio: 0
    };
    this.props.dispatch(action.getShipping(this.props.pesos, this.props.subtotal));
  }

  /*componentDidMount() {
    this.load(this.props.pesos);
  }

  async load(pesos) {
    if (this.props.subtotal < 100) {
      console.log(pesos);
      const data = { pesos: pesos };
      const res = await req.get('pedidos/shipping', data);
      if (res) {
        console.log(res.shipping);
        this.setState({ envio: res.shipping });
        window.localStorage.setItem('shipping', JSON.stringify(res.shipping));
      } 
    } else {
      this.setState({ envio: 0 });
    }
  }*/

  async checkOut() {
    if (this.props.auth) {
      if (this.props.shipping.toString() === "-1") {
        console.log(this.props.pesos);
        this.props.dispatch(action.getShipping(this.props.pesos, this.props.subtotal));
        this.setState({ idMessage: "carrito_shipping_error" });
        document
          .getElementById("modal-alert-checkout")
          .classList.remove("hidden");
      } else {
        window.location = "/checkout";
      }
      /*console.log(this.state.envio);
      if (this.state.envio.toString() === "-1") {
        const data = { pesos: this.props.pesos };
        const res = await req.get('pedidos/shipping', data);
        if (res) {
          console.log(res);
        }
        this.setState({ idMessage: "carrito_shipping_error" });
        document
          .getElementById("modal-alert-checkout")
          .classList.remove("hidden");
      } else {
        window.location = "/checkout";
      }*/
    } else {
      this.setState({ idMessage: "carrito_login_error" });
      document
        .getElementById("modal-alert-checkout")
        .classList.remove("hidden");
    }
  }

  renderAlert() {
    return (
      <div className="flex modal hidden" id="modal-alert-checkout">
        <div className="flex modal-content justify-center column" style={{ maxWidth: "300px" }} >
          <div className="white-space-32" />
          <label className="text-center">
            <FormattedMessage id={this.state.idMessage} defaultMessage="ERROR" />
          </label>
          <div className="white-space-32" />
          <button className="btn btn-red text-center" onClick={() => document.getElementById("modal-alert-checkout").classList.add("hidden")}>
            <FormattedMessage id="carrito-render-alert-aceptar" defaultMessage="ACEPTAR" />
          </button>
        </div>
      </div>
    );
  }

  render() {
    let pesos = 0;
    if (this.props.carrito && this.props.subtotal < 100) {
      pesos = this.props.carrito.reduce((a, b) => a + b.peso * b.cantidad, 0);
    }
    return (
      <div className="column">
        <Helmet>
          <title>Elfos Gourmet - Carrito</title>
        </Helmet>
        <PageBanner bannerImage="bannerpagecart" bannerTitle={ <FormattedMessage id="carrito-banner-title" defaultMessage="CARRITO" /> } />
        <div className="cart-container justify-center">
          <div className="container column">
            <div className="white-space-32" />
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="carrito-table-imagen" defaultMessage="IMAGEN" />
                    </th>
                    <th className="th-name">
                      <FormattedMessage id="carrito-table-producto" defaultMessage="PRODUCTO" />
                    </th>
                    <th> 
                      <FormattedMessage id="carrito-table-cantidad" defaultMessage="CANTIDAD " />
                    </th>
                    <th>
                      <FormattedMessage id="carrito-table-precio" defaultMessage="PRECIO" />
                    </th>
                    <th>
                      <FormattedMessage id="carrito-table-promocion" defaultMessage="PROMOCIÓN" />
                    </th>
                    <th>TOTAL</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {this.props.carrito.map((product, index) => {
                    return <CartTableRow key={index} item={product} />;
                  })}
                </tbody>
              </table>
            </div>
            <div className="white-space-32" />
            <CartTotalSummary subtotal={this.props.subtotal} pesos={pesos} dispatch={this.props.dispatch} shipping={this.props.shipping} /*shipping={this.state.envio}*/ />
            <div className="white-space-32" />
            <p>
              <b>
                <FormattedMessage
                  id="carrito-importante"
                  defaultMessage="IMPORTANTE:"
                />
              </b>
            </p>
            <p>
              <FormattedMessage id="carrito-pedidos-superiores" defaultMessage="Pedidos superiores 100€ con envío gratuito." /><br />
              <FormattedMessage id="carrito-pedidos-fuera" defaultMessage="Para pedidos fuera de España e Islas Canarias por favor contactar antes de comprar: " />
              <a href="mailto:info@elfosgourmet.eu"><b> info@elfosgourmet.eu</b></a>
            </p>
            <div className="white-space-32" />
            <div className="btn-container-checkout column">
              {this.props.carrito_count > 0 ? (
                <a
                  className="btn btn-aqua text-center"
                  onClick={this.checkOut.bind(this)}
                >
                  <i className="fas fa-box-open" />
                  &nbsp;{" "}
                  <FormattedMessage
                    id="carrito-generar-pedido"
                    defaultMessage="GENERAR PEDIDO"
                  />
                </a>
              ) : (
                ""
              )}
              <div className="white-space-24" />
              <Link to="comprar" className="text-mini text-center link">
                <FormattedMessage
                  id="carrito-continuar-comprando"
                  defaultMessage="CONTINUAR COMPRANDO"
                />
              </Link>
            </div>
            <div className="white-space-32" />
          </div>
        </div>
        {this.renderAlert()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    carrito: state.carrito,
    subtotal: state.subtotal,
    shipping: state.shipping,
    pesos: state.pesos,
    carrito_count: state.carrito_count
  };
}

export default connect(mapStateToProps)(Carrito);
