import React, { Component } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { IMG_URL } from '../../api/urls';

class RelevantProduct extends Component {
  render() {
    return (
      <div className="column">
        <div className="relevat-product">
          <div className="row-responsive">
            <div className="image auto">
              <img src={ IMG_URL + "imagenes/obtener?id_imagen=" + this.props.item.idProducto } alt="Producto Interesante" alt="Producto Interesante" />
            </div>
            <div className="info column">
              <div className="relevant-name">
                <h5 className="color-black">{this.props.item.nombre}</h5>
              </div>
              <div className="white-space-8" />
              <div className="relevant-price">
                <h3 className="color-aqua weight-bold">
                {
                  this.props.item.a_consultar ?
                  ( "A CONSULTAR" ) :
                  ( this.props.item.precio_promo ? 
                    ( <FormattedNumber currency="EUR" style="currency" value={this.props.item.precio_promo} /> ) :
                    (
                      <FormattedNumber currency="EUR" style="currency" value={this.props.item.precio} />    
                    )
                  )
                }
                  
                </h3>
              </div>
              <div className="white-space-8" />
              <a href={`${this.props.item.idProducto}`} className="color-green weight-medium text-mini link" >
                <FormattedMessage id="relevant-product-ver" defaultMessage="VER PRODUCTO" />
              </a>
            </div>
          </div>
        </div>
        <div className="white-space-16" />
      </div>
    );
  }
}

export default RelevantProduct;
