import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import Request from "../../core/httpClient";

const req = new Request();

class Pedidos extends Component {
	constructor(props) {
		super(props);
		this.state = {
			order: 0,
			user: {}
		};
	}

	componentDidMount() {
		let modal = document.getElementById("modal-order");
		let btn = document.getElementById("open-modal-order");
		if (btn) {
			btn.onclick = function() {
				modal.classList.remove("hidden");
			};
		}
		window.onclick = function(event) {
			if (event.target === modal) {
				modal.classList.add("hidden");
			}
		};
		window.onkeydown = event => {
			if (event.keyCode === 27) {
				modal.classList.add("hidden");
			} else {
				return 0;
			}
		};
		this.loadUser();
	}

	async loadUser() {
		this.setState({loading: true});
		const data = {
			id_cliente: this.props.user.id_cliente
		}
		const res = await req.post('/clientes/getbyid', data);
		if(res.data) {
			this.setState({user: res.data});
			const {usuario, email, nombre, apellidos, telefono, direccion, numInterior, numExterior, ciudad, estado, pais, zip, dni} = res.data;
			document.getElementById('username').value = usuario || "";
			document.getElementById('email').value = email || "";
			document.getElementById('name').value = nombre || "";
			document.getElementById('lastname').value = apellidos || "";
			document.getElementById('telephone').value = telefono || "";
			document.getElementById('address').value = direccion || "";
			document.getElementById('intnumber').value = numInterior || "";
			document.getElementById('extnumber').value = numExterior || "";
			document.getElementById('city').value = ciudad || "";
			document.getElementById('state').value = estado || "";
			document.getElementById('country-list').value = pais || "";
			document.getElementById('zip').value = zip || "";
			document.getElementById('dni').value = dni || "";
			dni ? this.setState({ facturar: true }) : this.setState({ facturar: false });
		} else {
			alert(res.message);
		}
		this.setState({loading: false});
	}

	async saveUser(event) {
		event.preventDefault();
		this.setState({loading: true});
		const username = event.target.username.value;
		const user = {
			id_cliente: this.state.user.id_cliente,
			usuario: username,
			email: event.target.email.value,
			nombre: event.target.firstName.value,
			apellidos: event.target.lastName.value,
			telefono: event.target.phone.value,
			direccion: event.target.fullAddress.value,
			numInterior: event.target.internalNumber.value,
			numExterior: event.target.externalNumber.value,
			ciudad: event.target.city.value,
			estado: event.target.state.value,
			pais: event.target.county.value,
			zip: event.target.zip.value,
			dni: this.state.facturar ? event.target.dni.value : ''
		};
		const res = await req.post('/clientes/edit', user);
		if(res.updated) {
			window.location.reload();
		}
		this.setState({loading: false});
	}

	openPassword() {
		const modal = document.getElementById('modal-change-password');
		if(modal) {
			modal.classList.remove('hidden');
		}
	}

	async changePassword(event) {
		event.preventDefault();

		this.setState({loadingPassword: true, passwordMessage: ''});
		const form = event.target;
		const data = {
			prev_pass: form.prev_pass.value,
			new_pass: form.new_pass.value,
			ret_pass: form.ret_pass.value,
			id_cliente: this.props.user.id_cliente
		}
		if(data.prev_pass) {
			if(data.new_pass === data.ret_pass) {
				document.getElementById('new_pass').style.border = "2px solid #E9EBED";
				document.getElementById('ret_pass').style.border = "2px solid #E9EBED";
				/**Enviar petición al API */
				const res = await req.post('/clientes/changepassword', data);
				this.setState({passwordMessage: res.message});
			} else {
				this.setState({passwordMessage: 'Las contraseñas no coinciden.'});
				document.getElementById('new_pass').style.border = "1px solid red";
				document.getElementById('ret_pass').style.border = "1px solid red";
			}
		} else {
			this.setState({passwordMessage: 'Contraseña actual no válida.'});
		}
		this.setState({loadingPassword: false});
	}

	renderPassword() {
		return (
			<div className="flex modal justify-center hidden" id="modal-change-password">
				<div className="container modal-content column justify-center">
					<div className="modal-header justify-center">
						<h2 className="color-white">
							<FormattedMessage
								id="modal_password_cambiar"
								defaultMessage="CAMBIAR PASSWORD"
							/>
						</h2>
						<a className="color-white pointer close" onClick={() => {
							const modal = document.getElementById('modal-change-password');
							if(modal) {
								modal.classList.add('hidden');
							}
						}}>
							<i className="fas fa-times"></i>
						</a>
					</div>
					<div className="modal-body column">
						<form className="modal-body-container column" onSubmit={this.changePassword.bind(this)}>
							<div className="white-space-16" />
							<label htmlFor="prev_pass" className="color-black">
								<FormattedMessage
									id="modal_password_actual"
									defaultMessage="CONTRASEÑA ACTUAL"
								/>
							</label>
							<div className="white-space-8" />
							<input type="password" id="prev_pass" name="prev_pass" className="input input-text" required></input>
							<div className="white-space-16" />
							<label htmlFor="new_pass" className="color-black">
								<FormattedMessage
									id="modal_password_nueva"
									defaultMessage="NUEVA CONTRASEÑA"
								/>
							</label>
							<div className="white-space-8" />
							<input type="password" id="new_pass" name="new_pass" className="input input-text" required></input>
							<div className="white-space-16" />
							<label htmlFor="ret_pass" className="color-black">
								<FormattedMessage
									id="modal_password_repetir"
									defaultMessage="REPETIR CONTRASEÑA"
								/>
							</label>
							<div className="white-space-8" />
							<input type="password" id="ret_pass" className="input input-text" required></input>
							<div className="white-space-16" />
							<div className="column align-center">
								<h4 className="color-black" style={{textAlign: 'center'}}>{this.state.passwordMessage}</h4>
							</div>
							<div className="white-space-16" />
							<div className="btn-container-login">
								<button type="submit" className="btn btn-aqua">
									<i className="fas fa-upload" />
									&nbsp;{" "}
									<FormattedMessage
										id="modal_password_save"
										defaultMessage="GUARDAR"
									/>
									&nbsp;
									{this.state.loadingPassword ? (
										<i className="fas fa-spinner fa-spin" />
									) : null}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		)
	}

 	render() {
    	return (
			<div className="register justify-center">
				<div className="container column">
					<div className="white-space-32" />
					<form className="column" onSubmit={this.saveUser.bind(this)}>
						<div className="row-responsive justify-between break-ipad">
							<div className="personal-info column">
								<div className="white-space-16" />
								<h3 className="color-black">
								<FormattedMessage
									id="datos-cuenta"
									defaultMessage="DATOS DE LA CUENTA"
								/>
								</h3>
								<div className="white-space-16" />
								<label htmlFor="firstName" className="color-black">
									<FormattedMessage
										id="registro-nombre"
										defaultMessage="Nombre *"
									/>
								</label>
								<div className="white-space-8" />
								<input type="text" name="firstName" id="name" className="input input-text" required />
								<div className="white-space-16" />
								<label htmlFor="lastName" className="color-black">
									<FormattedMessage
										id="registro-apellidos"
										defaultMessage="Apellidos *"
									/>
								</label>
								<div className="white-space-8" />
								<input type="text" name="lastName" id="lastname" className="input input-text" required />
								<div className="white-space-16" />
								<label htmlFor="email" className="color-black">
									<FormattedMessage
										id="registro-email"
										defaultMessage="Email *"
									/>
								</label>
								<div className="white-space-8" />
								<input type="email" name="email" id="email" className="input input-text" required />
								<div className="white-space-16" />
								<label htmlFor="phone" className="color-black">
									<FormattedMessage
										id="registro-telefono"
										defaultMessage="Teléfono"
									/>
								</label>
								<div className="white-space-8" />
								<input type="text" name="phone" id="telephone" className="input input-text" />
								<div className="white-space-16" />
								<label htmlFor="username" className="color-black">
									<FormattedMessage
										id="registro-username"
										defaultMessage="Nombre de usuario *"
									/>
								</label>
								<div className="white-space-8" />
								<input type="text" name="username" id="username" className="input input-text" required />
								<div className="white-space-16" />
								<div className="row align-center justify-start">
									<label htmlFor="facturar" className="pointer color-black">
									<FormattedMessage
										id="checkout-facturar"
										defaultMessage="REQUIERE FACTURA"
									/>
									</label>
									&nbsp;
									<input id="facturar" type="checkbox" className="pointer" checked={this.state.facturar} onChange={event =>  {
										this.setState({ facturar: event.target.checked });
										document.getElementById('dni').value = "";
									}} />
								</div>
								<div className="white-space-16" />
								<label htmlFor="dni" className="color-black">
									<FormattedMessage
										id="registro-dni"
										defaultMessage="DNI / NIF"
									/>
								</label>
								<div className="white-space-8" />
								{this.state.facturar ? 
								<input type="text" name="dni" id="dni" className="input input-text" required /> : 
								<input type="text" name="dni" id="dni" className="input input-text" />}
								<p>
									<FormattedMessage
										id="obligatorio-factura"
										defaultMessage="*Obligatorio para emitir factura"
									/>
								</p>
								<div className="white-space-32" />
								<button type="button" className="btn btn-aqua" id="btn-register" onClick={this.openPassword.bind(this)}>
									<FormattedMessage
										id="cambio-password"
										defaultMessage="CAMBIAR CONTRASEÑA"
									/>
								</button>
							</div>
							<div className="address-info column">
								<div className="white-space-16" />
								<h3 className="color-black">
									<FormattedMessage
										id="registro-envio"
										defaultMessage="¿DÓNDE ENVIAREMOS TUS COMPRAS?"
									/>
								</h3>
								<div className="white-space-16" />
								<label htmlFor="fullAddress" className="color-black">
									<FormattedMessage
										id="registro-direccion"
										defaultMessage="Dirección *"
									/>
								</label>
								<div className="white-space-8" />
								<input type="text" name="fullAddress" id="address" className="input input-text" required />
								<div className="white-space-16" />
								<label htmlFor="internalNumber" className="color-black">
									<FormattedMessage
										id="registro-numInterior"
										defaultMessage="Número Interior"
									/>
								</label>
								<div className="white-space-8" />
								<input type="text" name="internalNumber" id="intnumber" className="input input-text" />
								<div className="white-space-16" />
								<label htmlFor="externalNumber" className="color-black">
									<FormattedMessage
										id="registro-numExterior"
										defaultMessage="Número Exterior *"
									/>
								</label>
								<div className="white-space-8" />
								<input type="text" name="externalNumber" id="extnumber" className="input input-text" required />
								<div className="white-space-16" />
								<label htmlFor="city" className="color-black">
									<FormattedMessage
										id="registro-ciudad"
										defaultMessage="Cuidad *"
									/>
								</label>
								<div className="white-space-8" />
								<input type="text" name="city" id="city" className="input input-text" required />
								<div className="white-space-16" />
								<label htmlFor="state" className="color-black">
									<FormattedMessage
										id="registro-estado"
										defaultMessage="Estado / Provincia *"
									/>
								</label>
								<div className="white-space-8" />
								<input type="text" name="state" id="state" className="input input-text" required />
								<div className="white-space-16" />
								<label htmlFor="zip" className="color-black">
									<FormattedMessage
										id="registro-zip"
										defaultMessage="Código Postal *"
									/>
								</label>
								<div className="white-space-8" />
								<input type="text" name="zip" id="zip" className="input input-text" pattern="[0-9]{5}" required />
								<div className="white-space-16" />
								<label htmlFor="country" className="color-black">
									<FormattedMessage
										id="registro-country"
										defaultMessage="País *"
									/>
								</label>
								<div className="white-space-8" />
								<select name="county" id="country-list" className="input input-select" required >
									<option value="ESP">España</option>
								</select>
								<div className="white-space-16" />
							</div>
						</div>
						<div className="white-space-8" />
						<p>
							<FormattedMessage
								id="registro-datos-obligatorios"
								defaultMessage="* Datos Obligatorios"
							/>
						</p>
						<div className="white-space-32" />
						<button type="submit" className="btn btn-aqua" id="btn-register">
							<i className="fas fa-sign-in-alt" />
							&nbsp;{" "}
							<FormattedMessage
								id="registro-crear"
								defaultMessage="GUARDAR"
							/>
							{this.props.register_loading ? (
								<i className="fas fa-spinner fa-spin" />
							) : null}
						</button>
						<div className="white-space-16" />
						<strong className="color-darkGreen" id="register-status">
							{this.state.login_error === "1"
								? this.registerOk()
								: this.state.login_error === "2"
								? ""
								: this.state.login_error === "3"
								? "Usuario en uso o datos inválidos"
								: ""}
						</strong>
						<div className="white-space-32" />
					</form>
				</div>
				{this.renderPassword()}
			</div>
    	);
  	}
}

function mapStateToProps(state) {
	return {
		user: state.user
	}
}

export default connect(mapStateToProps)(Pedidos);
