import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as action from "../../redux/actions";
import { injectIntl, FormattedMessage } from "react-intl";

// Web Component
import Product from './product';

class ProductContainer extends Component {
    
    render() {
        let items = [];
        if ( this.props.items ) {
            items = this.props.items;
        }
        const numberOfItems = this.props.numitems;
        return(
            <div className="featured-products">
                <div className="column">
                    <div className="white-space-32"></div>
                    <div className="products-container">
                        {items.length !== 0 ?
                            items
                            .slice((this.props.page - 1) * numberOfItems, this.props.page * numberOfItems)
                            .map(function(item, index){
                                return(
                                    <Product key = { index } item = { item }></Product>
                                )
                            })
                        : 
                        <div className="column">
                            <div className="white-space-24"></div>
                            <h3 className="text-center color-black">No se encontraron resultados.</h3>
                        </div>}
                    </div>
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        products: state.products_filter
    };
}

export default connect(mapStateToProps)(ProductContainer);