import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../redux/actions";
import { injectIntl, FormattedMessage } from "react-intl";

class ProductLine extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(action.getLines());
  }
  handleClick(idLinea) {
    if (parseFloat(this.props.lines_selected) === parseFloat(idLinea)) {
      this.props.dispatch(action.getProducts(idLinea, this.props.intl.locale));
    }
  }
  hanldeChange(event) {
    this.props.dispatch(
      action.getCategories(event.target.value, this.props.intl.locale)
    );
    this.props.dispatch(
      action.getProducts(event.target.value, this.props.intl.locale)
    );
  }
  render() {
    let lines = [];
    if (this.props.lines) {
      lines = this.props.lines;
    }
    return (
      <div className="column">
        <div className="white-space-32" />
        <h3 className="color-black">
          <FormattedMessage id="comprar-linea" defaultMessage="LÍNEA DE PRODUCTOS" />
        </h3>
        <div className="white-space-16" />
        <div className="product-line column">
          {lines.map((line, index) => {
            return (
              <div
                className="column"
                key={index}
                onClick={this.handleClick.bind(this, line.idLinea)}
              >
                <label className="container-checkbox">
                  {line.Linea}
                  <input
                    type="radio"
                    className="lineradio"
                    name="product-line"
                    value={line.idLinea}
                    onChange={this.hanldeChange.bind(this)}
                    checked={
                      parseFloat(this.props.lines_selected) === line.idLinea
                        ? true
                        : false
                    }
                  />
                  <span className="checkmark" />
                </label>
                <div className="white-space-8" />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  componentDidMount() {
    if (document.getElementsByClassName("lineradio")[0]) {
      document
        .getElementsByClassName("lineradio")[0]
        .setAttribute("checked", true);
    } else {
      setTimeout(function() {
        if (document.getElementsByClassName("lineradio")[0]) {
          document
            .getElementsByClassName("lineradio")[0]
            .setAttribute("checked", true);
        }
      }, 2000);
    }
  }
}

function mapStateToProps(state) {
  return {
    lines: state.lines,
    lines_selected: state.lines_selected
  };
}

export default injectIntl(connect(mapStateToProps)(ProductLine));
