import React from "react";
import { FormattedNumber } from "react-intl";

const ProductRow = function(props) {
  return (
    <tr className="table-row">
      <td>{props.product.sku}</td>
      <td className="table-product-name">{props.product.producto}</td>
      {/*<td className="table-unit">Pieza</td>*/}
      <td className="table-quatity">{props.product.cantidad}</td>
      {/*<td className="table-price">
        <FormattedNumber
          currency="EUR"
          style="currency"
          value={props.product.precio}
        />
      </td> */ }
      <td className="table-total">
        <FormattedNumber
          currency="EUR"
          style="currency"
          value={props.product.total}
        />
      </td>
    </tr>
  );
};

export default ProductRow;