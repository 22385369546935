import React from 'react';
import { FormattedMessage } from 'react-intl';

// Images
import Images from '../../img/index';

const DeliveryProcess = function() {

    return(

        <div className="about-delivery justify-center">
            <div className="container column">
                <div className="white-space-64"></div>
                <h2 className="color-black text-center">
                    <FormattedMessage id="nosotros-proceso" defaultMessage="PROCESO DE ENTREGA" />
                </h2>
                <div className="white-space-16"></div>
                <div className="justify-center">
                    <img src = { Images['divider'] } alt="Divisor" title="Divisor" />
                </div>
                <div className="white-space-32"></div>
                <div className="justify-between wrap">
                    <div className="delivery-step column auto">
                        <div className="delivery-img">
                            <img src={ Images['bagicon'] } alt="Proceso de envío" title="Proceso de envío" />
                        </div>
                        <div className="white-space-16"></div>
                        <h4 className="color-black text-center">
                            <FormattedMessage id="nosotros-ordenar" defaultMessage="ORDENAR" />
                        </h4>
                    </div>
                    <div className="delivery-step column auto">
                        <div className="delivery-img">
                            <img src={ Images['packingicon']} alt="Proceso de envío" title="Proceso de envío" />
                        </div>
                        <div className="white-space-16"></div>
                        <h4 className="color-black text-center">
                            <FormattedMessage id="nosotros-preparar" defaultMessage="PREPARAR" />
                        </h4>
                    </div>
                    <div className="delivery-step column auto">
                        <div className="delivery-img">
                            <img src={ Images['deliveryicon']} alt="Proceso de envío" title="Proceso de envío" />
                        </div>
                        <div className="white-space-16"></div>
                        <h4 className="color-black text-center">
                            <FormattedMessage id="nosotros-enviar" defaultMessage="ENVIAR" />
                        </h4>
                    </div>
                    <div className="delivery-step column auto">
                        <div className="delivery-img">
                            <img src={ Images['ordericon']} alt="Proceso de envío" title="Proceso de envío" />
                        </div>
                        <div className="white-space-16"></div>
                        <h4 className="color-black text-center">
                            <FormattedMessage id="nosotros-entregar" defaultMessage="ENTREGAR" />
                        </h4>
                    </div>
                </div>
                <div className="white-space-64"></div>
            </div>
        </div>

    );

}

export default DeliveryProcess;