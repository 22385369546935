import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import * as action from "../redux/actions";
import { FormattedMessage } from "react-intl";

// Web Components
import PageBanner from "../components/banner/page-banner";
import { setTimeout } from "timers";

class Registro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      login_error: "",
      error_class: "label-error"
    };
  }

  cipher(text) {
    const string64 = new Buffer(text).toString("base64");
    return string64;
  }

  register(event) {
    event.preventDefault();
    const username = event.target.username.value;
    const password = this.cipher(event.target.password.value);
    const user = {
      usuario: username,
      password: password,
      email: event.target.email.value,
      nombre: event.target.firstName.value,
      apellidos: event.target.lastName.value,
      telefono: event.target.phone.value,
      direccion: event.target.fullAddress.value,
      numInterior: event.target.internalNumber.value,
      numExterior: event.target.externalNumber.value,
      ciudad: event.target.city.value,
      estado: event.target.state.value,
      pais: event.target.county.value,
      zip: event.target.zip.value,
      dni: this.state.facturar ? event.target.dni.value : ''
    };
    this.props.dispatch(action.client_register(user, this.response.bind(this)));
  }

  response(res) {
    console.log(res);
    let message = '';
    if (res.error) {
      console.log(res.message);
      message = <FormattedMessage id="register_mesage_error" defaultMessage="Error al registrar, intente más tarde" />;
    } else if(res.message.mensaje === "3") {
      message = <FormattedMessage id="register_message" defaultMessage="Este usuario ya existe, intente con otro." />
      //window.location = "/";
    } else {
      message = <FormattedMessage id="register_mesage_success" defaultMessage="Registro de usuario exitoso" />
      window.location = "/";
    }
    this.setState({ error: res.error, message: message });
    const modal = document.getElementById("registro-modal-message");
    if (modal) {
      modal.classList.remove("hidden");
    }
  }

  renderModal() {
    const modal = document.getElementById("registro-modal-message");
    return (
      <div
        className="flex modal justify-center hidden"
        id="registro-modal-message"
      >
        <div
          className="container modal-content column"
          style={{ maxWidth: "300px" }}
        >
          <div className="modal-header justify-center">
            <h2 className="color-white">
              <FormattedMessage id="modal_login_header" defaultMessage="REGISTRO" />
            </h2>
          </div>
          <div className="modal-body column justify-center">
            <div className="white-space-32" />
            <div className="justify-center">
              <label>{this.state.message}</label>
            </div>
            <div className="white-space-32" />
            <div className="btn-container-login">
              <button
                className="btn btn-red"
                onClick={() => modal.classList.add("hidden")}
              >
                <i className="fas fa-close" />
                &nbsp;{" "}
                <FormattedMessage id="modal_close" defaultMessage="CERRAR" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="column">
        <Helmet>
          <title>Elfos Gourmet - Registro</title>
        </Helmet>
        <PageBanner
          bannerImage="bannershop"
          bannerTitle={
            <FormattedMessage
              id="registro-crear-cuenta"
              defaultMessage="CREAR CUENTA"
            />
          }
        />
        <div className="register justify-center">
          <div className="container column">
            <div className="white-space-32" />
            <form className="column" onSubmit={this.register.bind(this)}>
              <div className="row-responsive justify-between break-ipad">
                <div className="personal-info column">
                  <div className="white-space-16" />
                  <h3 className="color-black">
                    <FormattedMessage
                      id="registro-cuentanos"
                      defaultMessage="CUÉNTANOS DE TI"
                    />
                  </h3>
                  <div className="white-space-16" />
                  <label htmlFor="firstName" className="color-black">
                    <FormattedMessage
                      id="registro-nombre"
                      defaultMessage="Nombre *"
                    />
                  </label>
                  <div className="white-space-8" />
                  <input
                    type="text"
                    name="firstName"
                    className="input input-text"
                    required
                  />
                  <div className="white-space-16" />
                  <label htmlFor="lastName" className="color-black">
                    <FormattedMessage
                      id="registro-apellidos"
                      defaultMessage="Apellidos *"
                    />
                  </label>
                  <div className="white-space-8" />
                  <input
                    type="text"
                    name="lastName"
                    className="input input-text"
                    required
                  />
                  <div className="white-space-16" />
                  <label htmlFor="email" className="color-black">
                    <FormattedMessage
                      id="registro-email"
                      defaultMessage="Email *"
                    />
                  </label>
                  <div className="white-space-8" />
                  <input
                    type="email"
                    name="email"
                    className="input input-text"
                    required
                  />
                  <div className="white-space-16" />
                  <label htmlFor="phone" className="color-black">
                    <FormattedMessage
                      id="registro-telefono"
                      defaultMessage="Teléfono"
                    />
                  </label>
                  <div className="white-space-8" />
                  <input
                    type="text"
                    name="phone"
                    className="input input-text"
                  />
                  <div className="white-space-16" />
                  <label htmlFor="username" className="color-black">
                    <FormattedMessage
                      id="registro-username"
                      defaultMessage="Nombre de usuario *"
                    />
                  </label>
                  <div className="white-space-8" />
                  <input
                    type="text"
                    name="username"
                    className="input input-text"
                    required
                  />
                  <div className="white-space-16" />
                  <label htmlFor="password" className="color-black">
                    <FormattedMessage
                      id="registro-password"
                      defaultMessage="Contraseña *"
                    />
                  </label>
                  <div className="white-space-8" />
                  <input
                    type="password"
                    name="password"
                    className="input input-text"
                    onChange={event =>
                      this.setState({ password: event.target.value })
                    }
                    value={this.state.password}
                    required
                  />
                  <div className="white-space-16" />
                  <label htmlFor="repassword" className="color-black">
                    <FormattedMessage
                      id="registro-repassword"
                      defaultMessage="Confirmar Contraseña *"
                    />
                  </label>
                  <div className="white-space-8" />
                  <input
                    type="password"
                    name="repassword"
                    className="input input-text"
                    required
                  />
                  <div className="white-space-16" />
                  <div className="row align-center justify-start">
                    <label htmlFor="facturar" className="pointer color-black">
                      <FormattedMessage
                        id="checkout-facturar"
                        defaultMessage="REQUIERE FACTURA"
                      />
                    </label>
                    &nbsp;
                    <input id="facturar" type="checkbox" className="pointer" checked={this.state.facturar} onChange={event => this.setState({ facturar: event.target.checked })} />
                  </div>
                  {this.state.facturar &&
                    <React.Fragment>
                      <div className="white-space-16" />
                      <div className="column">
                        <label htmlFor="dni" className="color-black">
                          <FormattedMessage
                            id="registro-dni"
                            defaultMessage="DNI / NIF"
                          />
                        </label>
                        <div className="white-space-8" />
                        <input type="text" name="dni" id="dni" className="input input-text" required />
                        <p>
                          <FormattedMessage
                            id="obligatorio-factura"
                            defaultMessage="*Obligatorio para emitir factura"
                          />
                        </p>
                      </div>
                    </React.Fragment>
                  }
                </div>
                <div className="address-info column">
                  <div className="white-space-16" />
                  <h3 className="color-black">
                    <FormattedMessage
                      id="registro-envio"
                      defaultMessage="¿DÓNDE ENVIAREMOS TUS COMPRAS?"
                    />
                  </h3>
                  <div className="white-space-16" />
                  <label htmlFor="fullAddress" className="color-black">
                    <FormattedMessage
                      id="registro-direccion"
                      defaultMessage="Dirección *"
                    />
                  </label>
                  <div className="white-space-8" />
                  <input
                    type="text"
                    name="fullAddress"
                    className="input input-text"
                    required
                  />
                  <div className="white-space-16" />
                  <label htmlFor="internalNumber" className="color-black">
                    <FormattedMessage
                      id="registro-numInterior"
                      defaultMessage="Piso/Puerta"
                    />
                  </label>
                  <div className="white-space-8" />
                  <input
                    type="text"
                    name="internalNumber"
                    className="input input-text"
                  />
                  <div className="white-space-16" />
                  <label htmlFor="externalNumber" className="color-black">
                    <FormattedMessage
                      id="registro-numExterior"
                      defaultMessage="Número *"
                    />
                  </label>
                  <div className="white-space-8" />
                  <input
                    type="text"
                    name="externalNumber"
                    className="input input-text"
                    required
                  />
                  <div className="white-space-16" />
                  <label htmlFor="city" className="color-black">
                    <FormattedMessage
                      id="registro-ciudad"
                      defaultMessage="Cuidad *"
                    />
                  </label>
                  <div className="white-space-8" />
                  <input
                    type="text"
                    name="city"
                    className="input input-text"
                    required
                  />
                  <div className="white-space-16" />
                  <label htmlFor="state" className="color-black">
                    <FormattedMessage
                      id="registro-estado"
                      defaultMessage="Estado / Provincia *"
                    />
                  </label>
                  <div className="white-space-8" />
                  <input
                    type="text"
                    name="state"
                    className="input input-text"
                    required
                  />
                  <div className="white-space-16" />
                  <label htmlFor="zip" className="color-black">
                    <FormattedMessage
                      id="registro-zip"
                      defaultMessage="Código Postal *"
                    />
                  </label>
                  <div className="white-space-8" />
                  <input
                    type="text"
                    name="zip"
                    className="input input-text"
                    pattern="[0-9]{5}"
                    required
                  />
                  <div className="white-space-16" />
                  <label htmlFor="country" className="color-black">
                    <FormattedMessage
                      id="registro-country"
                      defaultMessage="País *"
                    />
                  </label>
                  <div className="white-space-8" />
                  <select
                    name="county"
                    id="country-list"
                    className="input input-select"
                    required
                  >
                    <option value="ESP">España</option>
                    {/*<option value="AFG">Afghanistan</option>
                    <option value="ALA">Åland Islands</option>
                    <option value="ALB">Albania</option>
                    <option value="DZA">Algeria</option>
                    <option value="ASM">American Samoa</option>
                    <option value="AND">Andorra</option>
                    <option value="AGO">Angola</option>
                    <option value="AIA">Anguilla</option>
                    <option value="ATA">Antarctica</option>
                    <option value="ATG">Antigua and Barbuda</option>
                    <option value="ARG">Argentina</option>
                    <option value="ARM">Armenia</option>
                    <option value="ABW">Aruba</option>
                    <option value="AUS">Australia</option>
                    <option value="AUT">Austria</option>
                    <option value="AZE">Azerbaijan</option>
                    <option value="BHS">Bahamas</option>
                    <option value="BHR">Bahrain</option>
                    <option value="BGD">Bangladesh</option>
                    <option value="BRB">Barbados</option>
                    <option value="BLR">Belarus</option>
                    <option value="BEL">Belgium</option>
                    <option value="BLZ">Belize</option>
                    <option value="BEN">Benin</option>
                    <option value="BMU">Bermuda</option>
                    <option value="BTN">Bhutan</option>
                    <option value="BOL">Bolivia, Plurinational State of</option>
                    <option value="BES">
                      Bonaire, Sint Eustatius and Saba
                    </option>
                    <option value="BIH">Bosnia and Herzegovina</option>
                    <option value="BWA">Botswana</option>
                    <option value="BVT">Bouvet Island</option>
                    <option value="BRA">Brazil</option>
                    <option value="IOT">British Indian Ocean Territory</option>
                    <option value="BRN">Brunei Darussalam</option>
                    <option value="BGR">Bulgaria</option>
                    <option value="BFA">Burkina Faso</option>
                    <option value="BDI">Burundi</option>
                    <option value="KHM">Cambodia</option>
                    <option value="CMR">Cameroon</option>
                    <option value="CAN">Canada</option>
                    <option value="CPV">Cape Verde</option>
                    <option value="CYM">Cayman Islands</option>
                    <option value="CAF">Central African Republic</option>
                    <option value="TCD">Chad</option>
                    <option value="CHL">Chile</option>
                    <option value="CHN">China</option>
                    <option value="CXR">Christmas Island</option>
                    <option value="CCK">Cocos (Keeling) Islands</option>
                    <option value="COL">Colombia</option>
                    <option value="COM">Comoros</option>
                    <option value="COG">Congo</option>
                    <option value="COD">
                      Congo, the Democratic Republic of the
                    </option>
                    <option value="COK">Cook Islands</option>
                    <option value="CRI">Costa Rica</option>
                    <option value="CIV">Côte d'Ivoire</option>
                    <option value="HRV">Croatia</option>
                    <option value="CUB">Cuba</option>
                    <option value="CUW">Curaçao</option>
                    <option value="CYP">Cyprus</option>
                    <option value="CZE">Czech Republic</option>
                    <option value="DNK">Denmark</option>
                    <option value="DJI">Djibouti</option>
                    <option value="DMA">Dominica</option>
                    <option value="DOM">Dominican Republic</option>
                    <option value="ECU">Ecuador</option>
                    <option value="EGY">Egypt</option>
                    <option value="SLV">El Salvador</option>
                    <option value="ESP">España</option>
                    <option value="GNQ">Equatorial Guinea</option>
                    <option value="ERI">Eritrea</option>
                    <option value="EST">Estonia</option>
                    <option value="ETH">Ethiopia</option>
                    <option value="FLK">Falkland Islands (Malvinas)</option>
                    <option value="FRO">Faroe Islands</option>
                    <option value="FJI">Fiji</option>
                    <option value="FIN">Finland</option>
                    <option value="FRA">France</option>
                    <option value="GUF">French Guiana</option>
                    <option value="PYF">French Polynesia</option>
                    <option value="ATF">French Southern Territories</option>
                    <option value="GAB">Gabon</option>
                    <option value="GMB">Gambia</option>
                    <option value="GEO">Georgia</option>
                    <option value="DEU">Germany</option>
                    <option value="GHA">Ghana</option>
                    <option value="GIB">Gibraltar</option>
                    <option value="GRC">Greece</option>
                    <option value="GRL">Greenland</option>
                    <option value="GRD">Grenada</option>
                    <option value="GLP">Guadeloupe</option>
                    <option value="GUM">Guam</option>
                    <option value="GTM">Guatemala</option>
                    <option value="GGY">Guernsey</option>
                    <option value="GIN">Guinea</option>
                    <option value="GNB">Guinea-Bissau</option>
                    <option value="GUY">Guyana</option>
                    <option value="HTI">Haiti</option>
                    <option value="HMD">
                      Heard Island and McDonald Islands
                    </option>
                    <option value="VAT">Holy See (Vatican City State)</option>
                    <option value="HND">Honduras</option>
                    <option value="HKG">Hong Kong</option>
                    <option value="HUN">Hungary</option>
                    <option value="ISL">Iceland</option>
                    <option value="IND">India</option>
                    <option value="IDN">Indonesia</option>
                    <option value="IRN">Iran, Islamic Republic of</option>
                    <option value="IRQ">Iraq</option>
                    <option value="IRL">Ireland</option>
                    <option value="IMN">Isle of Man</option>
                    <option value="ISR">Israel</option>
                    <option value="ITA">Italy</option>
                    <option value="JAM">Jamaica</option>
                    <option value="JPN">Japan</option>
                    <option value="JEY">Jersey</option>
                    <option value="JOR">Jordan</option>
                    <option value="KAZ">Kazakhstan</option>
                    <option value="KEN">Kenya</option>
                    <option value="KIR">Kiribati</option>
                    <option value="PRK">
                      Korea, Democratic People's Republic of
                    </option>
                    <option value="KOR">Korea, Republic of</option>
                    <option value="KWT">Kuwait</option>
                    <option value="KGZ">Kyrgyzstan</option>
                    <option value="LAO">
                      Lao People's Democratic Republic
                    </option>
                    <option value="LVA">Latvia</option>
                    <option value="LBN">Lebanon</option>
                    <option value="LSO">Lesotho</option>
                    <option value="LBR">Liberia</option>
                    <option value="LBY">Libya</option>
                    <option value="LIE">Liechtenstein</option>
                    <option value="LTU">Lithuania</option>
                    <option value="LUX">Luxembourg</option>
                    <option value="MAC">Macao</option>
                    <option value="MKD">
                      Macedonia, the former Yugoslav Republic of
                    </option>
                    <option value="MDG">Madagascar</option>
                    <option value="MWI">Malawi</option>
                    <option value="MYS">Malaysia</option>
                    <option value="MDV">Maldives</option>
                    <option value="MLI">Mali</option>
                    <option value="MLT">Malta</option>
                    <option value="MHL">Marshall Islands</option>
                    <option value="MTQ">Martinique</option>
                    <option value="MRT">Mauritania</option>
                    <option value="MUS">Mauritius</option>
                    <option value="MYT">Mayotte</option>
                    <option value="MEX">Mexico</option>
                    <option value="FSM">Micronesia, Federated States of</option>
                    <option value="MDA">Moldova, Republic of</option>
                    <option value="MCO">Monaco</option>
                    <option value="MNG">Mongolia</option>
                    <option value="MNE">Montenegro</option>
                    <option value="MSR">Montserrat</option>
                    <option value="MAR">Morocco</option>
                    <option value="MOZ">Mozambique</option>
                    <option value="MMR">Myanmar</option>
                    <option value="NAM">Namibia</option>
                    <option value="NRU">Nauru</option>
                    <option value="NPL">Nepal</option>
                    <option value="NLD">Netherlands</option>
                    <option value="NCL">New Caledonia</option>
                    <option value="NZL">New Zealand</option>
                    <option value="NIC">Nicaragua</option>
                    <option value="NER">Niger</option>
                    <option value="NGA">Nigeria</option>
                    <option value="NIU">Niue</option>
                    <option value="NFK">Norfolk Island</option>
                    <option value="MNP">Northern Mariana Islands</option>
                    <option value="NOR">Norway</option>
                    <option value="OMN">Oman</option>
                    <option value="PAK">Pakistan</option>
                    <option value="PLW">Palau</option>
                    <option value="PSE">Palestinian Territory, Occupied</option>
                    <option value="PAN">Panama</option>
                    <option value="PNG">Papua New Guinea</option>
                    <option value="PRY">Paraguay</option>
                    <option value="PER">Peru</option>
                    <option value="PHL">Philippines</option>
                    <option value="PCN">Pitcairn</option>
                    <option value="POL">Poland</option>
                    <option value="PRT">Portugal</option>
                    <option value="PRI">Puerto Rico</option>
                    <option value="QAT">Qatar</option>
                    <option value="REU">Réunion</option>
                    <option value="ROU">Romania</option>
                    <option value="RUS">Russian Federation</option>
                    <option value="RWA">Rwanda</option>
                    <option value="BLM">Saint Barthélemy</option>
                    <option value="SHN">
                      Saint Helena, Ascension and Tristan da Cunha
                    </option>
                    <option value="KNA">Saint Kitts and Nevis</option>
                    <option value="LCA">Saint Lucia</option>
                    <option value="MAF">Saint Martin (French part)</option>
                    <option value="SPM">Saint Pierre and Miquelon</option>
                    <option value="VCT">
                      Saint Vincent and the Grenadines
                    </option>
                    <option value="WSM">Samoa</option>
                    <option value="SMR">San Marino</option>
                    <option value="STP">Sao Tome and Principe</option>
                    <option value="SAU">Saudi Arabia</option>
                    <option value="SEN">Senegal</option>
                    <option value="SRB">Serbia</option>
                    <option value="SYC">Seychelles</option>
                    <option value="SLE">Sierra Leone</option>
                    <option value="SGP">Singapore</option>
                    <option value="SXM">Sint Maarten (Dutch part)</option>
                    <option value="SVK">Slovakia</option>
                    <option value="SVN">Slovenia</option>
                    <option value="SLB">Solomon Islands</option>
                    <option value="SOM">Somalia</option>
                    <option value="ZAF">South Africa</option>
                    <option value="SGS">
                      South Georgia and the South Sandwich Islands
                    </option>
                    <option value="SSD">South Sudan</option>
                    <option value="LKA">Sri Lanka</option>
                    <option value="SDN">Sudan</option>
                    <option value="SUR">Suriname</option>
                    <option value="SJM">Svalbard and Jan Mayen</option>
                    <option value="SWZ">Swaziland</option>
                    <option value="SWE">Sweden</option>
                    <option value="CHE">Switzerland</option>
                    <option value="SYR">Syrian Arab Republic</option>
                    <option value="TWN">Taiwan, Province of China</option>
                    <option value="TJK">Tajikistan</option>
                    <option value="TZA">Tanzania, United Republic of</option>
                    <option value="THA">Thailand</option>
                    <option value="TLS">Timor-Leste</option>
                    <option value="TGO">Togo</option>
                    <option value="TKL">Tokelau</option>
                    <option value="TON">Tonga</option>
                    <option value="TTO">Trinidad and Tobago</option>
                    <option value="TUN">Tunisia</option>
                    <option value="TUR">Turkey</option>
                    <option value="TKM">Turkmenistan</option>
                    <option value="TCA">Turks and Caicos Islands</option>
                    <option value="TUV">Tuvalu</option>
                    <option value="UGA">Uganda</option>
                    <option value="UKR">Ukraine</option>
                    <option value="ARE">United Arab Emirates</option>
                    <option value="GBR">United Kingdom</option>
                    <option value="USA">United States</option>
                    <option value="UMI">
                      United States Minor Outlying Islands
                    </option>
                    <option value="URY">Uruguay</option>
                    <option value="UZB">Uzbekistan</option>
                    <option value="VUT">Vanuatu</option>
                    <option value="VEN">
                      Venezuela, Bolivarian Republic of
                    </option>
                    <option value="VNM">Viet Nam</option>
                    <option value="VGB">Virgin Islands, British</option>
                    <option value="VIR">Virgin Islands, U.S.</option>
                    <option value="WLF">Wallis and Futuna</option>
                    <option value="ESH">Western Sahara</option>
                    <option value="YEM">Yemen</option>
                    <option value="ZMB">Zambia</option>
                    <option value="ZWE">Zimbabwe</option>*/}
                  </select>
                  <div className="white-space-16" />
                </div>
              </div>
              <div className="white-space-8" />
              <p>
                <FormattedMessage
                  id="registro-datos-obligatorios"
                  defaultMessage="* Datos Obligatorios"
                />
              </p>
              <div className="white-space-32" />
              <button type="submit" className="btn btn-aqua" id="btn-register">
                <i className="fas fa-sign-in-alt" />
                &nbsp;{" "}
                <FormattedMessage
                  id="registro-crear"
                  defaultMessage="CREAR CUENTA"
                />
                {this.props.register_loading ? (
                  <i className="fas fa-spinner fa-spin" />
                ) : null}
              </button>
              <div className="white-space-16" />
              <strong className="color-darkGreen" id="register-status">
                {this.state.login_error === "1"
                  ? this.registerOk()
                  : this.state.login_error === "2"
                  ? ""
                  : this.state.login_error === "3"
                  ? "Usuario en uso o datos inválidos"
                  : ""}
              </strong>
              <div className="white-space-32" />
            </form>
          </div>
        </div>
        {this.renderModal()}
      </div>
    );
  }

  registerOk() {
    alert("Registro Exitoso");
    document.getElementById("btn-register").setAttribute("disabled", "");
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.user,
    login_error: state.login_error,
    loading: state.login_loading,
    sigin_message: state.sigin_message,
    register_loading: state.client_register_loading
  };
}

export default connect(mapStateToProps)(Registro);
