import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../redux/actions";
import shave from "shave";
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { IMG_URL } from '../../api/urls';

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    shave(".name", 48);
  }

  addCart() {
    this.props.dispatch(action.addCart(this.props.item));
    showPopOver();
    function showPopOver() {
      let popOver = document.getElementById("popover");
      popOver.classList.remove("hidden");
      setTimeout(function() {
        popOver.classList.add("hidden");
      }, 2500);
    }
  }
 
  render() {
    return (
      <div className="product column bordered">
        <div className="image" onClick={this.goDetails.bind(this, this.props.item.idProducto)}>
          <img
            src={
              IMG_URL + "imagenes/obtener?id_imagen=" +
              this.props.item.idProducto
            } alt="Producto" title="Producto"
          />
        </div>
        <div className="white-space-24" />
        <div className="description column">
          <h3 className="text-center name color-darkGray" onClick={this.goDetails.bind(this, this.props.item.idProducto)} >
            {this.props.item.nombre}
          </h3>
          <div className="white-space-16" />
          <div className="text-center price justify-center align-center" onClick={this.goDetails.bind(this, this.props.item.idProducto)} >
            {
              this.props.item.a_consultar ? ("A CONSULTAR") : 
              (
                (
                    this.props.item.precio_promo ? 
                    (
                        <div className="auto align-center justify-center">
                          <FormattedNumber currency="EUR" style="currency" value={this.props.item.precio_promo} />
                          <div className="promotion-price">
                            &nbsp; <FormattedNumber currency="EUR" style="currency" value={this.props.item.precio} />
                          </div>
                        </div>                        
                    ) :
                    (
                        <FormattedNumber currency="EUR" style="currency" value={this.props.item.precio} />
                    )
                    
                )
                
              )
            }
          </div>
          <div className="white-space-16" />
          {
            this.props.item.a_consultar ? 
            (
                <div>
                  <button className="btn btn-green btn-addToCart" id="btn-addToCart" onClick={this.goDetails.bind(this, this.props.item.idProducto)}  >
                    <i className="fas fa-cart-plus" />
                    &nbsp; <FormattedMessage id="producto-anadir" defaultMessage="VER PRODUCTO" />
                  </button>
                </div>
            ) : 
            (
              <div>
                <button className="btn btn-green btn-addToCart" id="btn-addToCart" onClick={this.addCart.bind(this, this.props.item)} >
                  <i className="fas fa-cart-plus" />
                  &nbsp; <FormattedMessage id="producto-anadir" defaultMessage="AÑADIR AL CARRITO" />
                </button>
              </div>
            )
          }
          
        </div>
      </div>
    );
  }

  goDetails(item) {
    window.location = `/producto/${item}`;
  }

}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

export default connect(mapStateToProps)(Product);
